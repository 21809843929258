export async function getAllSectionDynamic(homeContents, pageName, pageSize) {
  const commonArr = [];
  const homePageSections = [
    'bannerSection',
    'becomeLandlord',
    'exploreRentals',
    'featuredCities',
    'reviews',
    'faqs',
    'contactUs',
  ];
  const rentPageSection = ['bannerSection', 'becomeLandlord', 'exploreRentals'];
  const listPageSection = [
    'bannerSection',
    'rentFavouritePlace',
    'convenience',
    'listConfidently',
    'howItWorks',
  ];
  const aboutUsPageSection = ['bannerSection', 'hiring', 'aboutUs'];
  for (let i = 0; i < pageSize; i++) {
    let obj = {};
    if (pageName == 'home') {
      obj = {
        pageName: pageName,
        section: homePageSections[i],
        title: homeContents[i]?.title ? homeContents[i]?.title : '',
        subTitle: homeContents[i]?.subTitle ? homeContents[i]?.subTitle : '',
        description: homeContents[i]?.description
          ? homeContents[i]?.description
          : '',
        bannerImage: homeContents[i]?.bannerImage
          ? homeContents[i]?.bannerImage
          : '',
        bannerImage2: homeContents[i]?.bannerImage2
          ? homeContents[i]?.bannerImage2
          : '',
      };
    }
    if (pageName == 'rent') {
      obj = {
        pageName: pageName,
        section: rentPageSection[i],
        title: homeContents[i]?.title ? homeContents[i]?.title : '',
        subTitle: homeContents[i]?.subTitle ? homeContents[i]?.subTitle : '',
        description: homeContents[i]?.description
          ? homeContents[i]?.description
          : '',
        bannerImage: homeContents[i]?.bannerImage
          ? homeContents[i]?.bannerImage
          : '',
        bannerImage2: homeContents[i]?.bannerImage2
          ? homeContents[i]?.bannerImage2
          : '',
      };
    }
    if (pageName == 'list') {
      obj = {
        pageName: pageName,
        section: listPageSection[i],
        title: homeContents[i]?.title ? homeContents[i]?.title : '',
        subTitle: homeContents[i]?.subTitle ? homeContents[i]?.subTitle : '',
        description: homeContents[i]?.description
          ? homeContents[i]?.description
          : '',
        bannerImage: homeContents[i]?.bannerImage
          ? homeContents[i]?.bannerImage
          : '',
        bannerImage2: homeContents[i]?.bannerImage2
          ? homeContents[i]?.bannerImage2
          : '',
      };
    }
    if (pageName == 'aboutUs') {
      obj = {
        pageName: pageName,
        section: aboutUsPageSection[i],
        title: homeContents[i]?.title ? homeContents[i]?.title : '',
        subTitle: homeContents[i]?.subTitle ? homeContents[i]?.subTitle : '',
        description: homeContents[i]?.description
          ? homeContents[i]?.description
          : '',
        bannerImage: homeContents[i]?.bannerImage
          ? homeContents[i]?.bannerImage
          : '',
        bannerImage2: homeContents[i]?.bannerImage2
          ? homeContents[i]?.bannerImage2
          : '',
      };
    }
    commonArr.push(obj);
  }
  let homeContentArr = { value: commonArr };
  // console.log('homeContentArr', homeContentArr);
  return homeContentArr;
}
