import React, { useEffect, useState } from 'react';
import { CardBody, Container, Row, Card, Table, Col } from 'reactstrap';
//Import Breadcrumb
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { useLocation, useNavigate } from 'react-router-dom';
import { setToken } from '../../../utils/axiosClient';
import { useAuthStore } from '../../../redux/features/authSlice';
import {
  deletePropertyFromAdmin,
  fetchParticularUser,
  getAllPropertyUser,
} from '../../../services/apiServices';
import { IMG_URL } from '../../../utils/constant';
import userNoImage from '../../assets/images/users/user-dummy-img.jpg';
import LandlordDetails from './LandlordDetails';
import { Alert } from '@mui/material';
import RenterDetails from './RenterDetails';
import ShowSpinner from '../../Components/Common/spinner.js';
import ResponseModal from '../../Components/Common/SuccessModal.js';
import RecentActivity from './RecentActivity';
import ActiveInactiveModel from '../../Components/Common/ActiveInactiveModel';

const UserDetails = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { loginAdminInfo } = useAuthStore();
  const [userData, setUserData] = useState();
  //fetch properties
  const [fetchRender, setFetchRender] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [allPropertyList, setAllPropertyList] = useState([]);
  const [optionsFlag, setOptionsFlag] = useState(true);
  //filter
  const [deleteModal, setDeleteModal] = useState(false);
  const [messageActiveInactive, setMessageActiveInactive] = useState();
  const [propertyDeletedId, setPropertyDeletedId] = useState();
  const [userDeletedId, setUserDeletedId] = useState();
  const [propertyDeletedName, setPropertyDeletedName] = useState();
  const [search, setSearch] = useState();
  const [searchByDate, setSearchByDate] = useState();
  const [paginationTotalRows, setPaginationTotalRows] = useState(1);
  const [spinFlag, setSpinFlag] = useState(false);
  const [rowPerPage, setRowsPerPage] = useState(10);
  const [userStatus, setUserStatus] = useState();
  const [response, setResponse] = useState({
    message: '',
    open: false,
    statusCode: null,
  });

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');
  useEffect(() => {
    fetchSingleUser(id);
    fetchPropertyList();
  }, [id, fetchRender, currentPage, rowPerPage]);

  const fetchPropertyList = async () => {
    try {
      setSpinFlag(true);
      setToken(loginAdminInfo ? loginAdminInfo.token : null);
      let payload = { currentPage, user_id: id, rowPerPage };
      if ((userStatus == 1 || userStatus == 0) && userStatus !== 'All')
        payload['isDeleted'] = userStatus;
      if (search) payload['search'] = search;
      if (searchByDate) payload['searchByDate'] = searchByDate;
      const { data } = await getAllPropertyUser(payload);
      if (data.statusCode == 200) {
        setPaginationTotalRows(data.allPropertyCount);
        setAllPropertyList(data?.allProperty);
        setUserStatus();
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
        setUserStatus();
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };
  const fetchSingleUser = async (id) => {
    try {
      setSpinFlag(true);

      let payload = { user_id: id };
      const { data } = await fetchParticularUser(payload);
      if (data.statusCode === 200) {
        setUserData(data?.requestData);

        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handleDeleteProperty = async () => {
    try {
      setSpinFlag(true);
      let payload = {
        property_id: propertyDeletedId,
        user_id: userDeletedId,
        propertyTitle: propertyDeletedName,
      };
      const { data } = await deletePropertyFromAdmin(payload);
      if (data.statusCode === 200) {
        setDeleteModal(false);
        setFetchRender(!fetchRender);
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      } else {
        setDeleteModal(false);
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };
  const onClickDelete = (id, userID, p_name, isDeleted) => {
    setPropertyDeletedId(id);
    setUserDeletedId(userID);
    setPropertyDeletedName(p_name);
    setDeleteModal(true);
    if (!isDeleted) {
      setMessageActiveInactive('inactive this property');
    } else {
      setMessageActiveInactive('active this property');
    }
  };
  return (
    <React.Fragment>
      <ResponseModal response={response} setResponse={setResponse} />
      <ActiveInactiveModel
        show={deleteModal}
        onActiveInactiveClick={handleDeleteProperty}
        onCloseClick={() => setDeleteModal(false)}
        messageActiveInactive={messageActiveInactive}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xxl={3}>
              <div style={{ position: 'sticky', top: '80px' }}>
                <Row>
                  <Col xxl={12} lg={6}>
                    <Card>
                      <CardBody className="p-4">
                        <div className="text-center">
                          <div className="profile-user position-relative d-inline-block mx-auto mb-4">
                            {userData?.profile ? (
                              <img
                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                alt="user-profile-image"
                                src={`${IMG_URL}${userData.profile}`}
                              />
                            ) : (
                              <img
                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                alt="user-profile-image"
                                src={userNoImage}
                              />
                            )}
                          </div>
                          <h5 className="fs-16 mb-1 text-capitalize">
                            {userData?.firstname} {userData?.lastname}
                          </h5>
                          <p className="text-muted mb-0 text-capitalize">
                            {userData?.role}
                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xxl={12} lg={6}>
                    <Card>
                      <CardBody className="p-4">
                        <h5 className="card-title mb-3">Detail</h5>
                        <div className="table-responsive">
                          <Table className="table mb-0 table-borderless">
                            <tbody>
                              <tr>
                                <th className="ps-0">Full Name</th>
                                <td className="text-muted text-capitalize">
                                  {userData?.firstname} {userData?.lastname}
                                </td>
                              </tr>
                              <tr>
                                <th className="ps-0">Email</th>
                                <td className="text-muted">
                                  {userData?.email}
                                </td>
                              </tr>
                              <tr>
                                <th className="ps-0">Contact No.</th>
                                <td className="text-muted">
                                  {userData?.countryCode} {userData?.mobile}
                                </td>
                              </tr>
                              {userData?.role === 'landlord' ? null : (
                                // <tr>
                                //   <th className="ps-0">Units</th>
                                //   <td className="text-muted">{userData?.unit}</td>
                                // </tr>
                                <tr>
                                  <th className="ps-0">Date of Birth</th>
                                  <td className="text-muted">
                                    {userData?.dob.substring(0, 10)}
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <th className="ps-0">Status</th>
                                <td className="text-muted">
                                  {userData?.isActive ? 'Active' : 'Inactive'}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xxl={9}>
              <div className="">
                {userData?.role === 'landlord' ? (
                  <>
                    <LandlordDetails
                      data={allPropertyList}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      paginationTotalRows={paginationTotalRows}
                      setSearch={setSearch}
                      search={search}
                      fetchRender={fetchRender}
                      setFetchRender={setFetchRender}
                      optionsFlag={optionsFlag}
                      setSearchByDate={setSearchByDate}
                      setRowsPerPage={setRowsPerPage}
                      rowPerPage={rowPerPage}
                      onClickDelete={onClickDelete}
                      setUserStatus={setUserStatus}
                      userStatus={userStatus}
                    />
                  </>
                ) : (
                  <>
                    {userData?.role === 'renter' ? (
                      <RenterDetails
                        userData={userData}
                        setSpinFlag={setSpinFlag}
                        setResponse={setResponse}
                      />
                    ) : null}
                  </>
                )}
                <RecentActivity user_id={id} userData={userData} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ShowSpinner spinFlag={spinFlag} />
    </React.Fragment>
  );
};

export default UserDetails;
