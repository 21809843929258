import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  Button,
  Backdrop,
  CircularProgress,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Alert,
  FormHelperText,
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import { RightImg, LeftSection, BrandListBox, BannerLeftMain } from './styled';
import { Link, useNavigate } from 'react-router-dom';
import { BrandList } from '../../../components/brand-list';
import Rectangle5196 from '../../../assets/images/Rectangle5196.png';
import { useAuthStore } from '../../../redux/features/authSlice';
import { userlogin } from '../../../services/apiServices';
import { loginSchema } from '../../../utils/validation/Auth';
import authBack6 from '../../../assets/images/auth_back6.svg';
import authBack7 from '../../../assets/images/auth_back7.svg';

export const Login = () => {
  const { updateLoginInfo } = useAuthStore();
  const navigate = useNavigate();

  const [state, setState] = useState({
    showPassword: false,
    message: '',
    spinFlag: false,
    showAlert: false,
  });

  const initialValues = {
    email: '',
    password: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      try {
        setState({ ...state, spinFlag: true });
        const { data } = await userlogin(values);

        if (data.statusCode == 200) {
          let result = data.userData;
          result.token = data.token;
          setState({
            ...state,
            message: data.message,
            spinFlag: false,
            showAlert: false,
          });
          updateLoginInfo(result);
          if (result.role === 'landlord') navigate('/dashboard');
          else navigate('/');
        } else {
          setState({
            ...state,
            message: data.message,
            spinFlag: false,
            showAlert: true,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: { md: 'end', xs: 'center' },
            background: `url(${authBack6}) no-repeat top right, url(${authBack7}) no-repeat bottom left`,
            order: { md: '1', xs: '2' },
          }}
        >
          <BannerLeftMain>
            <LeftSection>
              <>
                {state.showAlert ? (
                  <Alert sx={{ mb: 2 }} severity="error">
                    {state.message}
                  </Alert>
                ) : (
                  ''
                )}

                <Box sx={{ mb: 6 }}>
                  <Typography
                    component="h6"
                    align="center"
                    sx={{
                      color: '#FEAE01',
                      fontSize: '16px',
                      textTransform: 'uppercase',
                      fontWeight: '600',
                      letterSpacing: { sm: '0.1em' },
                    }}
                  >
                    Sign IN
                  </Typography>
                </Box>

                <FormikProvider value={formik}>
                  <Form noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            autoComplete="off"
                            fullWidth
                            type="email"
                            label="Username / Email"
                            {...getFieldProps('email')}
                            // error={Boolean(touched.email && errors.email)}
                            // helperText={touched.email && errors.email}
                          />
                          {touched.email && errors.email && (
                            <FormHelperText error>
                              {errors.email}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            autoComplete="off"
                            fullWidth
                            type={state.showPassword ? 'text' : 'password'}
                            label="Password"
                            {...getFieldProps('password')}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleShowPassword}
                                    edge="end"
                                  >
                                    <Icon
                                      icon={
                                        state.showPassword
                                          ? 'eva:eye-fill'
                                          : 'eva:eye-off-fill'
                                      }
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            // error={Boolean(touched.password && errors.password)}
                            // helperText={touched.password && errors.password}
                          />
                          {touched.password && errors.password && (
                            <FormHelperText error>
                              {errors.password}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="end"
                          >
                            <Link
                              to="/forgot-password"
                              style={{
                                color: '#374151',
                                textDecoration: 'none',
                                fontSize: '16px',
                                fontWeight: '500',
                              }}
                            >
                              Forgot password?
                            </Link>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                          >
                            Login
                          </Button>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Form>
                </FormikProvider>
              </>
              <Box>
                <Typography
                  component="p"
                  align="center"
                  sx={{
                    mt: 2,
                    fontSize: '16px',
                    color: '#6B7280',
                    fontWeight: '500',
                  }}
                >
                  Don’t have an account,{' '}
                  <Link
                    to="/sign-up"
                    style={{ color: '#FEAE01', textDecoration: 'none' }}
                  >
                    Create an account
                  </Link>
                </Typography>
              </Box>
            </LeftSection>
            {/* <BrandListBox>
              <BrandList />
            </BrandListBox> */}
          </BannerLeftMain>
        </Grid>
        <Grid item md={6} xs={12} sx={{ order: { md: '2', xs: '1' } }}>
          <RightImg>
            <img src={Rectangle5196} alt="Rectangle5196" />
          </RightImg>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={state.spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
