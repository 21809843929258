import React, { useEffect, useState } from 'react';
import {
  CardBody,
  Row,
  Col,
  Card,
  Table,
  CardHeader,
  Container,
} from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fetchTransferedById } from '../../../services/apiServices';
import { nairaSymbol } from '../../../utils/constant';
import ShowSpinner from '../../../admin/Components/Common/spinner';
const PaymentDetailsLandlord = () => {
  const [spinFlag, setSpinFlag] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [data, setData] = useState([]);
  const [fetchRender, setFetchRender] = useState(true);
  const [withdrwalChargeRecepint, setWithdrwalChargeRecepint] = useState();
  const [totalTransferedFee, setTotalTransferedFee] = useState(0);
  const [totalFeeCharged, setTotalFeeCharged] = useState(0);
  const [totalWithdrwalAmount, setTotalWithdrwalAmount] = useState(0);
  const [totalSubAmount, setTotalSubAmount] = useState(0);
  const [withdwalAndUserData, setWithdwalAndUserData] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');
  //Print the Invoice
  const printInvoice = () => {
    window.print();
  };
  const dateObj = new Date(data?.createdAt);
  // Format the date: "23 Nov, 2021"
  const optionsDate = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = dateObj.toLocaleDateString('en-US', optionsDate);

  // Format the time: "02:36PM"
  const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };
  const formattedTime = dateObj.toLocaleTimeString('en-US', optionsTime);
  const fetchTransferedDeatil = async () => {
    try {
      setSpinFlag(true);
      const payload = { t_id: id };
      const { data } = await fetchTransferedById(payload);
      if (data?.statusCode == 200) {
        setData(data?.data);
        setWithdwalAndUserData(data?.withdrwalData[0]);
        setSpinFlag(false);
        const amount = data?.data?.amount / 100;
        const subTotal =
          data?.data?.amount / 100 - data?.data?.fee_charged / 100;
        const transferedFee = Math.ceil(
          ((((data?.data?.amount / 100) * 5) / 100 + data?.data?.amount / 100) *
            5) /
            100
        );
        const feecharge = data?.data?.fee_charged / 100;
        setTotalSubAmount(subTotal);
        setTotalTransferedFee(transferedFee);
        setTotalFeeCharged(feecharge);
        setTotalWithdrwalAmount(transferedFee + subTotal);
        if (amount < 5010) {
          setWithdrwalChargeRecepint(10);
        } else if (amount >= 5010 && amount < 50050) {
          setWithdrwalChargeRecepint(25);
        } else {
          setWithdrwalChargeRecepint(50);
        }
      } else {
        setSpinFlag(false);
      }
    } catch (error) {
      setSpinFlag(false);
      navigate(`/admin/error?msg=${error}&&path=${pathname}`);
    }
  };
  useEffect(() => {
    if (id) fetchTransferedDeatil();
  }, [id, fetchRender]);
  return (
    <div className="page-content-landlord">
      <ShowSpinner spinFlag={spinFlag} />
      <Container fluid>
        <Row className="justify-content-center">
          <Col xxl={9}>
            <Card id="demo">
              <Row>
                <Col lg={12}>
                  <CardHeader className="border-bottom-dashed p-4">
                    <div className="d-flex">
                      <div className="flex-shrink-0 mt-sm-0 mt-3">
                        <h6>
                          <span className="text-muted fw-normal">Email:</span>{' '}
                          <span id="email">team@flattts.com</span>
                        </h6>
                        <h6>
                          <span className="text-muted fw-normal">Website:</span>{' '}
                          <Link to="#" className="link-primary" id="website">
                            www.flattts.com
                          </Link>
                        </h6>
                        {/* <h6 className="mb-0">
                          <span className="text-muted fw-normal">
                            Contact No:
                          </span>{' '}
                          <span id="contact-no"> +(1) 9197490023</span>
                        </h6> */}
                      </div>
                    </div>
                  </CardHeader>
                </Col>
                <Col lg={12}>
                  <CardBody className="p-4">
                    <Row className="g-3">
                      <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                          Reference Code
                        </p>
                        <h5 className="fs-14 mb-0">
                          #<span id="invoice-no1">{data?.reference}</span>
                        </h5>
                      </Col>
                      <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                          Transfere Code
                        </p>
                        <h5 className="fs-14 mb-0">
                          #<span id="invoice-no2">{data?.transfer_code}</span>
                        </h5>
                      </Col>
                      <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                          Recipient Code
                        </p>
                        <h5 className="fs-14 mb-0">
                          #
                          <span id="invoice-no3">
                            {data?.recipient?.recipient_code}
                          </span>
                        </h5>
                      </Col>
                      <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold">
                          Date
                        </p>

                        <h5 className="fs-14 mb-0">
                          <span id="invoice-date">{formattedDate}</span>{' '}
                          <small className="text-muted" id="invoice-time">
                            {formattedTime}
                          </small>
                        </h5>
                      </Col>
                      <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold pt-4">
                          Payment Status
                        </p>
                        <span
                          className={
                            data?.status === 'success'
                              ? `badge badge-soft-success fs-11`
                              : data?.status === 'abandoned'
                              ? `badge badge-soft-danger fs-11`
                              : `badge badge-soft-warning fs-11`
                          }
                          id="payment-status"
                        >
                          {/* {data?.status==="success" ? :} */}
                          {data?.status}
                        </span>
                      </Col>
                      <Col lg={3} xs={6}>
                        <p className="text-muted mb-2 text-uppercase fw-semibold pt-4">
                          Total Withdrawal Amount
                        </p>
                        <h5 className="fs-14 mb-0">
                          {nairaSymbol}
                          <span id="total-amount">
                            {withdwalAndUserData?.amount}
                          </span>
                        </h5>
                      </Col>
                    </Row>
                  </CardBody>
                </Col>
                <Col lg={12}>
                  <CardBody className="p-4 border-top border-top-dashed">
                    <Row className="g-3">
                      {withdwalAndUserData &&
                        withdwalAndUserData?.userDetails?.length > 0 && (
                          <Col sm={6}>
                            <h6 className="text-muted text-uppercase fw-semibold mb-3">
                              Recipient's details
                            </h6>
                            <p className="fw-medium mb-2" id="billing-name">
                              {/* {data?.recipient?.name} */}
                              {
                                withdwalAndUserData?.userDetails[0]?.firstname
                              }{' '}
                              {withdwalAndUserData?.userDetails[0]?.lastname}
                            </p>
                            <p
                              className="text-muted mb-1"
                              id="billing-address-line-1"
                            >
                              {/* {data?.recipient?.email} */}
                              {withdwalAndUserData?.userDetails[0]?.email}
                            </p>
                            <p
                              className="text-muted mb-1"
                              id="billing-address-line-1"
                            >
                              {/* {data?.recipient?.email} */}
                              {
                                withdwalAndUserData?.userDetails[0]?.countryCode
                              }{' '}
                              {withdwalAndUserData?.userDetails[0]?.mobile}
                            </p>
                            <p className="text-muted mb-1">
                              <span>Account no.: </span>
                              <span id="billing-phone-no">
                                {data?.recipient?.details?.account_number}
                              </span>
                            </p>
                            <p className="text-muted mb-1">
                              <span>Account name: </span>
                              <span id="billing-tax-no">
                                {data?.recipient?.details?.account_name}
                              </span>
                            </p>
                            <p className="text-muted mb-1">
                              <span>Bank code: </span>
                              <span id="billing-tax-no">
                                {data?.recipient?.details?.bank_code}
                              </span>
                            </p>
                            <p className="text-muted mb-1">
                              <span>Bank name: </span>
                              <span id="billing-tax-no">
                                {data?.recipient?.details?.bank_name}
                              </span>
                            </p>
                          </Col>
                        )}

                      <Col sm={6}>
                        <div className="mt-2">
                          <Table
                            className="table table-borderless table-nowrap align-middle mb-0 ms-auto"
                            style={{ width: '250px' }}
                          >
                            <tbody>
                              <tr>
                                <td>Sub Total </td>
                                <td className="text-end">
                                  {nairaSymbol}
                                  {totalSubAmount}
                                  {/* {data?.amount / 100 - data?.fee_charged / 100} */}
                                </td>
                              </tr>
                              <tr>
                                <td>Transfer Fee </td>
                                <td className="text-end">
                                  {nairaSymbol}
                                  {withdwalAndUserData?.transferedFee}
                                  {/* {Math.ceil(
                                    ((((data?.amount / 100) * 5) / 100 +
                                      data?.amount / 100) *
                                      5) /
                                      100
                                  )} */}
                                </td>
                              </tr>

                              <tr className="border-top border-top-dashed fs-15">
                                <th scope="row">Total Amount </th>
                                <th className="text-end">
                                  {nairaSymbol}
                                  {/* {totalWithdrwalAmount} */}
                                  {withdwalAndUserData?.amount}
                                </th>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Col>
                <Col lg={12}>
                  <CardBody className="p-4">
                    <div className="table-responsive"></div>

                    <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                      <Link
                        to="#"
                        onClick={printInvoice}
                        className="btn btn-success"
                      >
                        <i className="ri-printer-line align-bottom me-1"></i>{' '}
                        Print
                      </Link>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PaymentDetailsLandlord;
