import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, FormFeedback, Input, Label } from 'reactstrap';
import { emailConfigure } from '../../../../utils/validation/Admin';
import {
  getEmailConfigure,
  updateEmailConfigure,
} from '../../../../services/apiServices';

const EmailConfigure = (props) => {
  let { setSpinFlag, setResponse } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordShowConfirm, setPasswordShowConfirm] = useState(false);
  const [configData, setConfigData] = useState();
  const [render, setRender] = useState(true);
  useEffect(() => {
    fetchEmailConfigure();
  }, [render]);
  const fetchEmailConfigure = async () => {
    try {
      setSpinFlag(true);
      const { data } = await getEmailConfigure();
      if (data.statusCode === 200) {
        // console.log('dadadad', data);
        setConfigData(data?.data[0]);
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const validation1 = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: configData?.email || '',
      password: '',
      confirmpassword: '',
    },
    validationSchema: emailConfigure,

    onSubmit: async (values) => {
      try {
        // console.log('dadadadad', values);
        setSpinFlag(true);
        const payload = {
          email: values.email,
          password: values.password,
        };
        const { data } = await updateEmailConfigure(payload);
        if (data.statusCode === 200) {
          setSpinFlag(false);
          setRender(!render);
          setResponse({
            message: data.message,
            statusCode: data.statusCode,
            open: true,
          });
        } else {
          setSpinFlag(false);
          setResponse({
            message: data.message,
            statusCode: data.statusCode,
            open: true,
          });
        }
      } catch (err) {
        navigate(`/admin/error?msg=${err}&&path=${pathname}`);
      }
      resetForm();
    },
  });

  const {
    errors,
    touched,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setFieldError,
    resetForm,
    values,
  } = validation1;

  return (
    <React.Fragment>
      <FormikProvider value={validation1}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <div className="mb-3">
            <Label className="form-label" htmlFor="product-title-input">
              Email
            </Label>
            <Input
              name="email"
              className="form-control"
              placeholder="Enter email"
              type="email"
              onChange={validation1.handleChange}
              onBlur={validation1.handleBlur}
              value={validation1.values.email || ''}
              invalid={
                validation1.touched.email && validation1.errors.email
                  ? true
                  : false
              }
            />
            {validation1.touched.email && validation1.errors.email ? (
              <FormFeedback type="invalid">
                {validation1.errors.email}
              </FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label>Password</Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                name="password"
                value={validation1.values.password || ''}
                type={passwordShow ? 'text' : 'password'}
                className="form-control pe-5"
                placeholder="Enter Password"
                onChange={validation1.handleChange}
                onBlur={validation1.handleBlur}
                invalid={
                  validation1.touched.password && validation1.errors.password
                    ? true
                    : false
                }
              />
              {validation1.touched.password && validation1.errors.password ? (
                <FormFeedback type="invalid">
                  {validation1.errors.password}
                </FormFeedback>
              ) : null}
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                type="button"
                id="password-addon"
                onClick={() => setPasswordShow(!passwordShow)}
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </div>
          <div className="mb-3">
            <Label htmlFor="confirmpasswordInput" className="form-label">
              Confirm password
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                name="confirmpassword"
                className="form-control"
                placeholder="Enter confirm password"
                type={passwordShowConfirm ? 'text' : 'password'}
                onChange={validation1.handleChange}
                onBlur={validation1.handleBlur}
                value={validation1.values.confirmpassword || ''}
                invalid={
                  validation1.touched.confirmpassword &&
                  validation1.errors.confirmpassword
                    ? true
                    : false
                }
              />
              {validation1.touched.confirmpassword &&
              validation1.errors.confirmpassword ? (
                <FormFeedback type="invalid">
                  {validation1.errors.confirmpassword}
                </FormFeedback>
              ) : null}
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                type="button"
                id="password-addon"
                onClick={() => setPasswordShowConfirm(!passwordShowConfirm)}
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </div>
          <div className="text-end mb-5">
            <Button type="submit" className="btn btn-success w-sm">
              Submit
            </Button>
          </div>
        </Form>
      </FormikProvider>
    </React.Fragment>
  );
};

export default EmailConfigure;
