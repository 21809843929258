import React, { useState, useEffect, useCallback } from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from 'reactstrap';
import DatatablesCom from '../../Components/Tables/DataTables/DatatablesCom';
import 'react-toastify/dist/ReactToastify.css';
import userNoImage from '../../assets/images/users/user-dummy-img.jpg';
import {
  getAllPropertyList,
  deletePropertyFromAdmin,
  getAllBookedProperties,
  getAllAvailableProperties,
  savedAsPopularPlaces,
  removeFromPopularPlaces,
} from '../../../services/apiServices';
import { IMG_URL } from '../../../utils/constant';
import { currencySeprator } from '../../../utils/common-function';
import classnames from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DeleteModal from '../../Components/Common/DeleteModal';
import ActiveInactiveModel from '../../Components/Common/ActiveInactiveModel';
const customStyles = {
  rows: {
    style: {
      // minHeight: '72px',
    },
  },
  headCells: {
    style: {
      padding: '8px',
      background: '#f3f6f9',
      fontWeight: 'bold',
      color: '#878a99',
    },
  },
  cells: {
    style: {
      padding: '8px',
    },
  },
};

const PropertyList = (props) => {
  let { data, setSpinFlag, setResponse } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  const [allPropertyList, setAllPropertyList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationTotalRows, setPaginationTotalRows] = useState(1);

  const [fetchRender, setFetchRender] = useState(true);
  const [bookedProperties, setBookedProperties] = useState([]);
  //filter
  const [search, setSearch] = useState();
  const [searchByDate, setSearchByDate] = useState();
  const [activeTab, setActiveTab] = useState('1');
  const [tab, setTab] = useState('all');
  const [type, setType] = useState();
  const [rowPerPage, setRowsPerPage] = useState(10);
  const [userStatus, setUserStatus] = useState();

  // deleted property
  const [messageActiveInactive, setMessageActiveInactive] = useState();
  const [propertyDeletedId, setPropertyDeletedId] = useState();
  const [userDeletedId, setUserDeletedId] = useState();
  const [propertyDeletedName, setPropertyDeletedName] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [optionsFlag, setOptionsFlag] = useState(true);
  const [placeholder, setPlaceholder] = useState(
    'Search by title, firstname, city, country'
  );

  useEffect(() => {
    if (tab == 'all' || tab == 'viewed') {
      fetchPropertyList();
    }
    if (tab === 'booked') {
      fetchBookedPropertyList();
    }
    if (tab === 'available') {
      fetchAvailablePropertyList();
    }
  }, [fetchRender, currentPage, rowPerPage]);

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById('checkBoxAll');
    const ele = document.querySelectorAll('.customerCheckBox');

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll('.customerCheckBox:checked');
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setCurrentPage(1);
    }
  };

  const fetchPropertyList = async () => {
    try {
      setSpinFlag(true);

      let payload = { currentPage, tab, rowPerPage };
      if ((userStatus == 1 || userStatus == 0) && userStatus !== 'All')
        payload['isDeleted'] = userStatus;
      if (search) payload['search'] = search;
      if (searchByDate) payload['searchByDate'] = searchByDate;
      const { data } = await getAllPropertyList(payload);
      if (data.statusCode == 200) {
        setPaginationTotalRows(data.propertyCount);
        setAllPropertyList(data.myProperties);
        setUserStatus();
        setSpinFlag(false);
      } else {
        setUserStatus();
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const fetchBookedPropertyList = async () => {
    try {
      setSpinFlag(true);
      let payload = { currentPage, tab, rowPerPage };
      if ((userStatus == 1 || userStatus == 0) && userStatus !== 'All')
        payload['isDeleted'] = userStatus;
      if (search) payload['search'] = search;
      if (searchByDate) payload['searchByDate'] = searchByDate;
      const { data } = await getAllBookedProperties(payload);
      if (data.statusCode == 200) {
        if (tab === 'booked') {
          setPaginationTotalRows(data.propertyCount);
          setBookedProperties(data.bookedProperties);
        }
        // setUserStatus();
        setSpinFlag(false);
      } else {
        // setUserStatus();
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (error) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const fetchAvailablePropertyList = async () => {
    try {
      setSpinFlag(true);
      let payload = { currentPage, tab, rowPerPage };
      if ((userStatus == 1 || userStatus == 0) && userStatus !== 'All')
        payload['isDeleted'] = userStatus;
      if (search) payload['search'] = search;
      if (searchByDate) payload['searchByDate'] = searchByDate;
      const { data } = await getAllAvailableProperties(payload);
      if (data.statusCode == 200) {
        if (tab === 'available') {
          setAllPropertyList(data.myProperties);
          setPaginationTotalRows(data.propertyCount);
        }
        setUserStatus();
        setSpinFlag(false);
      } else {
        setUserStatus();
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  // Delete Data
  const onClickDelete = (id, userID, p_name, isDeleted) => {
    setPropertyDeletedId(id);
    setUserDeletedId(userID);
    setPropertyDeletedName(p_name);
    setDeleteModal(true);
    if (!isDeleted) {
      setMessageActiveInactive('inactive this property');
    } else {
      setMessageActiveInactive('active this property');
    }
  };

  const setAsPopular = async (id) => {
    try {
      let payload = { property_id: id };
      let { data } = await savedAsPopularPlaces(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
        setFetchRender(!fetchRender);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const removeFromPopular = async (id) => {
    try {
      let payload = { property_id: id };
      let { data } = await removeFromPopularPlaces(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
        setFetchRender(!fetchRender);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Properties</span>,
      // sortable: true,
      width: '350px',
      cell: (row) => {
        return (
          <div>
            {/* <ResponseModal response={response} setResponse={setResponse} /> */}
            <Link
              to={`/admin/property-details/${row?._id}`}
              className="text-dark"
              // target="_blank"
            >
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm bg-light rounded p-1">
                    {row?.propertyImages[0]?.propertyImage ? (
                      <img
                        className="img-fluid d-block h-100 object-fit-cover"
                        src={`${IMG_URL}${row?.propertyImages[0]?.propertyImage}`}
                        alt="Header Avatar"
                      />
                    ) : (
                      <img
                        className="img-fluid d-block h-100 object-fit-cover"
                        src={userNoImage}
                        alt="Header Avatar"
                      />
                    )}
                  </div>
                </div>
                <div className="flex-grow-1">
                  <h5 className="fs-14 mb-1">
                    {/* <Link
                  to={`/admin/user-details?id=${row?.ownerData[0]?._id}`}
                  className="text-dark"
                > */}
                    {row?.title}
                    {/* </Link> */}
                  </h5>
                  <p className="text-muted mb-0">
                    <span className="fw-medium"> {row?.address}</span>
                  </p>
                </div>
              </div>
            </Link>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Landlord</span>,
      // sortable: true,
      width: '230px',
      cell: (row) => {
        return (
          <div className="d-flex align-items-center text-capitalize">
            <div className="flex-shrink-0 me-3">
              <div className="avatar-sm bg-light rounded p-1">
                {row?.ownerData[0]?.profile ? (
                  <img
                    className="img-fluid d-block h-100 object-fit-cover"
                    src={`${IMG_URL}${row?.ownerData[0]?.profile}`}
                    alt="Header Avatar"
                  />
                ) : (
                  <img
                    className="img-fluid d-block h-100 object-fit-cover"
                    src={userNoImage}
                    alt="Header Avatar"
                  />
                )}
              </div>
            </div>
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <Link
                  to={`/admin/user-details?id=${row?.ownerData[0]?._id}`}
                  className="text-dark"
                >
                  {row?.ownerData[0]?.firstname} {row?.ownerData[0]?.lastname}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                <span className="fw-medium">
                  {' '}
                  {row?.ownerData[0]?.countryCode} {row?.ownerData[0]?.mobile}
                </span>
              </p>
            </div>
          </div>
        );
      },
    },
    // {
    //   name: <span className="font-weight-bold fs-13">Units</span>,
    //   selector: (row) => row?.propertyUnits?.length,
    //   sortable: true,
    // },
    {
      name: <span className="font-weight-bold fs-13">Price</span>,
      // sortable: true,
      width: '230px',
      cell: (row) => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <span>{'₦' + currencySeprator(row?.minPrice)}</span>{' '}
            <b className="px-1">{' - '}</b>
            <span>{'₦' + currencySeprator(row?.maxPrice)}</span>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Created date</span>,
      selector: (row) => row?.createdAt.substring(0, 10),
      // sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Viewed</span>,
      // sortable: true,
      cell: (row) => {
        return <span>{row?.views}</span>;
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Units</span>,
      // sortable: true,
      cell: (row) => {
        return <span>{row?.propertyUnits?.length}</span>;
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Rating</span>,
      // sortable: true,
      cell: (row) => {
        return (
          <span>
            <span className="badge bg-light text-body fs-12 fw-medium">
              {row?.rating ? (
                <>
                  <i className="mdi mdi-star text-warning me-1"></i>
                  {row?.rating}
                </>
              ) : (
                '-'
              )}
            </span>
          </span>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      // sortable: true,
      selector: (cellProps) => (
        <div className="form-check form-switch">
          <Input
            className="form-check-input"
            type="checkbox"
            role="switch"
            checked={!cellProps.isDeleted}
            // onClick={() => onClickActiveInactiveUser(cell._id, cell.isActive)}
            onClick={() => {
              onClickDelete(
                cellProps?._id,
                cellProps?.user,
                cellProps?.title,
                cellProps?.isDeleted
              );
            }}
          />
        </div>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      // sortable: true,
      cell: (cellProps) => {
        return (
          <>
            <UncontrolledDropdown direction="start" className="col text-start">
              <DropdownToggle tag="a" id="dropdownMenuLink2" role="button">
                <i className="ri-more-fill fs-17"></i>
              </DropdownToggle>
              <DropdownMenu>
                {cellProps.isPopular === 1 ? (
                  <DropdownItem
                    className=""
                    onClick={() => {
                      removeFromPopular(cellProps?._id);
                    }}
                  >
                    <i className="ri-star-fill me-2 align-bottom text-muted"></i>
                    Remove Explore Rentals
                  </DropdownItem>
                ) : (
                  <DropdownItem
                    className=""
                    onClick={() => {
                      setAsPopular(cellProps?._id);
                    }}
                  >
                    <i className="ri-star-line me-2 align-bottom text-muted"></i>
                    Explore Rentals
                  </DropdownItem>
                )}
                {/* <DropdownItem
                  className="remove-list"
                  onClick={() => {
                    onClickDelete(
                      cellProps?._id,
                      cellProps?.user,
                      cellProps?.title
                    );
                  }}
                >
                  <i className="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>
                  Remove
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        );
      },
    },
  ];

  const bookedPropertiesColumns = [
    // {
    //   name: (
    //     <input
    //       type="checkbox"
    //       id="checkBoxAll"
    //       className="form-check-input"
    //       onClick={() => checkedAll()}
    //     />
    //   ),
    //   width: '50px',
    //   cell: (cellProps) => (
    //     <input
    //       type="checkbox"
    //       className="customerCheckBox form-check-input"
    //       onChange={() => deleteCheckbox()}
    //     />
    //   ),
    // },
    {
      name: <span className="font-weight-bold fs-13">Properties</span>,
      // sortable: true,
      width: '350px',
      cell: (row) => {
        console.log('rowrowrowrowrow', row);
        return (
          <div>
            <Link
              to={`/admin/property-details/${row?.propertyData[0]._id}`}
              className="text-dark"
            >
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm bg-light rounded p-1">
                    {row?.propertyImages[0]?.propertyImage ? (
                      <img
                        className="img-fluid d-block h-100 object-fit-cover"
                        src={`${IMG_URL}${row?.propertyImages[0]?.propertyImage}`}
                        alt="Header Avatar"
                      />
                    ) : (
                      <img
                        className="img-fluid d-block h-100 object-fit-cover"
                        src={userNoImage}
                        alt="Header Avatar"
                      />
                    )}
                  </div>
                </div>
                <div className="flex-grow-1">
                  <h5 className="fs-14 mb-1">
                    {/* <Link
                      to={`/admin/user-details?id=${row?.ownerData[0]?._id}`}
                      className="text-dark"
                    > */}
                    {row?.propertyData[0].title}
                    {/* </Link> */}
                  </h5>
                  <p className="text-muted mb-0">
                    <span className="fw-medium">
                      {' '}
                      {row?.propertyData[0].address}
                    </span>
                  </p>
                </div>
              </div>
            </Link>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Landlord</span>,
      // sortable: true,
      width: '230px',
      cell: (row) => {
        return (
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 me-3">
              <div className="avatar-sm bg-light rounded p-1">
                {row?.ownerData[0]?.profile ? (
                  <img
                    className="img-fluid d-block h-100 object-fit-cover"
                    src={`${IMG_URL}${row?.ownerData[0]?.profile}`}
                    alt="Header Avatar"
                  />
                ) : (
                  <img
                    className="img-fluid d-block h-100 object-fit-cover"
                    src={userNoImage}
                    alt="Header Avatar"
                  />
                )}
              </div>
            </div>
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <Link
                  to={`/admin/user-details?id=${row?.ownerData[0]?._id}`}
                  className="text-dark"
                >
                  {row?.ownerData[0]?.firstname} {row?.ownerData[0]?.lastname}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                <span className="fw-medium">
                  {' '}
                  {row?.ownerData[0]?.countryCode} {row?.ownerData[0]?.mobile}
                </span>
              </p>
            </div>
          </div>
        );
      },
    },
    // {
    //   name: <span className="font-weight-bold fs-13">Units</span>,
    //   selector: (row) => row?.propertyUnits?.length,
    //   sortable: true,
    // },
    {
      name: <span className="font-weight-bold fs-13">Price</span>,
      // sortable: true,
      cell: (row) => {
        return (
          <>
            <span>{'₦' + row?.propertyUnit[0].price}</span>{' '}
          </>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Created date</span>,
      selector: (row) => row?.propertyData[0].createdAt.substring(0, 10),
      // sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Units name</span>,
      // sortable: true,
      cell: (row) => {
        return <span>{row?.propertyUnit[0]?.unitName}</span>;
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Rating</span>,
      // sortable: true,
      cell: (row) => {
        return (
          <span>
            {/* <span className="badge bg-light text-body fs-12 fw-medium">
              <i className="mdi mdi-star text-warning me-1"></i>
              {row?.propertyData[0].rating}
            </span> */}

            <span className="badge bg-light text-body fs-12 fw-medium">
              {row?.propertyData[0].rating ? (
                <>
                  <i className="mdi mdi-star text-warning me-1"></i>
                  {row?.propertyData[0].rating}
                </>
              ) : (
                '-'
              )}
            </span>
          </span>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      // sortable: true,
      selector: (cellProps) => (
        <div className="form-check form-switch">
          <Input
            className="form-check-input"
            type="checkbox"
            role="switch"
            checked={!cellProps.isDeleted || false}
            // onClick={() => onClickActiveInactiveUser(cell._id, cell.isActive)}
            onClick={() => {
              onClickDelete(
                cellProps?.propertyData[0]?._id,
                cellProps?.propertyData[0]?.user,
                cellProps?.propertyData[0]?.title,
                cellProps?.propertyData[0]?.isDeleted
              );
            }}
          />
        </div>
      ),
    },
    // {
    //   name: <span className="font-weight-bold fs-13">Action</span>,
    //   sortable: true,
    //   cell: (cellProps) => {
    //     return (
    //       <ul className="list-inline hstack gap-2 mb-0">
    //         <li className="" title="Remove">
    //           <Link
    //             className="btn btn-icon btn-topbar btn-ghost-danger rounded-circle"
    //             onClick={() => {
    //               onClickDelete(
    //                 cellProps?.propertyData[0]?._id,
    //                 cellProps?.propertyData[0]?.user,
    //                 cellProps?.propertyData[0]?.title
    //               );
    //             }}
    //           >
    //             <i className="ri-delete-bin-5-fill fs-16"></i>
    //           </Link>
    //         </li>
    //       </ul>
    //     );
    //   },
    // },
  ];

  // Delete Data
  const handleDeleteProperty = async () => {
    try {
      setSpinFlag(true);
      let payload = {
        property_id: propertyDeletedId,
        user_id: userDeletedId,
        propertyTitle: propertyDeletedName,
      };
      const { data } = await deletePropertyFromAdmin(payload);
      if (data.statusCode === 200) {
        setDeleteModal(false);
        setFetchRender(!fetchRender);
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      } else {
        setDeleteModal(false);
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  return (
    <>
      {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProperty}
        onCloseClick={() => setDeleteModal(false)}
      /> */}
      <ActiveInactiveModel
        show={deleteModal}
        onActiveInactiveClick={handleDeleteProperty}
        onCloseClick={() => setDeleteModal(false)}
        messageActiveInactive={messageActiveInactive}
      />
      <Col lg={12}>
        <Card id="propertiesList">
          <CardHeader className="border-0">
            <Row>
              <Col>
                <div>
                  <h5 className="card-title mb-0">Properties List</h5>
                </div>
              </Col>
              <Col></Col>
            </Row>
          </CardHeader>
          <CardHeader className="border-0 pt-0 overflow-auto">
            <Nav
              className="nav-tabs-custom card-header-tabs border-bottom-0 flex-nowrap"
              role="tablist"
            >
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: tab === 'all' },
                    'fw-semibold'
                  )}
                  onClick={() => {
                    toggleTab('1');
                    setTab('all');
                    setSearch('');
                    setSearchByDate('');
                    setFetchRender(!fetchRender);
                  }}
                  href="#"
                >
                  All Properties
                  {/* <span className="badge badge-soft-danger align-middle rounded-pill ms-1">
                    {allPropertyList.length}
                  </span> */}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: tab === 'viewed' },
                    'fw-semibold'
                  )}
                  onClick={() => {
                    toggleTab('2');
                    setTab('viewed');
                    setSearch('');
                    setSearchByDate('');
                    setFetchRender(!fetchRender);
                  }}
                  href="#"
                >
                  Viewed Properties
                  {/* <span className="badge badge-soft-danger align-middle rounded-pill ms-1">
                    {allPropertyList.length}
                  </span> */}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: tab === 'booked' },
                    'fw-semibold'
                  )}
                  onClick={() => {
                    toggleTab('3');
                    setTab('booked');
                    setSearch('');
                    setSearchByDate('');
                    setFetchRender(!fetchRender);
                  }}
                  href="#"
                >
                  Booked Properties
                  {/* <span className="badge badge-soft-danger align-middle rounded-pill ms-1">
                    {bookedProperties.length}
                  </span> */}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: tab === 'available' },
                    'fw-semibold'
                  )}
                  onClick={() => {
                    toggleTab('4');
                    setTab('available');
                    setSearch('');
                    setSearchByDate('');
                    setFetchRender(!fetchRender);
                  }}
                  href="#"
                >
                  Available Properties
                  {/* <span className="badge badge-soft-danger align-middle rounded-pill ms-1">
                    10
                  </span> */}
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody className="p-0">
            {(allPropertyList && allPropertyList.length > 0) ||
            (bookedProperties.length > 0 && bookedProperties) ? (
              <div>
                {allPropertyList &&
                  allPropertyList.length > 0 &&
                  (tab === 'all' ||
                    tab === 'viewed' ||
                    tab === 'available') && (
                    <DatatablesCom
                      columns={columns}
                      data={allPropertyList}
                      setCurrentPage={setCurrentPage}
                      paginationTotalRows={paginationTotalRows}
                      setSearch={setSearch}
                      search={search}
                      fetchRender={fetchRender}
                      setFetchRender={setFetchRender}
                      optionsFlag={optionsFlag}
                      searchByDate={searchByDate}
                      setSearchByDate={setSearchByDate}
                      placeholder={placeholder}
                      setRowsPerPage={setRowsPerPage}
                      rowPerPage={rowPerPage}
                      setUserStatus={setUserStatus}
                      userStatus={userStatus}
                    />
                  )}

                {bookedProperties && tab === 'booked' && (
                  <DatatablesCom
                    columns={bookedPropertiesColumns}
                    data={bookedProperties}
                    setCurrentPage={setCurrentPage}
                    paginationTotalRows={paginationTotalRows}
                    setSearch={setSearch}
                    search={search}
                    fetchRender={fetchRender}
                    setFetchRender={setFetchRender}
                    optionsFlag={optionsFlag}
                    searchByDate={searchByDate}
                    setSearchByDate={setSearchByDate}
                    placeholder={placeholder}
                    setRowsPerPage={setRowsPerPage}
                    rowPerPage={rowPerPage}
                    setUserStatus={setUserStatus}
                    userStatus={userStatus}
                  />
                )}
              </div>
            ) : (
              <DatatablesCom
                setCurrentPage={setCurrentPage}
                paginationTotalRows={paginationTotalRows}
                setSearch={setSearch}
                search={search}
                fetchRender={fetchRender}
                setFetchRender={setFetchRender}
                optionsFlag={optionsFlag}
                searchByDate={searchByDate}
                setSearchByDate={setSearchByDate}
                placeholder={placeholder}
                setRowsPerPage={setRowsPerPage}
                rowPerPage={rowPerPage}
                setUserStatus={setUserStatus}
                userStatus={userStatus}
              />
            )}
          </CardBody>
        </Card>
      </Col>
      {/* <ShowSpinner spinFlag={spinFlag} /> */}
    </>
  );
};

export default PropertyList;
