import React, { useEffect, useState } from 'react';
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Pagination,
  CardHeader,
  Box,
  Paper,
  Backdrop,
  CircularProgress,
  Button,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { TableHeader } from '../dashboard/TableHeader';
import Scrollbar from '../../../components/scrollbar';
import { DataNotPayment } from '../../../components/data-not-found';
import { PropertiesListSearch } from '../dashboard/PropertiesListSearch';
import { getWithdrawalHistory } from '../../../services/apiServices';
import { nairaSymbol } from '../../../utils/constant';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';
import { useWithdrwalRequestStore } from '../../../redux/features/renderStateWithdrawalSlice';

const TABLE_HEAD = [
  { id: 'bank name', label: 'Bank Name', alignRight: false },
  { id: 'acc number', label: 'Account Number', alignRight: false },
  { id: 'acc name', label: 'Account Name', alignRight: false },
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
];

export const WithdrawalHistory = (props) => {
  let { title } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [spinFlag, setSpinFlag] = useState(false);
  const [message, setMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState();
  const [withdrawalList, setWithdrawalList] = useState([]);
  const [sort, setSort] = useState('');
  const [statusCode, setStatusCode] = useState();
  const [search, setSearch] = useState(null);
  const { renderWithdrwalRequestFlag } = useWithdrwalRequestStore();
  const handleFilterByName = (event) => {
    setSearch(event.target.value);
  };

  const fetchWithdrawalHistory = async () => {
    try {
      setSpinFlag(true);
      let payload = {
        currentPage: currentPage,
        sortByAlphabet: sort,
        search: search,
      };

      let { data } = await getWithdrawalHistory(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setWithdrawalList(data?.withdrawalList);
        setNumberOfPages(data?.noOfPages);
        setStatusCode(data?.statusCode);
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    if (search !== null) {
      const getData = setTimeout(() => {
        fetchWithdrawalHistory();
      }, 1000);
      return () => clearTimeout(getData);
    } else {
      fetchWithdrawalHistory();
    }
  }, [currentPage, search, renderWithdrwalRequestFlag]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const handleDetailPage = (val) => {
    if (val?.transfer_code) {
      navigate(`/payment/p_details?id=${val?.transfer_code}`);
    }
  };
  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <Card>
        <CardHeader
          title={title}
          action={
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                justifyContent: { md: 'end' },
                flexWrap: 'wrap',
              }}
            >
              <>
                <PropertiesListSearch
                  filterValues={search}
                  onFilterValues={handleFilterByName}
                />
              </>
            </Box>
          }
          sx={{
            pb: 2,
            flexWrap: 'wrap',
            borderBottom: {
              md: 'none',
              xs: '1px solid rgba(241, 243, 244, 1)',
            },
            '& .MuiCardHeader-content': {
              width: { md: '35%', xs: '100%' },
              pb: { md: 0, xs: 2 },
              borderBottom: {
                md: 'none',
                xs: '1px solid rgba(241, 243, 244, 1)',
              },
            },
            '& .MuiCardHeader-action': {
              width: { md: '65%', xs: '100%' },
              pt: { md: 0, xs: 2 },
              m: 0,
            },
          }}
        />

        <Box dir="ltr">
          <Scrollbar>
            <TableContainer
              sx={{
                minWidth: 1100,
                minHeight: 200,
              }}
            >
              <Table>
                <TableHeader headLabel={TABLE_HEAD} />
                <TableBody>
                  {withdrawalList?.map((val, index) => (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell align="left" sx={{ minWidth: 180 }}>
                        {val?.bank}
                      </TableCell>
                      <TableCell align="left">{val?.accountNumber}</TableCell>
                      <TableCell align="left">{val?.accountName}</TableCell>
                      <TableCell align="left">
                        {nairaSymbol}
                        {/* {val?.amount ? (val?.amount).toFixed(2) : ''} */}
                        {val?.amount}
                      </TableCell>

                      <TableCell align="left">
                        {val.createdAt.slice(0, 10)} <br />
                      </TableCell>

                      <TableCell align="left">
                        <Paper
                          sx={{
                            border: `1px solid ${
                              (val?.status === 'Completed' && '#12B76A') ||
                              (val?.status === 'Pending' && '#FF6645') ||
                              ''
                            }`,
                            bgcolor: `${
                              (val?.status === 'Completed' && '#ECFDF3') ||
                              (val?.status === 'Pending' && '#FFEFED') ||
                              ''
                            }`,
                            borderRadius: '8px',
                            width: '98px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: `${
                              (val?.status === 'completed' && '#12B76A') ||
                              (val?.status === 'pending' && '#FF6645') ||
                              ''
                            }`,
                          }}
                        >
                          {val?.status}
                        </Paper>
                      </TableCell>
                      <TableCell align="left">
                        {val?.status === 'completed' ? (
                          <Button
                            variant="contained"
                            onClick={() => handleDetailPage(val)}
                          >
                            View
                          </Button>
                        ) : (
                          ''
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {withdrawalList?.length === 0 && (
                    <TableRow>
                      <TableCell
                        colSpan={TABLE_HEAD?.length}
                        sx={{ border: 'none' }}
                      >
                        <DataNotPayment title="You don't have withdrawal list" />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {withdrawalList?.length !== 0 && (
            <Pagination
              shape="rounded"
              count={numberOfPages}
              siblingCount={0}
              boundaryCount={2}
              onChange={handlePageChange}
              sx={{
                py: 2,
                '& .MuiPagination-ul': { justifyContent: 'center' },
              }}
            />
          )}
        </Box>
      </Card>
    </>
  );
};
