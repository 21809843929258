import crypto from 'crypto-js';
import { CRPTO_SECRET_KEY } from '../utils/constant.js';
import { addUserLogged } from '../services/apiServices.js';
export const currencySeprator = (value) => {
  return new Intl.NumberFormat('en-NG').format(value);
};
export const getNumberWithoutComma = (input) => {
  return input
    .toLocaleString()
    .replace(/,/g, '')
    .replace(/[^0-9]/g, '');
};

export async function encryptedValueCrypto(dataToEncrypt) {
  const secretKey = CRPTO_SECRET_KEY; // Replace with a strong secret key
  var encryptedData = crypto.AES.encrypt(dataToEncrypt, secretKey).toString();
  return encryptedData;
}

export async function decryptedValueCrypto(encryptedData) {
  const secretKey = CRPTO_SECRET_KEY; // Replace with a strong secret key
  var bytes = crypto.AES.decrypt(encryptedData, secretKey);
  var decryptedData = bytes.toString(crypto.enc.Utf8);
  return decryptedData;
}

export async function createUserLogged(event, description, time, user_id) {
  try {
    const payload = {
      event: event,
      description: description,
      time: time,
      user_id: user_id,
    };
    const { data } = await addUserLogged(payload);
    if (data.statusCode == 200) {
      console.log('data message createUserLogged:', data?.message);
    } else {
      console.log('error in createUserLogged');
    }
  } catch (err) {
    console.log('errrr', err);
  }
}

export const getDateAndTime = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  };
  const formattedTime = date?.toLocaleTimeString('en-US', options);

  return formattedTime;
};
