import React, { useState, useEffect, useRef } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { BannerSection } from './BannerSection';
import { PopularSpacesSection } from './PopularSpacesSection';
import { ListSpacesSection } from './ListSpacesSection';
import { BecomeHostSection } from './BecomeHostSection';
import {
  getAvailableSpacesContent,
  getCmsContent,
  fetchAllCities,
  getAllProperty,
} from '../../services/apiServices';
import { useLocation, useNavigate } from 'react-router-dom';
import { DialogPopup } from '../../components/dialog-popup/DialogPopup';
import { useAuthStore } from '../../redux/features/authSlice';
import { PropertyFilter } from './PropertyFilter';

export const AvailableSpaces = () => {
  //
  const query = new URLSearchParams(useLocation().search);
  const lat = query.get('lat');
  const lng = query.get('lng');
  let { loginInfo } = useAuthStore();
  //
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  const [availableSpacesContents, setAvailableSpacesContents] = useState();
  const [spinFlag, setSpinFlag] = useState(false);
  const [availRenderFlag, setAvailRenderFlag] = useState();
  const [bannerSection, setBannerSection] = useState([]);
  const [exploreRentals, setExploreRentals] = useState([]);
  const [becomeLandlord, setBecomeLandlord] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [statusCode, setStatusCode] = useState();
  const resultRef = useRef(null);
  //
  const [priceRangeFilter, setPriceRangeFilter] = useState([]);
  const [priceDuration, setPriceDuration] = useState(null);
  const [cities, setCities] = useState([]);
  const [bedroomFilter, setBedroomFilter] = useState('any');
  const [propertyTypeFilter, setPropertyTypeFilter] = useState('');
  const [amenitiesFilter, setAmenitiesFilter] = useState([]);
  const [openFilterPop, setFilterPop] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    checkIn: '',
    checkOut: '',
  });
  const [moveInDate, setMoveInDate] = useState(null);
  const [leaseDuration, setLeaseDuration] = useState([]);
  const [visibleProperties, setVisibleProperties] = useState(9);
  const [cityCheckbox, setCityCheckbox] = useState([]);
  const [propertiesData, setPropertiesData] = useState([]);
  const [totalProperty, setTotalProperty] = useState();

  // const [open, setOpen] = useState(false);
  // const [message, setMessage] = useState('');
  // const [statusCode, setStatusCode] = useState();
  //

  const availableSpacesContent = async () => {
    try {
      const { data } = await getAvailableSpacesContent();
      if (data.statusCode === 200) {
        setAvailableSpacesContents(data?.availableSpaces);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const fetchListContent = async () => {
    try {
      setSpinFlag(true);
      const payload = { type: pathname };
      const { data } = await getCmsContent(payload);
      if (data.statusCode === 200) {
        setBannerSection(
          data?.cmsContent?.filter((item) => item?.section === 'bannerSection')
        );
        setExploreRentals(
          data?.cmsContent?.filter((item) => item?.section === 'exploreRentals')
        );
        setBecomeLandlord(
          data?.cmsContent?.filter((item) => item?.section === 'becomeLandlord')
        );
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setFilterPop(true);
  };
  const handleClose = () => {
    setFilterPop(false);
  };

  //
  // get all properties
  const getProperties = async () => {
    try {
      setSpinFlag(true);
      if (
        (dateFilter?.checkIn && !dateFilter?.checkOut) ||
        (!dateFilter?.checkIn && dateFilter?.checkOut)
      ) {
        setOpen(true);
        setMessage('Invalid date');
        setStatusCode(401);
        return;
      }

      let user_id = loginInfo ? loginInfo._id : null;
      let payload = {
        lat: lat,
        lng: lng,
        user_id: user_id,
        // priceDuration: priceDuration,
        minPrice: state ? state.priceRangeFilter[0] : priceRangeFilter[0],
        maxPrice: state ? state.priceRangeFilter[1] : priceRangeFilter[0],
        cities: state ? state.cities : cities,
        // bedroom: bedroomFilter,
        bedroom: state ? state.bedroom : bedroomFilter,
        // propertyType: propertyTypeFilter,
        propertyType: state ? state.propertyType : propertyTypeFilter,
        // amenities: amenitiesFilter,
        amenities: state ? state.amenities : amenitiesFilter,
        leaseDuration: state ? state.leaseDuration : leaseDuration,
        moveInDate: state ? state.moveInDate : moveInDate,

        visibleProperties: visibleProperties,

        // checkIn: dateFilter?.checkIn,
        // checkOut: dateFilter?.checkOut,
      };
      const { data } = await getAllProperty(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setPropertiesData(data.properties);
        setTotalProperty(data.totalProperty);
        setFilterPop(false);
        setStatusCode(data.statusCode);
        if (((lat && lng) || availRenderFlag) && resultRef.current) {
          resultRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const getCities = async () => {
    try {
      setSpinFlag(true);
      const { data } = await fetchAllCities();
      if (data.statusCode === 200) {
        setCityCheckbox(data?.cities);
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };
  //

  useEffect(() => {
    availableSpacesContent();
    fetchListContent();
    getProperties();
    getCities();
  }, [availRenderFlag, lat, lng, visibleProperties]);
  return (
    <>
      <BannerSection
        // availableSpacesContents={availableSpacesContents}
        resultRef={resultRef}
        bannerSection={bannerSection}
        handleClickOpen={handleClickOpen}
      />
      <PopularSpacesSection
        exploreRentals={exploreRentals}
        availableSpacesContents={availableSpacesContents}
        availRenderFlag={availRenderFlag}
        setAvailRenderFlag={setAvailRenderFlag}
        setSpinFlag={setSpinFlag}
      />
      <ListSpacesSection
        availRenderFlag={availRenderFlag}
        setAvailRenderFlag={setAvailRenderFlag}
        resultRef={resultRef}
        bedroomFilter={bedroomFilter}
        handleClickOpen={handleClickOpen}
        visibleProperties={visibleProperties}
        setVisibleProperties={setVisibleProperties}
        propertiesData={propertiesData}
        totalProperty={totalProperty}
      />
      <BecomeHostSection
        availableSpacesContents={availableSpacesContents}
        setSpinFlag={setSpinFlag}
        becomeLandlord={becomeLandlord}
      />
      {/*  */}
      <PropertyFilter
        setRenderFlag={setAvailRenderFlag}
        renderFlag={availRenderFlag}
        setPriceRangeFilter={setPriceRangeFilter}
        setPriceDuration={setPriceDuration}
        setCities={setCities}
        setBedroomFilter={setBedroomFilter}
        setPropertyTypeFilter={setPropertyTypeFilter}
        setAmenitiesFilter={setAmenitiesFilter}
        handleClose={handleClose}
        setDateFilter={setDateFilter}
        open={openFilterPop}
        city={cityCheckbox}
        setMoveInDate={setMoveInDate}
        moveInDate={moveInDate}
        setLeaseDuration={setLeaseDuration}
        leaseDuration={leaseDuration}
        pathname={pathname}
      />
      {/*  */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogPopup
        handleClose={handleCloseDialog}
        open={open}
        message={message}
        setOpen={setOpen}
        statusCode={statusCode}
        size="xs"
      />
    </>
  );
};
