import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Col, Form, Input, Label, Row } from 'reactstrap';
import 'quill/dist/quill.snow.css';
import { IMG_URL } from '../../../../utils/constant';
import { addOrEditContactUs } from '../../../../services/apiServices';
import dummyImgIcon from '../../../assets/images/logo-sm.png';

const AddEditInfo = (props) => {
  let {
    handleClose,
    setSpinFlag,
    setResponse,
    contactUsData,
    isEdit,
    setAmenitiesListRendering,
    amenitiesListRendering,
  } = props;
  const uploadedImage = useRef();
  const [formData, setFormData] = useState({
    icon: '',
    info: '',
    heading: '',
    subHeading: '',
  });
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  //for image validation
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    // setImageError('');
    if (
      file?.type === 'image/jpg' ||
      file?.type === 'image/jpeg' ||
      file?.type === 'image/png' ||
      file?.type === 'image/svg+xml' ||
      file?.type === 'image/gif'
    ) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      setFormData({ ...formData, icon: file });
      setErrors({ ...errors, icon: '' });
    } else {
      setFormData({ ...formData, icon: '' });
      setErrors({ ...errors, icon: 'Image must be in valid format' });
    }
  };
  useEffect(() => {
    if (isEdit) {
      setFormData({
        icon: contactUsData.icon,
        info: contactUsData.info,
        heading: contactUsData.heading,
        subHeading: contactUsData.subHeading,
      });
    }
  }, []);

  const toAddOrEditContactUs = async () => {
    try {
      setSpinFlag(true);
      // console.log('isisisis', formData);
      formData.isEdit = isEdit;
      let contactUsData = new FormData();
      contactUsData.append('data', JSON.stringify(formData));
      contactUsData.append('icon', formData.icon);
      let { data } = await addOrEditContactUs(contactUsData);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
        setAmenitiesListRendering(!amenitiesListRendering);
        handleClose();
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validation logic
    const newErrors = {};

    if (!formData.icon) {
      newErrors.icon = 'icon is required';
    }

    if (!formData.heading.trim()) {
      newErrors.heading = 'Heading is required';
    }
    if (!formData.subHeading.trim()) {
      newErrors.subHeading = 'subHeading is required';
    }
    if (!formData.info.trim()) {
      newErrors.info = 'Info is required';
    }
    // Check for errors
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      toAddOrEditContactUs();
    }
  };
  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg={12}>
            <div className="text-center mb-4">
              <div className="position-relative d-inline-block">
                <div className="position-absolute bottom-0 end-0">
                  <label
                    htmlFor="member-image-input"
                    className="mb-0"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Select Member Image"
                  >
                    <div className="avatar-xs">
                      <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                        <i className="ri-image-fill"></i>
                      </div>
                    </div>
                  </label>
                  <input
                    className="form-control d-none"
                    defaultValue=""
                    id="member-image-input"
                    name="path"
                    type="file"
                    onChange={handleImageUpload}
                    accept="image/png, image/gif, image/jpeg"
                  />
                </div>
                <div className="avatar-lg">
                  <div className="avatar-title bg-light rounded-circle">
                    <img
                      src={
                        isEdit
                          ? `${IMG_URL}${contactUsData?.icon}`
                          : dummyImgIcon
                      }
                      alt=" "
                      id="member-img"
                      className="avatar-md rounded-circle h-auto"
                      ref={uploadedImage}
                    />
                  </div>
                  {errors.icon && (
                    <div className="text-danger">{errors.icon}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Heading
              </Label>
              <Input
                type="text"
                className="form-control"
                id="title"
                placeholder="Title"
                name="heading"
                onChange={handleChange}
                value={formData.heading}
              />
              {errors.heading && (
                <div className="text-danger">{errors.heading}</div>
              )}
            </div>
            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Sub heading
              </Label>
              <textarea
                type="text"
                className="form-control"
                id=""
                placeholder="Designation"
                name="subHeading"
                rows="2"
                onChange={handleChange}
                style={{ resize: 'none' }}
                value={formData.subHeading}
              />
              {errors.subHeading && (
                <div className="text-danger">{errors.subHeading}</div>
              )}
            </div>
            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Info
              </Label>
              <Input
                type="text"
                className="form-control"
                id=""
                placeholder="Info"
                name="info"
                onChange={handleChange}
                value={formData.info}
              />
              {errors.info && <div className="text-danger">{errors.info}</div>}
            </div>
          </Col>
          <Col lg={12}>
            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn-light" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" className="btn-success">
                {isEdit ? 'Save' : 'Add ContactUs'}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default AddEditInfo;
