import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Row,
  Col,
  ListGroup,
  Input,
  Label,
  ListGroupItem,
  Form,
} from 'reactstrap';
import {
  createRoles,
  deleteRoles,
  getLogo,
  getRoles,
} from '../../../../services/apiServices';
import DeleteModal from '../../../Components/Common/DeleteModal';
const AddRoles = (props) => {
  let { setSpinFlag, setResponse } = props;
  const [formData, setFormData] = useState({ role: '' });
  const [errors, setErrors] = useState({});
  const [render, setRender] = useState(false);
  const [roles, setRoles] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [roleDeleteId, setRoleDeleteId] = useState(null);
  const [roleName, setRoleName] = useState();
  useEffect(() => {
    fetchRole();
  }, [render]);
  const fetchRole = async () => {
    try {
      setSpinFlag(true);
      const { data } = await getRoles();
      if (data.statusCode === 200) {
        setRoles(data?.allRoles);
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const toAddRoles = async () => {
    try {
      setSpinFlag(true);
      const payload = { role_name: formData?.role };
      let { data } = await createRoles(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
        setRender(!render);
        setFormData({
          ...formData,
          role: '',
        });
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
        setFormData({
          ...formData,
          role: '',
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handleDeleteRoles = async () => {
    try {
      setSpinFlag(true);
      let payload = { roleId: roleDeleteId, roleName: roleName };
      let { data } = await deleteRoles(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
        setDeleteModal(false);
        setRender(!render);
      } else {
        setDeleteModal(false);
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const onClickDelete = (id, roleName) => {
    // console.log('wshcgshxcg', id);
    setRoleDeleteId(id);
    setRoleName(roleName);
    setDeleteModal(true);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!formData?.role?.trim()) {
      newErrors.role = 'Role is required';
    }
    // Check for errors
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      toAddRoles();
    }
  };
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteRoles}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Form onSubmit={handleSubmit}>
        <div className="mb-3">
          <Label className="form-label" htmlFor="product-title-input">
            Role
          </Label>
          <div className="">
            <Row className="">
              <Col sm={6} className="mb-3">
                <Input
                  name="role"
                  className="form-control"
                  placeholder="Enter role"
                  type="text"
                  onChange={handleChange}
                  value={formData?.role}
                />
                {errors.role && (
                  <div className="text-danger">{errors.role}</div>
                )}
              </Col>
              <Col sm={4} className="mb-3">
                <Button type="submit" className="btn btn-success w-sm">
                  <i className="ri-add-fill me-1 align-bottom"></i> Add Role
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Form>

      <div className="roles-list" style={{ maxWidth: '500px' }}>
        <ListGroup>
          {roles?.map((item, index) => {
            return (
              <ListGroupItem className="d-flex align-items-center fw-semibold text-capitalize">
                <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i>
                {item?.role_name}
                <button
                  className="btn btn-sm btn-icon btn-topbar btn-ghost-danger rounded-circle ms-auto"
                  onClick={() => {
                    onClickDelete(item?._id, item?.role_name);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </button>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </div>
    </React.Fragment>
  );
};

export default AddRoles;
