import React, { useState, useEffect } from 'react';
import { Grid, Backdrop, CircularProgress } from '@mui/material';
import { CurrentUnit } from './CurrentUnit';
import { AppWidgetSummaryMain } from './AppWidgetSummaryMain';
import { AppWidgetSummary } from './AppWidgetSummary';
import { AppPayments } from './AppPayments';
import { AppMessages } from './AppMessages';
import { MyPropertiesList } from './MyPropertiesList';
import { BookingRequest } from './BookingRequest';
import { BookingDetails } from './BookingDetails';
import { MyListing } from './MyListing';
import { FavouritePlace } from './FavouritePlace';
import { UnitHistory } from './UnitHistory';
import Customers from '../../../assets/icons/Customers.svg';
import City from '../../../assets/icons/City.svg';
import { ConfirmProvider } from 'material-ui-confirm';
import { useAuthStore } from '../../../redux/features/authSlice';
import { HeaderBreadcrumbs } from '../../../components/breadcrumbs';

export const Dashboard = () => {
  let { loginInfo } = useAuthStore();
  const [totalProperty, setTotalProperty] = useState(0);
  const [totalCity, setTotalCity] = useState(0);
  const [totalCustomer, setTotalCustomer] = useState(0);
  const [myListRender, setMyListRender] = useState(false);
  const [spinFlag, setSpinFlag] = useState(false);

  return (
    <>
      <HeaderBreadcrumbs heading="Dashboard" />
      <Grid container spacing={3}>
        {loginInfo.role === 'renter' ? (
          <>
            <Grid item xs={12}>
              <CurrentUnit
                myListRender={myListRender}
                setMyListRender={setMyListRender}
                loginInfo={loginInfo}
                setSpinFlag={setSpinFlag}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <FavouritePlace
                title="Favourite place"
                myListRender={myListRender}
                setMyListRender={setMyListRender}
                setSpinFlag={setSpinFlag}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <UnitHistory
                myListRender={myListRender}
                setMyListRender={setMyListRender}
                title="Unit history"
                setSpinFlag={setSpinFlag}
              />
            </Grid>
            <Grid item xs={12}>
              <ConfirmProvider>
                <MyListing
                  title="My listing"
                  myListRender={myListRender}
                  setMyListRender={setMyListRender}
                  setSpinFlag={setSpinFlag}
                  loginInfo={loginInfo}
                />
              </ConfirmProvider>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={5} xl={4}>
              <AppWidgetSummaryMain
                title="Total listings"
                totalProperty={totalProperty}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={7} xl={8} gap="15px" display="grid">
              <AppWidgetSummary
                title="Total Renters"
                total={totalCustomer}
                icon={Customers}
              />
              <AppWidgetSummary
                title="Total cities"
                total={totalCity}
                icon={City}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <AppPayments title="Payments" />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <AppMessages title="Messages" />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <ConfirmProvider>
                <MyPropertiesList
                  setTotalProperty={setTotalProperty}
                  setTotalCity={setTotalCity}
                  setTotalCustomer={setTotalCustomer}
                  setSpinFlag={setSpinFlag}
                  title="My properties"
                  // subheader="500 more to break last month record"
                />
              </ConfirmProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <ConfirmProvider>
                <BookingRequest
                  title="Rental request"
                  setSpinFlag={setSpinFlag}
                  renderFlag={myListRender}
                  setRenderFlag={setMyListRender}
                  loginInfo={loginInfo}
                />
              </ConfirmProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <ConfirmProvider>
                <BookingDetails
                  title="Properties booking details"
                  setSpinFlag={setSpinFlag}
                  renderFlag={myListRender}
                  setRenderFlag={setMyListRender}
                  loginInfo={loginInfo}
                />
              </ConfirmProvider>
            </Grid>
          </>
        )}
      </Grid>

      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
