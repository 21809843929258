import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Input } from 'reactstrap';
import DatatablesCom from '../../Components/Tables/DataTables/DatatablesCom';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { getAllPayoutHistory } from '../../../services/apiServices';
import { nairaSymbol } from '../../../utils/constant';

const customStyles = {
  rows: {
    style: {
      // minHeight: '72px',
    },
  },
  headCells: {
    style: {
      padding: '8px',
      background: '#f3f6f9',
      fontWeight: 'bold',
      color: '#878a99',
    },
  },
  cells: {
    style: {
      padding: '8px',
    },
  },
};

const TransactionList = ({
  setSpinFlag,
  setResponse,
  fetchRender,
  setFetchRender,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationTotalRows, setPaginationTotalRows] = useState(1);
  const [rowPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState();
  const [searchByDate, setSearchByDate] = useState();
  const [placeholder, setPlaceholder] = useState('Search by reference id');

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Reference ID</span>,
      cell: (row) => {
        return <div className="font-weight-bold">{row?.reference}</div>;
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Recipient Code</span>,
      selector: (row) => row?.recipient?.recipient_code,
    },
    // {
    //   name: <span className="font-weight-bold fs-13">Customer</span>,
    //   sortable: true,
    //   cell: (row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         {row.img ? (
    //           <img
    //             src={process.env.REACT_APP_API_URL + '/images/users/' + row.img}
    //             alt=""
    //             className="avatar-xs rounded-circle me-2"
    //           />
    //         ) : (
    //           <div className="flex-shrink-0 avatar-xs me-2">
    //             <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
    //               {row.name.charAt(0) +
    //                 row.name.split(' ').slice(-1).toString().charAt(0)}
    //             </div>
    //           </div>
    //         )}
    //         {row.name}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   name: <span className="font-weight-bold fs-13">Email</span>,
    //   selector: (row) => row?.recipient?.email,
    // },
    // {
    //   name: <span className="font-weight-bold fs-13">Name</span>,
    //   selector: (row) => row?.recipient?.name,
    // },
    {
      name: <span className="font-weight-bold fs-13">Created Date</span>,
      selector: (row) => row?.createdAt?.slice(0, 10),
    },
    {
      name: <span className="font-weight-bold fs-13">Amount</span>,
      selector: (row) => {
        return (
          <>
            {nairaSymbol}
            {row?.amount / 100}
          </>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Payment status</span>,
      selector: (cell) => {
        switch (cell?.status) {
          case 'success':
            return (
              <span className="badge text-uppercase badge-soft-success">
                {cell?.status}
              </span>
            );
          case 'abandoned':
            return (
              <span className="badge text-uppercase badge-soft-danger">
                {cell?.status}
              </span>
            );

          default:
            return (
              <span className="badge text-uppercase badge-soft-warning">
                {cell?.status}
              </span>
            );
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      // sortable: true,
      cell: (cellProps) => {
        return (
          <ul className="list-inline hstack gap-2 mb-0">
            <li className=" edit" title="View">
              <Link
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                to={`/admin/payment-details?id=${cellProps?.transfer_code}`}
              >
                <i className="ri-eye-fill fs-16"></i>
              </Link>
            </li>
          </ul>
        );
      },
    },
  ];

  const fetchPayoutList = async () => {
    try {
      setSpinFlag(true);
      let payload = { currentPage, rowPerPage };
      if (search) payload['search'] = search;
      if (searchByDate) payload['searchByDate'] = searchByDate;
      const { data } = await getAllPayoutHistory(payload);
      if (data?.statusCode == 200) {
        setData(data?.data);
        setSpinFlag(false);
        setPaginationTotalRows(data?.allUserCount);
      } else {
        setSpinFlag(false);
      }
    } catch (error) {
      setSpinFlag(false);
      navigate(`/admin/error?msg=${error}&&path=${pathname}`);
    }
  };
  useEffect(() => {
    fetchPayoutList();
  }, [fetchRender, currentPage, rowPerPage]);

  return (
    <>
      <Col lg={12}>
        <Card id="transactionList">
          <CardHeader className="border-0">
            <Row>
              <div className="col-sm">
                <div>
                  <h5 className="card-title mb-0">Payout history</h5>
                </div>
              </div>
              <div className="col-sm-auto"></div>
            </Row>
          </CardHeader>
          <CardBody className="p-0">
            <div>
              <DatatablesCom
                flag={1}
                columns={columns}
                data={data}
                customStyles={customStyles}
                setCurrentPage={setCurrentPage}
                paginationTotalRows={paginationTotalRows}
                setSearch={setSearch}
                search={search}
                fetchRender={fetchRender}
                setFetchRender={setFetchRender}
                searchByDate={searchByDate}
                setSearchByDate={setSearchByDate}
                placeholder={placeholder}
                setRowsPerPage={setRowsPerPage}
                rowPerPage={rowPerPage}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default TransactionList;
