import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, CardBody, Form, FormFeedback, Input } from 'reactstrap';
import { socialMediaLink } from '../../../../utils/validation/Admin';
import {
  getSocialMediaLink,
  updateSocialMediaLink,
} from '../../../../services/apiServices';
import dummyImgIcon from '../../../assets/images/profileDam.png';
import { IMG_URL } from '../../../../utils/constant';
import { Icon } from '@mui/material';

const SocialMediaLink = (props) => {
  const { setSpinFlag, setResponse } = props;
  const [socialData, setSocialData] = useState([]);
  const [deletdIcons, setDeletedIcons] = useState([]);
  const [formData, setFormData] = useState({
    link1: '',
    link2: '',
    link3: '',
    link4: '',
    icon1: '',
    icon2: '',
    icon3: '',
    icon4: '',
  });
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [render, setRender] = useState(true);
  const uploadedImage1 = useRef();
  const uploadedImage2 = useRef();
  const uploadedImage3 = useRef();
  const uploadedImage4 = useRef();
  useEffect(() => {
    fetchSocialMediaLink();
  }, [render]);

  const fetchSocialMediaLink = async () => {
    try {
      setSpinFlag(true);
      const { data } = await getSocialMediaLink();
      if (data.statusCode === 200) {
        setSocialData(data?.links?.socailMediaData);
        let socialData = data?.links?.socailMediaData;
        setFormData({
          ...formData,
          link1: socialData ? socialData[0]?.link : '',
          link2: socialData ? socialData[1]?.link : '',
          link3: socialData ? socialData[2]?.link : '',
          link4: socialData ? socialData[3]?.link : '',
          icon1: socialData ? socialData[0]?.icon : '',
          icon2: socialData ? socialData[1]?.icon : '',
          icon3: socialData ? socialData[2]?.icon : '',
          icon4: socialData ? socialData[3]?.icon : '',
        });
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  //for image validation
  const handleImageUpload = (e, id) => {
    const file = e.target.files[0];
    if (
      file?.type === 'image/jpg' ||
      file?.type === 'image/jpeg' ||
      file?.type === 'image/png' ||
      file?.type === 'image/svg+xml' ||
      file?.type === 'image/svg' ||
      file?.type === 'image/gif'
    ) {
      const reader = new FileReader();
      let currentref;
      if (id == 1) {
        currentref = uploadedImage1.current;
      }
      if (id == 2) {
        currentref = uploadedImage2.current;
      }
      if (id == 3) {
        currentref = uploadedImage3.current;
      }
      if (id == 4) {
        currentref = uploadedImage4.current;
      }

      currentref.file = file;
      reader.onload = (e) => {
        currentref.src = e.target.result;
      };
      reader.readAsDataURL(file);
      setFormData({
        ...formData,
        [e.target.name]: file,
      });
      setErrors({ ...errors, icon1: '' });
    } else {
      setFormData({ ...formData, icon: '' });
      setErrors({ ...errors, icon: 'Image must be in valid format' });
    }
  };

  const toAddOrEditContactUs = async () => {
    try {
      setSpinFlag(true);
      let socialMedialData = new FormData();
      socialMedialData.append('data', JSON.stringify(formData));
      socialMedialData.append('deletedArray', JSON.stringify(deletdIcons));
      socialMedialData.append('icon1', formData.icon1);
      socialMedialData.append('icon2', formData.icon2);
      socialMedialData.append('icon3', formData.icon3);
      socialMedialData.append('icon4', formData.icon4);
      const { data } = await updateSocialMediaLink(socialMedialData);
      if (data.statusCode === 200) {
        setRender(!render);
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
        // resetForm();
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
        // resetForm();
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      toAddOrEditContactUs();
    }
  };
  const deleteIcon = async (deletedId) => {
    setFormData({
      ...formData,
      [`icon${deletedId + 1}`]: '',
    });
    const arr = [...deletdIcons];
    arr.push(deletedId);
    setDeletedIcons(arr);
  };
  return (
    <React.Fragment>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <div>
          <div className="d-flex align-items-center mb-4">
            <div className="flex-grow-1">
              <h5 className="card-title mb-0">Social Link</h5>
            </div>
          </div>
          <div className="mb-3 d-flex">
            <div className="avatar-xs d-block flex-shrink-0 me-3 social-icon">
              <span className="avatar-title rounded-circle bg-primary position-relative">
                <div className="position-absolute bottom-0 end-0 social-icon-upload">
                  <div className="avatar-xs">
                    <div className="avatar-title bg-light border rounded-circle cursor-pointer text-danger">
                      <i
                        class="ri-close-line"
                        onClick={() => deleteIcon(0)}
                      ></i>
                    </div>
                  </div>
                </div>
                <div className="position-absolute w-100 h-100 rounded-circle social-icon-upload-label">
                  <label
                    htmlFor="member-image-input1"
                    className="mb-0 w-100 h-100 rounded-circle cursor-pointer"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Select Member Image"
                  >
                    <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                      <i className="ri-image-fill"></i>
                    </div>
                  </label>
                  <input
                    className="form-control d-none"
                    defaultValue=""
                    id="member-image-input1"
                    name="icon1"
                    type="file"
                    onChange={(e) => handleImageUpload(e, 1)}
                    accept="image/png, image/gif, image/jpeg"
                  />
                </div>
                <div className="avatar-title bg-light rounded-circle">
                  <img
                    src={
                      formData?.icon1
                        ? `${IMG_URL}${formData?.icon1}`
                        : dummyImgIcon
                    }
                    alt={formData?.icon1}
                    id="member-img1"
                    className="avatar-xs object-fit-contain rounded-circle"
                    ref={uploadedImage1}
                  />
                  {/* {errors.icon && (
                    <div className="text-danger">{errors.icon}</div>
                  )} */}
                </div>
              </span>
            </div>
            <Input
              name="link1"
              className="form-control"
              placeholder="Enter Link here"
              type="text"
              onChange={handleChange}
              value={formData?.link1}
            />
          </div>

          <div className="mb-3 d-flex">
            <div className="avatar-xs d-block flex-shrink-0 me-3 social-icon">
              <span className="avatar-title rounded-circle bg-primary position-relative">
                <div className="position-absolute bottom-0 end-0 social-icon-upload">
                  <div className="avatar-xs">
                    <div className="avatar-title bg-light border rounded-circle cursor-pointer text-danger">
                      <i
                        class="ri-close-line"
                        onClick={() => deleteIcon(1)}
                      ></i>
                    </div>
                  </div>
                </div>
                <div className="position-absolute w-100 h-100 rounded-circle social-icon-upload-label">
                  <label
                    htmlFor="member-image-input2"
                    className="mb-0 w-100 h-100 rounded-circle cursor-pointer"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Select Member Image"
                  >
                    <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                      <i className="ri-image-fill"></i>
                    </div>
                  </label>
                  <input
                    className="form-control d-none"
                    defaultValue=""
                    id="member-image-input2"
                    name="icon2"
                    type="file"
                    onChange={(e) => handleImageUpload(e, 2)}
                    accept="image/png, image/gif, image/jpeg"
                  />
                </div>
                <div className="avatar-title bg-light rounded-circle">
                  <img
                    src={
                      formData?.icon2
                        ? `${IMG_URL}${formData?.icon2}`
                        : dummyImgIcon
                    }
                    alt={formData?.icon2}
                    id="member-img2"
                    className="avatar-xs object-fit-contain rounded-circle"
                    ref={uploadedImage2}
                  />
                  {/* {errors.icon && (
                    <div className="text-danger">{errors.icon}</div>
                  )} */}
                </div>
              </span>
            </div>
            <Input
              name="link2"
              className="form-control"
              placeholder="Enter Link here"
              type="text"
              onChange={handleChange}
              value={formData?.link2}
            />
          </div>

          <div className="mb-3 d-flex">
            <div className="avatar-xs d-block flex-shrink-0 me-3 social-icon">
              <span className="avatar-title rounded-circle bg-primary position-relative">
                <div className="position-absolute bottom-0 end-0 social-icon-upload">
                  <div className="avatar-xs">
                    <div className="avatar-title bg-light border rounded-circle cursor-pointer text-danger">
                      <i
                        class="ri-close-line"
                        onClick={() => deleteIcon(2)}
                      ></i>
                    </div>
                  </div>
                </div>
                <div className="position-absolute w-100 h-100 rounded-circle social-icon-upload-label">
                  <label
                    htmlFor="member-image-input3"
                    className="mb-0 w-100 h-100 rounded-circle cursor-pointer"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Select Member Image"
                  >
                    <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                      <i className="ri-image-fill"></i>
                    </div>
                  </label>
                  <input
                    className="form-control d-none"
                    defaultValue=""
                    id="member-image-input3"
                    name="icon3"
                    type="file"
                    onChange={(e) => handleImageUpload(e, 3)}
                    accept="image/png, image/gif, image/jpeg"
                  />
                </div>
                <div className="avatar-title bg-light rounded-circle">
                  <img
                    src={
                      formData?.icon3
                        ? `${IMG_URL}${formData?.icon3}`
                        : dummyImgIcon
                    }
                    alt={formData?.icon3}
                    id="member-img3"
                    className="avatar-xs object-fit-contain rounded-circle"
                    ref={uploadedImage3}
                  />
                  {/* {errors.icon && (
                    <div className="text-danger">{errors.icon}</div>
                  )} */}
                </div>
              </span>
            </div>
            <Input
              className="form-control"
              placeholder="Enter Link here"
              type="text"
              name="link3"
              onChange={handleChange}
              value={formData?.link3}
            />
          </div>

          <div className="mb-3 d-flex">
            <div className="avatar-xs d-block flex-shrink-0 me-3 social-icon">
              <span className="avatar-title rounded-circle bg-primary position-relative">
                <div className="position-absolute bottom-0 end-0 social-icon-upload">
                  <div className="avatar-xs">
                    <div className="avatar-title bg-light border rounded-circle cursor-pointer text-danger">
                      <i
                        class="ri-close-line"
                        onClick={() => deleteIcon(3)}
                      ></i>
                    </div>
                  </div>
                </div>
                <div className="position-absolute w-100 h-100 rounded-circle social-icon-upload-label">
                  <label
                    htmlFor="member-image-input4"
                    className="mb-0 w-100 h-100 rounded-circle cursor-pointer"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Select Member Image"
                  >
                    <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                      <i className="ri-image-fill"></i>
                    </div>
                  </label>
                  <input
                    className="form-control d-none"
                    defaultValue=""
                    id="member-image-input4"
                    name="icon4"
                    type="file"
                    onChange={(e) => handleImageUpload(e, 4)}
                    accept="image/png, image/gif, image/jpeg"
                  />
                </div>
                <div className="avatar-title bg-light rounded-circle">
                  <img
                    src={
                      formData?.icon4
                        ? `${IMG_URL}${formData?.icon4}`
                        : dummyImgIcon
                    }
                    alt={formData?.icon4}
                    id="member-img4"
                    className="avatar-xs object-fit-contain rounded-circle"
                    ref={uploadedImage4}
                  />
                  {/* {errors.icon && (
                    <div className="text-danger">{errors.icon}</div>
                  )} */}
                </div>
              </span>
            </div>
            <Input
              className="form-control"
              placeholder="Enter Link here"
              type="text"
              name="link4"
              onChange={handleChange}
              value={formData?.link4}
            />
          </div>
          {errors.icon && <div className="text-danger">{errors.icon}</div>}
          <div className="text-end mb-5">
            <Button type="submit" className="btn btn-success w-sm">
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default SocialMediaLink;
