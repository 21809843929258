import { Grid, Box, Container, Typography, Button, Stack } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import bannerImg from '../../assets/images/bannerImg.png';
import { useResponsive } from '../../hooks/useResponsive';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { IMG_URL } from '../../utils/constant';

const StylePopular = styled(Box)(({ theme }) => ({
  padding: '50px 0',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    padding: '56px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '40px 0',
  },
}));
const BannerLeftMain = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));
const BannerLeftText = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    '& .MuiStack-root': {
      gap: '10px',
      '& .MuiButtonBase-root': {
        padding: '8px 15px',
        minWidth: 'calc(50% - 10px)',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiStack-root': {
      flexDirection: 'column',
      '& .MuiButtonBase-root': {
        minWidth: '100%',
      },
    },
  },
}));
const BannerLeft = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
  '& .MuiTypography-h2': {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
      marginBottom: '20px',
    },
  },
  '& .MuiTypography-h6': {
    [theme.breakpoints.down('xl')]: {
      fontSize: '1.0625rem',
    },
  },
}));
const HostImages = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'start',
  overflow: 'hidden',
  gap: '25px',
  paddingTop: '64px',
  marginRight: '-50%',
}));
const CarouselDiv = styled(Box)(({ theme }) => ({
  '& .slick-slider': {
    '& .slick-dots': {
      bottom: '0',
      position: 'static',
    },
    '& .slick-list': {
      width: '100%',
    },
  },
}));
const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
};
export const HiringSection = (props) => {
  let { aboutHiringSection } = props;
  const isMobileMode = useResponsive('down', 'md');
  return (
    <>
      <StylePopular>
        <Container
          fixed={true}
          sx={{
            maxWidth: {
              xl: '1326px',
            },
          }}
        >
          <Grid
            container
            spacing={3}
            sx={{ flexDirection: { md: 'row', xs: 'column-reverse' } }}
          >
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: { md: 'end', xs: 'center' },
              }}
            >
              <BannerLeftMain>
                <BannerLeft>
                  <BannerLeftText>
                    {aboutHiringSection && aboutHiringSection?.length > 0 && (
                      <>
                        <Typography variant="h2" sx={{ mb: 4 }}>
                          {aboutHiringSection[0]?.title}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: 'normal', color: 'text.secondary' }}
                        >
                          {aboutHiringSection[0]?.subTitle}
                        </Typography>
                      </>
                    )}
                    <Stack direction="row" marginTop="32px" gap="24px">
                      <Button
                        variant="contained"
                        size="large"
                        sx={{ minWidth: '195px' }}
                        // component={Link}
                        href={`${aboutHiringSection[0]?.description}`}
                        target="_blank"
                      >
                        See all jobs
                      </Button>
                    </Stack>
                  </BannerLeftText>
                </BannerLeft>
              </BannerLeftMain>
            </Grid>
            <Grid item md={6} xs={12}>
              {isMobileMode ? (
                <CarouselDiv>
                  {aboutHiringSection && aboutHiringSection?.length > 0 && (
                    <>
                      <Slider {...settings}>
                        {aboutHiringSection?.map((val, index) => (
                          <Box
                            sx={{
                              borderRadius: '22px',
                              overflow: 'hidden',
                              aspectRatio: '1',
                            }}
                            key={index}
                          >
                            <img
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                display: 'block',
                              }}
                              src={`${IMG_URL}${val.bannerImage}`}
                            />
                          </Box>
                        ))}
                      </Slider>
                    </>
                  )}
                </CarouselDiv>
              ) : (
                <HostImages>
                  {aboutHiringSection && aboutHiringSection?.length > 0 && (
                    <>
                      <Box
                        sx={{
                          borderRadius: '22px',
                          overflow: 'hidden',
                          marginTop: { md: '-64px', xs: '0' },
                          width: '385px',
                          aspectRatio: '1/1.22078',
                        }}
                      >
                        {aboutHiringSection[0]?.bannerImage ? (
                          <img
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              display: 'block',
                            }}
                            src={`${IMG_URL}${aboutHiringSection[0].bannerImage}`}
                          />
                        ) : null}
                      </Box>
                      <Box
                        sx={{
                          borderRadius: '22px',
                          overflow: 'hidden',
                          width: '470px',
                          width: '279px',
                          aspectRatio: '1/1.6846',
                        }}
                      >
                        {aboutHiringSection[0]?.bannerImage2 ? (
                          <img
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              display: 'block',
                            }}
                            src={`${IMG_URL}${aboutHiringSection[0].bannerImage2}`}
                          />
                        ) : null}
                      </Box>
                    </>
                  )}
                </HostImages>
              )}
            </Grid>
          </Grid>
        </Container>
      </StylePopular>
    </>
  );
};
