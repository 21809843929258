import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  Button,
  Backdrop,
  CircularProgress,
  Stack,
  TextField,
  Alert,
  FormHelperText,
} from '@mui/material';
import { RightImg, LeftSection, BrandListBox, BannerLeftMain } from './styled';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BrandList } from '../../../components/brand-list';
import Rectangle5196 from '../../../assets/images/Rectangle5196.png';
import { useFormik, Form, FormikProvider } from 'formik';
import { forgotPasswordSchema } from '../../../utils/validation/Auth';
import { forgotPassword } from '../../../services/apiServices';
import authBack6 from '../../../assets/images/auth_back6.svg';
import authBack7 from '../../../assets/images/auth_back7.svg';

export const ForgotPassword = () => {
  const [state, setState] = useState({
    showPassword: false,
    message: '',
    spinFlag: false,
    showAlert: false,
    showAlertError: false,
  });
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values) => {
      let payload = {
        email: values.email,
      };
      try {
        setState({ ...state, spinFlag: true });
        const { data } = await forgotPassword(payload);
        if (data.statusCode == 200) {
          setState({
            ...state,
            message: data.message,
            spinFlag: false,
            showAlert: true,
            showAlertError: false,
          });
          resetForm();
        } else if (data.statusCode == 400) {
          setState({
            ...state,
            message: data.message,
            spinFlag: false,
            showAlert: false,
            showAlertError: true,
          });
        }
      } catch (err) {
        // navigate(`/error?msg=${err}&&path=${pathname}`);
      }
    },
  });
  const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik;
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: { md: 'end', xs: 'center' },
            background: `url(${authBack6}) no-repeat top right, url(${authBack7}) no-repeat bottom left`,
            order: { md: '1', xs: '2' },
          }}
        >
          <BannerLeftMain>
            <LeftSection>
              <>
                {state.showAlert ? (
                  <Alert sx={{ mb: 2 }} severity="success">
                    {state.message}
                  </Alert>
                ) : (
                  ''
                )}
                {state.showAlertError ? (
                  <Alert sx={{ mb: 2 }} severity="error">
                    {state.message}
                  </Alert>
                ) : (
                  ''
                )}

                <Box sx={{ mb: 6 }}>
                  <Typography
                    component="h6"
                    align="center"
                    sx={{
                      color: '#FEAE01',
                      fontSize: '16px',
                      textTransform: 'uppercase',
                      fontWeight: '600',
                      letterSpacing: { sm: '0.1em' },
                    }}
                  >
                    Forgot Password
                  </Typography>
                </Box>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            type="email"
                            label="Email"
                            {...getFieldProps('email')}
                            // error={Boolean(touched.email && errors.email)}
                            // helperText={touched.email && errors.email}
                          />
                          {touched.email && errors.email && (
                            <FormHelperText error>
                              {errors.email}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="end"
                          >
                            <Link
                              to="/login"
                              style={{
                                color: '#374151',
                                textDecoration: 'none',
                                fontSize: '16px',
                                fontWeight: '500',
                              }}
                            >
                              Back to login
                            </Link>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                          >
                            Send link
                          </Button>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Form>
                </FormikProvider>
              </>
              <Box>
                <Typography
                  component="p"
                  align="center"
                  sx={{
                    mt: 2,
                    fontSize: '16px',
                    color: '#6B7280',
                    fontWeight: '500',
                  }}
                >
                  Don’t have an account,{' '}
                  <Link
                    to="/sign-up"
                    style={{ color: '#FEAE01', textDecoration: 'none' }}
                  >
                    Create an account
                  </Link>
                </Typography>
              </Box>
            </LeftSection>
            {/* <BrandListBox>
              <BrandList />
            </BrandListBox> */}
          </BannerLeftMain>
        </Grid>
        <Grid item md={6} xs={12} sx={{ order: { md: '2', xs: '1' } }}>
          <RightImg>
            <img src={Rectangle5196} alt="Rectangle5196" />
          </RightImg>
        </Grid>
      </Grid>

      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={state.spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
