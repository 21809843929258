import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

let initialState = {
  renderWithdrwalRequestFlag: false,
};
export const userRenderWithdrawlRequest = createSlice({
  name: 'withdrwalRequestRender',
  initialState,
  reducers: {
    updateRenderWithdrwalRequestFlag: (state) => {
      state.renderWithdrwalRequestFlag = !state.renderWithdrwalRequestFlag;
    },
  },
});

export function useWithdrwalRequestStore() {
  const renderWithdrwalRequestFlag = useSelector(
    (state) => state.withdrwalRequestRender.renderWithdrwalRequestFlag
  );
  const dispatch = useDispatch();
  return {
    renderWithdrwalRequestFlag,
    updateRenderWithdrwalRequestFlag: () =>
      dispatch(updateRenderWithdrwalRequestFlag()),
  };
}

export const { updateRenderWithdrwalRequestFlag } =
  userRenderWithdrawlRequest.actions;

export default userRenderWithdrawlRequest.reducer;
