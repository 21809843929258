import { Backdrop, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import React from 'react';
import {
  Alert,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
} from 'reactstrap';
import trash from '../../assets/lordicon-icons/wired-outline-185-trash-bin.gif';
import ResponseModal from './SuccessModal';
import ShowSpinner from './spinner';

const OtpModal = (props) => {
  let {
    show,
    onTransferedClick,
    onCloseClick,
    deleteConfirmationMsg,
    setOtp,
    error,
    setError,
    otp,
  } = props;
  const handleChange = (e) => {
    const value = e.target.value;

    // Allow only numbers
    if (/^\d*$/.test(value)) {
      setOtp(value);

      // Validation: Check if the OTP is 6 digits long
      if (!otp) {
        setError('OTP is required');
      }
      if (value.length !== 6) {
        setError('OTP must be exactly 6 digits');
      } else {
        setError('');
      }
    }
  };
  return (
    <>
      <Modal fade={true} isOpen={show} toggle={onCloseClick} centered={true}>
        <ModalBody className="py-3 px-3">
          <div className="mt-2 text-center">
            {/* <img width={'100px'} src={trash} alt="Delete" /> */}
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                {deleteConfirmationMsg
                  ? deleteConfirmationMsg
                  : 'Transfer requires OTP to continue'}
              </p>
            </div>
          </div>
          <div className="mt-2 text-center">
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <div className="mb-6">
                <Input
                  name="otp"
                  className="form-control"
                  placeholder="Enter otp"
                  type="number"
                  onChange={handleChange}
                  max={6}
                  value={otp}
                  maxLength={6} // Limits input to 6 characters
                  invalid={error ? true : false}
                />
                {error ? (
                  <FormFeedback type="invalid">{error}</FormFeedback>
                ) : null}
              </div>
              {/* </Col> */}
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-"
              data-bs-dismiss="modal"
              onClick={onCloseClick}
            >
              Close
            </button>

            <button
              type="button"
              className="btn w-sm btn-danger "
              id="trnasfered-record"
              onClick={onTransferedClick}
              disabled={!otp || error ? true : false}
            >
              Transfered
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

OtpModal.propTypes = {
  onCloseClick: PropTypes.func,
  onTransferedClick: PropTypes.func,
  show: PropTypes.any,
  deleteState: PropTypes.any,
};

export default OtpModal;
