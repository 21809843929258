import React, { useState, useEffect, useCallback } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalBody,
  Input,
  Nav,
  NavItem,
  NavLink,
  Button,
  ModalHeader,
} from 'reactstrap';
import classnames from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DatatablesCom from '../../Components/Tables/DataTables/DatatablesCom';
import AddAdminUsers from './AddAdminUsers';
import DeleteModal from '../../Components/Common/DeleteModal';
import { IMG_URL } from '../../../utils/constant';
import ActiveInactiveModel from '../../Components/Common/ActiveInactiveModel';
import userNoImage from '../../assets/images/users/user-dummy-img.jpg';
import {
  deleteParticularUser,
  getAllUsers,
  activeInactiveParticularUser,
  fetchParticularUserWithModules,
} from '../../../services/apiServices';
const UsersList = (props) => {
  let { setRender, renderCount, setResponse, setSpinFlag } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState('1');
  const [isEdit, setIsEdit] = useState(false);
  const [customer, setCustomer] = useState([]);
  const [allUserList, setAllUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationTotalRows, setPaginationTotalRows] = useState(1);
  const [userDeletedId, setUserDeletedId] = useState();
  const [activeInactiveUserId, setActiveInactiveUserId] = useState();
  const [messageActiveInactive, setMessageActiveInactive] = useState();
  const [rowPerPage, setRowsPerPage] = useState(10);
  const [roleBased, setRoleBased] = useState('all');
  const [tab, setTab] = useState();
  const [type, setType] = useState();
  const [dynamiClassname1, setDynamiClassname1] = useState(
    classnames({ active: activeTab === '1' }, 'fw-semibold')
  );
  const [dynamiClassname2, setDynamiClassname2] = useState();
  const [dynamiClassname3, setDynamiClassname3] = useState();
  const [dynamiClassname4, setDynamiClassname4] = useState();

  //edit user
  const [modalEdit, setModalEdit] = useState(false);
  const [userData, setUserData] = useState();
  const [allUserPermissionModule, setAllUserPermissionModule] = useState([]);
  // Delete user
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [modal, setModal] = useState(false);
  const [activeInactiveModel, setActiveInactiveModel] = useState(false);
  const [fetchRender, setFetchRender] = useState(true);
  //filter
  const [search, setSearch] = useState();
  const [searchByDate, setSearchByDate] = useState();
  const [userStatus, setUserStatus] = useState();
  const [optionsFlag, setOptionsFlag] = useState(true);
  const [placeholder, setPlaceholder] = useState(
    'Search by firstname, lastname and email'
  );

  const setUserRedirectioRole = async () => {
    const searchParams = new URLSearchParams(location.search);
    let role = '';
    let tab = '';
    role = searchParams.get('type');
    tab = searchParams.get('tab');

    if (role && tab) {
      setType('flag');
      toggleTab(role, tab);
      setActiveTab(tab);
      setDynamiClassname1(
        classnames({ active: tab === '1' }, { 'fw-semibold': null })
      );
      setDynamiClassname2(classnames({ active: tab === '2' }, 'fw-semibold'));
      setDynamiClassname3(classnames({ active: tab === '3' }, 'fw-semibold'));
      setDynamiClassname4(classnames({ active: tab === '4' }, 'fw-semibold'));

      setRoleBased(role);
    } else {
      setType(null);
      setTab(null);
      if (activeTab !== 1) setDynamiClassname1(null);
      if (activeTab !== 2) setDynamiClassname2(null);
      if (activeTab !== 3) setDynamiClassname3(null);
      if (activeTab !== 4) setDynamiClassname4(null);
    }
    searchParams.delete('type');
    searchParams.delete('tab');
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    setUserRedirectioRole();
    fetchUserList();
  }, [fetchRender, currentPage, type, rowPerPage]);

  const fetchUserList = async () => {
    try {
      setSpinFlag(true);
      let payload = { currentPage, roleBased, rowPerPage };
      if (search) payload['search'] = search;
      if (searchByDate) payload['searchByDate'] = searchByDate;
      if ((userStatus == 1 || userStatus == 0) && userStatus !== 'All')
        payload['isActive'] = userStatus;
      const { data } = await getAllUsers(payload);
      if (data.statusCode == 200) {
        setPaginationTotalRows(data.allUserCount);
        setAllUserList(data.allUsers);
        setRender(!renderCount);
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
      setSearchByDate('');
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      if (type !== 'all') {
      }
    }
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCustomer(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  // Delete Data
  const onClickDelete = (id) => {
    setUserDeletedId(id);
    setDeleteModal(true);
  };

  //active in active user
  const onClickActiveInactiveUser = (id, isActive) => {
    setActiveInactiveUserId(id);
    setActiveInactiveModel(true);
    if (isActive) {
      setMessageActiveInactive('inactive this user');
    } else {
      setMessageActiveInactive('active this user');
    }
  };

  // Delete Data
  const handleDeleteCustomer = async () => {
    try {
      setSpinFlag(true);
      let payload = { user_id: userDeletedId };
      const { data } = await deleteParticularUser(payload);
      if (data.statusCode === 200) {
        setDeleteModal(false);
        setFetchRender(!fetchRender);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  //active in active user
  const handleActiveInactiveUser = async () => {
    try {
      setSpinFlag(true);
      let payload = { user_id: activeInactiveUserId };
      const { data } = await activeInactiveParticularUser(payload);
      if (data.statusCode === 200) {
        setActiveInactiveModel(false);
        setFetchRender(!fetchRender);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
        setActiveInactiveModel(false);
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  // Edit Data
  const handleEditClick = async (id) => {
    try {
      setSpinFlag(true);
      let payload = { user_id: id };
      const { data } = await fetchParticularUserWithModules(payload);
      if (data.statusCode === 200) {
        setModal(true);
        setUserData(data.requestData);
        setAllUserPermissionModule(data.allUserPermissionModule);
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          statusCode: data.statusCode,
          open: true,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  // Delete Multiple
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Users</span>,
      // sortable: true,
      width: '350px',
      cell: (row) => {
        return (
          <div className="d-flex align-items-center text-capitalize">
            <div className="flex-shrink-0 me-3">
              <div className="avatar-sm bg-light rounded p-1">
                <Link
                  to={`/admin/user-details?id=${row?._id}`}
                  className="text-dark"
                >
                  {row?.profile ? (
                    <img
                      className="img-fluid d-block"
                      src={`${IMG_URL}${row.profile}`}
                      alt="Header Avatar"
                    />
                  ) : (
                    <img
                      className="img-fluid d-block"
                      src={userNoImage}
                      alt="Header Avatar"
                    />
                  )}
                </Link>
              </div>
            </div>
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <Link
                  to={`/admin/user-details?id=${row?._id}`}
                  className="text-dark"
                >
                  {row.firstname} {row.lastname}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                Role : <span className="fw-medium"> {row.role}</span>
              </p>
            </div>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row.email,
      // sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Phone</span>,
      selector: (row) => row.countryCode + ' ' + row.mobile,
      // sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Created date</span>,
      selector: (row) => {
        const isoDate = new Date(row?.createdAt);
        const addLeadingZero = (number) =>
          number < 10 ? `0${number}` : number;
        const formattedDate = `${isoDate.getFullYear()}-${addLeadingZero(
          isoDate.getMonth() + 1
        )}-${addLeadingZero(isoDate.getDate())}`;
        return formattedDate;
      },
      // sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      // sortable: true,
      selector: (cell) => (
        <div className="form-check form-switch">
          <Input
            className="form-check-input"
            type="checkbox"
            role="switch"
            checked={cell.isActive || false}
            onClick={() => onClickActiveInactiveUser(cell._id, cell.isActive)}
          />
        </div>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      // sortable: true,
      cell: (cellProps) => {
        return (
          <ul className="list-inline hstack gap-2 mb-0">
            {cellProps.role !== 'landlord' && cellProps.role !== 'renter' ? (
              <li className=" edit" title="Edit">
                <button
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                  onClick={() => {
                    setIsEdit(true);
                    handleEditClick(cellProps._id);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </button>
              </li>
            ) : null}
            {cellProps.role === 'renter' || cellProps.role === 'landlord' ? (
              <li className=" edit" title="View">
                <Link
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                  to={`/admin/user-details?id=${cellProps?._id}`}
                >
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li>
            ) : null}
            {cellProps.role === 'landlord' ||
            cellProps.role === 'renter' ? null : (
              <li className="" title="Remove">
                <Link
                  className="btn btn-icon btn-topbar btn-ghost-danger rounded-circle"
                  onClick={() => {
                    onClickDelete(cellProps._id);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            )}
          </ul>
        );
      },
    },
  ];

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ActiveInactiveModel
        show={activeInactiveModel}
        onActiveInactiveClick={handleActiveInactiveUser}
        onCloseClick={() => setActiveInactiveModel(false)}
        messageActiveInactive={messageActiveInactive}
      />

      <Col lg={12}>
        <Card id="usersList">
          <CardHeader className="border-0">
            <Row>
              <Col>
                <div>
                  <h5 className="card-title mb-0">Users List</h5>
                </div>
              </Col>
              <Col>
                <div className="text-end">
                  {isMultiDeleteButton && (
                    <Button
                      className="btn-soft-danger me-1"
                      onClick={() => setDeleteModalMulti(true)}
                    >
                      <i className="ri-delete-bin-2-line"></i>
                    </Button>
                  )}
                  <Button
                    type="button"
                    className="btn-success add-btn"
                    id="create-btn"
                    onClick={() => {
                      setUserData([]);
                      setAllUserPermissionModule([]);
                      setIsEdit(false);
                      toggle();
                    }}
                  >
                    <i className="ri-add-line align-bottom me-1"></i> Add User
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardHeader className="border-0 pt-0 overflow-auto">
            <Nav
              className="nav-tabs-custom card-header-tabs border-bottom-0 flex-nowrap"
              role="tablist"
            >
              <NavItem>
                <NavLink
                  className={
                    dynamiClassname1
                      ? dynamiClassname1
                      : classnames({ active: activeTab === '1' }, 'fw-semibold')
                  }
                  onClick={() => {
                    toggleTab('1', 'all');
                    setRoleBased('all');
                    setFetchRender(!fetchRender);
                  }}
                  href="#"
                >
                  All{' '}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    dynamiClassname2
                      ? dynamiClassname2
                      : classnames({ active: activeTab === '2' }, 'fw-semibold')
                  }
                  onClick={() => {
                    toggleTab('2', 'admin');
                    setRoleBased('admin');
                    setFetchRender(!fetchRender);
                  }}
                  href="#"
                >
                  Admin user
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    dynamiClassname3
                      ? dynamiClassname3
                      : classnames({ active: activeTab === '3' }, 'fw-semibold')
                  }
                  onClick={() => {
                    toggleTab('3', 'landlord');
                    setRoleBased('landlord');
                    setFetchRender(!fetchRender);
                  }}
                  href="#"
                >
                  Landlord{' '}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={
                    dynamiClassname4
                      ? dynamiClassname4
                      : classnames({ active: activeTab === '4' }, 'fw-semibold')
                  }
                  onClick={() => {
                    toggleTab('4', 'renter');
                    setRoleBased('renter');
                    setFetchRender(!fetchRender);
                  }}
                  href="#"
                >
                  Renter
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody className="p-0">
            <div>
              {allUserList && (
                <DatatablesCom
                  columns={columns}
                  data={allUserList}
                  setCurrentPage={setCurrentPage}
                  paginationTotalRows={paginationTotalRows}
                  setUserStatus={setUserStatus}
                  setSearch={setSearch}
                  search={search}
                  userStatus={userStatus}
                  fetchRender={fetchRender}
                  setFetchRender={setFetchRender}
                  optionsFlag={optionsFlag}
                  searchByDate={searchByDate}
                  setSearchByDate={setSearchByDate}
                  placeholder={placeholder}
                  setRowsPerPage={setRowsPerPage}
                  rowPerPage={rowPerPage}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
      <Modal id="showModal" isOpen={modal} toggle={toggle} centered size="lg">
        <ModalHeader toggle={toggle}>Add User</ModalHeader>
        <ModalBody>
          <AddAdminUsers
            userData={userData}
            allUserPermissionModule={allUserPermissionModule}
            setModal={setModal}
            setFetchRender={setFetchRender}
            fetchRender={fetchRender}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setResponse={setResponse}
            setSpinFlag={setSpinFlag}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default UsersList;
