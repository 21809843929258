import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import DatatablesCom from '../../Components/Tables/DataTables/DatatablesCom';

import OtpModal from '../../Components/Common/OtpModal';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Components/Common/Loader';
import {
  createTransferedAPI,
  finalizeTransferedAPI,
  getAllWithdrawRequest,
} from '../../../services/apiServices';
import { useAuthStore } from '../../../redux/features/authSlice';
import { setToken } from '../../../utils/axiosClient';
import { IMG_URL, nairaSymbol } from '../../../utils/constant';
import userNoImage from '../../assets/images/users/user-dummy-img.jpg';
import ShowSpinner from '../../Components/Common/spinner';

const customStyles = {
  rows: {
    style: {
      // minHeight: '72px',
    },
  },
  headCells: {
    style: {
      padding: '8px',
      background: '#f3f6f9',
      fontWeight: 'bold',
      color: '#878a99',
    },
  },
  cells: {
    style: {
      padding: '8px',
    },
  },
};

const PayRequest = ({
  setSpinFlag,
  setResponse,
  fetchRender,
  setFetchRender,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [WithdrawRequest, setWithdrawRequest] = useState([]);
  const [otpModal, setOtpModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationTotalRows, setPaginationTotalRows] = useState(1);
  const [rowPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState();
  const [searchByDate, setSearchByDate] = useState();
  const [otp, setOtp] = useState();
  const [error, setError] = useState();
  const [otpMsg, setOtpMsg] = useState('');
  const [transferedCode, setTransferedCode] = useState('');
  const [withdrwalId, setWithdrwalId] = useState('');
  const [userId, setUserId] = useState('');
  const [placeholder, setPlaceholder] = useState('Search by contact and email');
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Customer</span>,
      cell: (row) => {
        return (
          <div className="d-flex align-items-center">
            {row?.userData[0].profile ? (
              <img
                src={`${IMG_URL}${row.userData[0].profile}`}
                alt=""
                className="avatar-xs rounded-circle me-2"
              />
            ) : (
              <img
                className="avatar-xs rounded-circle me-2"
                src={userNoImage}
                alt="Header Avatar"
              />
            )}
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row.userData[0].email,
    },
    {
      name: <span className="font-weight-bold fs-13">Contact</span>,
      selector: (row) => row.userData[0].mobile,
    },
    {
      name: <span className="font-weight-bold fs-13">Date</span>,
      selector: (row) => row.createdAt.slice(0, 10),
    },
    {
      name: <span className="font-weight-bold fs-13">Toal amount</span>,
      selector: (row) => {
        return (
          <>
            {nairaSymbol}
            {row?.amount}
          </>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Sub Total</span>,
      selector: (row) => {
        return (
          <>
            {nairaSymbol}
            {row?.subTotalAmount}
          </>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Transfer fee</span>,
      selector: (row) => {
        return (
          <>
            {nairaSymbol}
            {row?.transferedFee}
          </>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Payment status</span>,
      cell: (row) => {
        return (
          <button
            type="button"
            className="btn btn-sm btn-soft-secondary"
            onClick={() => handleTransfered(row)}
          >
            Pay Now
          </button>
        );
      },
    },
  ];

  const fetchWithDrawRequestList = async () => {
    try {
      setSpinFlag(true);
      let payload = { currentPage, rowPerPage };
      if (search) payload['search'] = search;
      if (searchByDate) payload['searchByDate'] = searchByDate;
      const { data } = await getAllWithdrawRequest(payload);
      if (data?.statusCode == 200) {
        setWithdrawRequest(data?.withdrawRequestList);
        setSpinFlag(false);
        setPaginationTotalRows(data?.allUserCount);
      } else {
        setSpinFlag(false);
      }
    } catch (error) {
      setSpinFlag(false);
      navigate(`/admin/error?msg=${error}&&path=${pathname}`);
    }
  };

  //handle transfered
  const handleTransfered = async (row) => {
    try {
      setSpinFlag(true);
      const payload = { payoutDetails: row };
      const { data } = await createTransferedAPI(payload);
      if (data?.statusCode == 200) {
        setOtpMsg(data?.message);
        setTransferedCode(data?.transferedDeatils?.transfer_code);
        setWithdrwalId(data?.transferedDeatils?._id);
        setUserId(data?.transferedDeatils?.userId);
        setOtpModal(true);
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setOtpModal(false);
        setResponse({
          message: data?.message,
          statusCode: data?.statusCode,
          open: true,
        });
      }
    } catch (error) {
      setSpinFlag(false);
      setOtpModal(false);
      navigate(`/admin/error?msg=${error}&&path=${pathname}`);
    }
  };
  //update otp Data
  const handleTransferedClick = async () => {
    if (!error) {
      try {
        setSpinFlag(true);
        const payload = { otp, transferedCode, withdrwalId, userId };
        const { data } = await finalizeTransferedAPI(payload);
        if (data?.statusCode == 200) {
          setOtpMsg(data?.message);
          setResponse({
            message: data?.message,
            statusCode: data?.statusCode,
            open: true,
          });
        } else {
          setResponse({
            message: data?.message,
            statusCode: data?.statusCode,
            open: true,
          });
        }
        setFetchRender(!fetchRender);
        setSpinFlag(false);
        setOtp('');
        setTransferedCode('');
        setOtpModal(false);
      } catch (error) {
        setSpinFlag(false);
        setOtpModal(true);
        navigate(`/admin/error?msg=${error}&&path=${pathname}`);
      }
    }
  };
  useEffect(() => {
    fetchWithDrawRequestList();
  }, [currentPage, rowPerPage, fetchRender]);
  const onCloseModel = () => {
    setOtpModal(false);
    setOtp('');
    setError('');
  };
  return (
    <>
      <OtpModal
        setError={setError}
        otpMsg={otpMsg}
        error={error}
        otp={otp}
        setOtp={setOtp}
        show={otpModal}
        onTransferedClick={handleTransferedClick}
        onCloseClick={() => onCloseModel()}
      />
      <Col lg={12}>
        <Card id="transactionList">
          <CardHeader className="border-0">
            <Row>
              <div className="col-sm">
                <div>
                  <h5 className="card-title mb-0">Withdraw request</h5>
                </div>
              </div>
              <div className="col-sm-auto"></div>
            </Row>
          </CardHeader>
          <CardBody className="p-0">
            <div>
              {/* {WithdrawRequest && WithdrawRequest?.length ? ( */}
              <DatatablesCom
                flag={1}
                columns={columns}
                data={WithdrawRequest}
                customStyles={customStyles}
                setCurrentPage={setCurrentPage}
                paginationTotalRows={paginationTotalRows}
                setSearch={setSearch}
                search={search}
                fetchRender={fetchRender}
                setFetchRender={setFetchRender}
                searchByDate={searchByDate}
                setSearchByDate={setSearchByDate}
                placeholder={placeholder}
                setRowsPerPage={setRowsPerPage}
                rowPerPage={rowPerPage}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default PayRequest;
