import React, { useEffect, useState, useRef } from 'react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  CardHeader,
  Box,
  Pagination,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useConfirm } from 'material-ui-confirm';
import { PropertiesListSearch } from './PropertiesListSearch';
import { PropertiesListSort } from './PropertiesListSort';
import { TableHeader } from './TableHeader';
import { myProperties, deleteProperty } from '../../../services/apiServices';
import { IMG_URL, nairaSymbol } from '../../../utils/constant';
import { useNavigate, useLocation } from 'react-router-dom';
import Scrollbar from '../../../components/scrollbar';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';
import { DataNotAvailable } from '../../../components/data-not-found';
import { currencySeprator } from '../../../utils/common-function';
import { useAuthStore } from '../../../redux/features/authSlice';

const TABLE_HEAD = [
  { id: 'title', label: 'Listing Title', alignRight: false },
  // { id: 'renterName', label: 'Renter Name', alignRight: false },
  { id: 'vacancyStatus', label: 'Vacancy Status', alignRight: false },
  // { id: 'maintenanceRequest', label: 'Maintenance request', alignRight: false },
  { id: 'action', label: ' ', alignRight: true },
];

const ActionField = (props) => {
  let { data, toDeleteProperty } = props;
  const navigate = useNavigate();
  const anchorRef1 = useRef(null);
  const [openIn, setOpenIn] = useState(false);

  const handleEditPage = (id) => {
    navigate(`/dashboard/edit-listing`, {
      state: {
        id,
      },
    });
  };
  return (
    <>
      <IconButton
        onClick={() => {
          setOpenIn(true);
        }}
        ref={anchorRef1}
      >
        <Icon icon="carbon:overflow-menu-vertical" />
      </IconButton>
      <Popover
        open={openIn}
        onClose={() => {
          setOpenIn(false);
        }}
        anchorEl={anchorRef1.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
        PaperProps={{
          sx: {
            width: '140px',
          },
        }}
      >
        <List>
          <ListItemButton
            onClick={() => {
              handleEditPage(data._id);
            }}
            sx={{
              borderRadius: 1.5,
            }}
          >
            <ListItemIcon>
              <Icon
                style={{ height: 20, width: 20 }}
                icon="fluent:edit-20-filled"
              />
            </ListItemIcon>
            Edit
          </ListItemButton>

          <ListItemButton
            onClick={() => {
              toDeleteProperty(data._id);
              setOpenIn(false);
            }}
            sx={{ borderRadius: 1.5, color: 'error.main' }}
          >
            <ListItemIcon>
              <Icon
                style={{ height: 20, width: 20 }}
                icon="solar:trash-bin-minimalistic-bold"
              />
            </ListItemIcon>
            Delete
          </ListItemButton>
        </List>
      </Popover>
    </>
  );
};

export const MyPropertiesList = ({
  title,
  subheader,
  setTotalProperty,
  setTotalCity,
  setTotalCustomer,
  setSpinFlag,
}) => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [open, setOpen] = useState(false);
  let { loginInfo } = useAuthStore();
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState();
  const [search, setSearch] = useState(null);
  const [sort, setSort] = useState('');
  const [properties, setProperties] = useState([]);
  const [renderFlag, setRenderFlag] = useState(false);
  const [message, setMessage] = useState(false);
  const [statusCode, setStatusCode] = useState();
  const toDeleteProperty = async (id) => {
    try {
      confirm({
        title: `Delete`,
        description: `Are you sure want to delete this property?`,
        dialogProps: {
          maxWidth: 'xs',
        },
        confirmationText: `Delete`,
        confirmationButtonProps: { color: 'error', variant: 'contained' },
        cancellationButtonProps: { variant: 'outlined' },
        buttonOrder: ['confirm', 'cancel'],
        dialogActionsProps: { style: { padding: '0 24px 16px' } },
      })
        .then(async () => {
          let payload = { propertyId: id };
          const { data } = await deleteProperty(payload);
          if (data.statusCode === 200) {
            setRenderFlag(!renderFlag);
            setSpinFlag(false);
            setOpen(true);
            setMessage(data.message);
            setStatusCode(data.statusCode);
          } else {
            setSpinFlag(false);
            setOpen(true);
            setMessage(data.message);
            setStatusCode(data.statusCode);
          }
        })
        .catch(() => console.log(''));
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const getProperties = async () => {
    try {
      setSpinFlag(true);
      let payload = {
        currentPage: currentPage,
        search: search,
        sortByAlphabet: sort,
        role: loginInfo ? loginInfo.role : null,
      };
      const { data } = await myProperties(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setTotalProperty(data.propertyCount);
        setTotalCity(data.cityCount);
        setTotalCustomer(data.customerCount);
        setProperties(data.myProperties);
        setNumberOfPages(data.noOfPages);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handleFilterByName = (event) => {
    setSearch(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSort = (sort) => {
    setSort(sort);
  };

  useEffect(() => {
    if (search !== null) {
      const getData = setTimeout(() => {
        getProperties();
      }, 1000);
      return () => clearTimeout(getData);
    } else {
      getProperties();
    }
  }, [currentPage, search, renderFlag, sort]);
  return (
    <>
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <Card>
        <CardHeader
          title={title}
          subheader={subheader}
          action={
            <>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  justifyContent: { md: 'end' },
                  flexWrap: 'wrap',
                }}
              >
                <PropertiesListSearch
                  filterValues={search}
                  onFilterValues={handleFilterByName}
                />
                <PropertiesListSort handleSort={handleSort} />
              </Box>
            </>
          }
          sx={{
            pb: 2,
            flexWrap: 'wrap',
            borderBottom: {
              md: 'none',
              xs: '1px solid rgba(241, 243, 244, 1)',
            },
            '& .MuiCardHeader-content': {
              width: { md: '35%', xs: '100%' },
              pb: { md: 0, xs: 2 },
              borderBottom: {
                md: 'none',
                xs: '1px solid rgba(241, 243, 244, 1)',
              },
            },
            '& .MuiCardHeader-action': {
              width: { md: '65%', xs: '100%' },
              pt: { md: 0, xs: 2 },
              m: 0,
            },
          }}
        />

        <Box dir="ltr">
          <Scrollbar>
            <TableContainer sx={{ minWidth: 1100 }}>
              <Table>
                <TableHeader
                  // order={order}
                  // orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={properties.length}
                  // onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {properties.map((row, index) => {
                    const {
                      _id,
                      title,
                      address,
                      propertyImages,
                      propertyUnits,
                      isDeleted,
                      // propertyPrice,
                    } = row;
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: '50%' }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Link to={'/property-details/' + _id}>
                              <Avatar
                                sx={{
                                  // mr: 2,
                                  width: '100px',
                                  height: '100px',
                                  borderRadius: '14px',
                                }}
                                src={`${IMG_URL}${propertyImages[0]?.propertyImage}`}
                                alt="photoURL"
                              />
                            </Link>
                            <Box
                              sx={{
                                whiteSpace: 'normal',
                                textTransform: 'capitalize',
                              }}
                            >
                              <Link
                                to={'/property-details/' + _id}
                                style={{
                                  color: '#323232',
                                  textDecoration: 'none',
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  noWrap
                                  sx={{ maxWidth: 370 }}
                                >
                                  {title}
                                </Typography>
                                <Typography variant="body2">
                                  {address}
                                </Typography>
                              </Link>
                              <Box>
                                {nairaSymbol}
                                {currencySeprator(
                                  Math.min(
                                    ...propertyUnits.map((item) => item.price)
                                  )
                                )}{' '}
                                - {nairaSymbol}
                                {currencySeprator(
                                  Math.max(
                                    ...propertyUnits.map((item) => item.price)
                                  )
                                )}
                                {/* {propertyPrice && (
                                    <Typography
                                      key={index}
                                      variant="caption"
                                      component="span"
                                    >
                                      <b>
                                        ${currencySeprator(propertyPrice.price)}
                                      </b>
                                      /{propertyPrice.durationName}
                                    </Typography>
                                  )} */}
                              </Box>
                            </Box>
                          </Stack>
                        </TableCell>
                        {/* <TableCell align="left">Renter Name</TableCell> */}
                        <TableCell align="left">
                          <Box sx={{ whiteSpace: 'normal' }}>
                            <Paper
                              sx={{
                                borderRadius: '8px',
                                width: '98px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: isDeleted ? '#EA001B' : '#212B36',
                              }}
                            >
                              {isDeleted ? 'Closed' : 'Open'}
                            </Paper>
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          <ActionField
                            toDeleteProperty={toDeleteProperty}
                            data={row}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {properties.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={TABLE_HEAD.length}
                        sx={{ border: 'none' }}
                      >
                        <DataNotAvailable title="You don't have any properties" />
                      </TableCell>
                    </TableRow>
                  ) : (
                    properties.length === 0 && (
                      <TableRow>
                        <TableCell
                          colSpan={TABLE_HEAD.length}
                          sx={{ border: 'none' }}
                        >
                          <DataNotAvailable title="No results found" />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          {properties.length !== 0 && (
            <Pagination
              shape="rounded"
              count={numberOfPages}
              siblingCount={0}
              boundaryCount={2}
              onChange={handlePageChange}
              sx={{
                py: 2,
                '& .MuiPagination-ul': { justifyContent: 'center' },
              }}
            />
          )}
        </Box>
      </Card>
    </>
  );
};
