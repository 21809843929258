import React, { useEffect, useState, useRef } from 'react';
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  Pagination,
  CardHeader,
  Box,
  Paper,
  Popover,
  List,
  ListItemButton,
  ListItemIcon,
  ListItem,
  ListItemText,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import SvgColor from '../../../components/svg-color';
import { TableHeader } from '../dashboard/TableHeader';
import Scrollbar from '../../../components/scrollbar';
import download from '../../../assets/icons/download.svg';
import cardType from '../../../assets/icons/cardType.svg';
import { DataNotPayment } from '../../../components/data-not-found';
import { PropertiesListSort } from '../dashboard/PropertiesListSort';
import { PropertiesListSearch } from '../dashboard/PropertiesListSearch';
import { getTransactionHistory } from '../../../services/apiServices';
import { useAuthStore } from '../../../redux/features/authSlice';
import { currencySeprator } from '../../../utils/common-function';
import { nairaSymbol } from '../../../utils/constant';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';
import { useWithdrwalRequestStore } from '../../../redux/features/renderStateWithdrawalSlice';

const TABLE_HEAD = [
  { id: 'title', label: 'ID Invoice', alignRight: false },
  { id: 'customers', label: 'Date', alignRight: false },
  { id: 'requestDate', label: 'Recipient', alignRight: false },
  { id: 'checkIn', label: 'Amount', alignRight: false },
  { id: 'checkOut', label: 'Type', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  // { id: 'action', label: ' ', alignRight: false },
];

const options = ['Sort by: Alphabet', 'Sort by: A-Z', 'Sort by: Z-A'];
const ActionField = () => {
  const anchorRef1 = useRef(null);
  const [openIn, setOpenIn] = useState(false);
  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        <IconButton
          onClick={() => {
            setOpenIn(true);
          }}
          ref={anchorRef1}
        >
          <Icon icon="carbon:overflow-menu-vertical" />
        </IconButton>
        <Popover
          open={openIn}
          onClose={() => {
            setOpenIn(false);
          }}
          anchorEl={anchorRef1.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'center', horizontal: 'right' }}
          PaperProps={{
            sx: {
              width: '140px',
            },
          }}
        >
          <List>
            <ListItemButton
              onClick={() => {
                setOpenIn(false);
              }}
              sx={{
                borderRadius: 1.5,
              }}
            >
              <ListItemIcon>
                <Icon
                  style={{ height: 20, width: 20 }}
                  icon="fluent:edit-20-filled"
                />
              </ListItemIcon>
              Edit
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                setOpenIn(false);
              }}
              sx={{ borderRadius: 1.5, color: 'error.main' }}
            >
              <ListItemIcon>
                <Icon
                  style={{ height: 20, width: 20 }}
                  icon="solar:trash-bin-minimalistic-bold"
                />
              </ListItemIcon>
              Delete
            </ListItemButton>
          </List>
        </Popover>
      </Box>
    </>
  );
};
export const TransactionHistory = (props) => {
  let { title } = props;
  let { loginInfo, updateLoginInfo } = useAuthStore();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [spinFlag, setSpinFlag] = useState(false);
  const [message, setMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState();
  const [transactionList, setTransactionList] = useState([]);
  const [sort, setSort] = useState('');
  const [statusCode, setStatusCode] = useState();
  const [search, setSearch] = useState(null);
  const { renderWithdrwalRequestFlag, updateRenderWithdrwalRequestFlag } =
    useWithdrwalRequestStore();
  const handleFilterByName = (event) => {
    setSearch(event.target.value);
  };

  const fetchTransactionHistory = async () => {
    try {
      setSpinFlag(true);
      let payload = {
        currentPage: currentPage,
        sortByAlphabet: sort,
        search: search,
      };

      let { data } = await getTransactionHistory(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setTransactionList(data.transactionList);
        let result = data.updatedUserData;
        result.token = data.token;
        updateLoginInfo(result);
        setNumberOfPages(data.noOfPages);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    if (search !== null) {
      const getData = setTimeout(() => {
        fetchTransactionHistory();
      }, 1000);
      return () => clearTimeout(getData);
    } else {
      fetchTransactionHistory();
    }
  }, [currentPage, search, renderWithdrwalRequestFlag]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSort = (sort) => {
    setSort(sort);
  };

  const downloadReport = () => {
    // Create a CSV content as a string
    let csvContent = 'Date,payment Method,Amount,Reference id\n'; // CSV header
    transactionList.forEach((transaction) => {
      let amount = 0;
      loginInfo.role === 'landlord'
        ? (amount = transaction.securityDeposit + transaction.propertyRent)
        : loginInfo.role === 'renter'
        ? (amount = transaction.totalAmount)
        : null;
      csvContent += `${transaction.createdAt},${transaction.paymentType},${amount},${transaction.referenceId}\n`;
    });

    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a download link and trigger a click event
    const a = document.createElement('a');
    a.href = url;
    a.download = 'transaction_report.csv';
    a.click();

    // Revoke the URL to free up resources
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <Card>
        <CardHeader
          title={title}
          action={
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                justifyContent: { md: 'end' },
                flexWrap: 'wrap',
              }}
            >
              {/* {transactionList.length > 0 && ( */}
              <>
                <PropertiesListSearch
                  filterValues={search}
                  onFilterValues={handleFilterByName}
                />
                <Button
                  variant="outlined"
                  size="large"
                  // component={Link}
                  // to="/payment"
                  onClick={downloadReport}
                  sx={{ py: 1, px: 3, height: '48px' }}
                >
                  <SvgColor
                    sx={{ width: '18px', height: '18px', mr: 1.75 }}
                    src={download}
                    alt={download}
                  />{' '}
                  Download report
                </Button>

                {/* <PropertiesListSort handleSort={handleSort} /> */}
              </>
              {/* )} */}
            </Box>
          }
          sx={{
            pb: 2,
            flexWrap: 'wrap',
            borderBottom: {
              md: 'none',
              xs: '1px solid rgba(241, 243, 244, 1)',
            },
            '& .MuiCardHeader-content': {
              width: { md: '35%', xs: '100%' },
              pb: { md: 0, xs: 2 },
              borderBottom: {
                md: 'none',
                xs: '1px solid rgba(241, 243, 244, 1)',
              },
            },
            '& .MuiCardHeader-action': {
              width: { md: '65%', xs: '100%' },
              pt: { md: 0, xs: 2 },
              m: 0,
            },
          }}
        />

        <Box dir="ltr">
          <Scrollbar>
            <TableContainer
              sx={{
                minWidth: 1100,
                minHeight: 200,
              }}
            >
              <Table>
                <TableHeader
                  // order={order}
                  // orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  //rowCount={myApplyList.length}
                  // onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {transactionList.map((val, index) => (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell align="left" sx={{ minWidth: 180 }}>
                        {val.referenceId}
                      </TableCell>
                      <TableCell align="left">
                        {val.createdAt.slice(0, 10)} <br />
                        {/* time  */}
                      </TableCell>
                      <TableCell align="left">
                        {val.merchantInformation.firstname}{' '}
                        {val.merchantInformation.lastname}
                      </TableCell>
                      <TableCell align="left">
                        {nairaSymbol}
                        {loginInfo.role === 'landlord'
                          ? currencySeprator(
                              // val.securityDeposit + val.propertyRent
                              val.propertyRent
                            )
                          : loginInfo.role === 'renter'
                          ? currencySeprator(val.totalAmount)
                          : null}
                      </TableCell>
                      <TableCell align="left">
                        <Box>
                          <ListItem
                            sx={{
                              color: `${
                                (loginInfo.role === 'landlord' && '#FEAE01') ||
                                '#EA001B'
                              }`,
                            }}
                          >
                            <ListItemIcon>
                              <SvgColor src={cardType} alt={cardType} />
                            </ListItemIcon>
                            <ListItemText>
                              {loginInfo.role === 'landlord'
                                ? 'Income'
                                : loginInfo.role === 'renter'
                                ? 'Outcome'
                                : null}
                            </ListItemText>
                          </ListItem>
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Paper
                          sx={{
                            border: `1px solid ${
                              (val.paymentStatus === 'Completed' &&
                                '#12B76A') ||
                              (val.paymentStatus === 'Pending' && '#FF6645') ||
                              ''
                            }`,
                            bgcolor: `${
                              (val.paymentStatus === 'Completed' &&
                                '#ECFDF3') ||
                              (val.paymentStatus === 'Pending' && '#FFEFED') ||
                              ''
                            }`,
                            borderRadius: '8px',
                            width: '98px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: `${
                              (val.paymentStatus === 'Completed' &&
                                '#12B76A') ||
                              (val.paymentStatus === 'Pending' && '#FF6645') ||
                              ''
                            }`,
                          }}
                        >
                          {val.paymentStatus}
                        </Paper>
                      </TableCell>
                      {/* <TableCell align="right">
                        <ActionField />
                      </TableCell> */}
                    </TableRow>
                  ))}
                  {transactionList.length === 0 && (
                    <TableRow>
                      <TableCell
                        colSpan={TABLE_HEAD.length}
                        sx={{ border: 'none' }}
                      >
                        <DataNotPayment title="You don't have transaction list" />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {transactionList.length !== 0 && (
            <Pagination
              shape="rounded"
              count={numberOfPages}
              siblingCount={0}
              boundaryCount={2}
              onChange={handlePageChange}
              sx={{
                py: 2,
                '& .MuiPagination-ul': { justifyContent: 'center' },
              }}
            />
          )}
        </Box>
      </Card>
    </>
  );
};
