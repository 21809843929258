import { Icon } from '@iconify/react';
import SvgColor from '../../../components/svg-color';
import Dashboard from '../../../assets/icons/dashboard.svg';
import Message from '../../../assets/icons/message.svg';
import AccountDetails from '../../../assets/icons/account-details.svg';
import Settings from '../../../assets/icons/settings.svg';
import Payment from '../../../assets/icons/payment.svg';
import Notification from '../../../assets/icons/Notification.svg';

const icon = (name) => (
  <SvgColor src={`${name}`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: icon(Dashboard),
  },
  {
    title: 'Messages',
    path: '/message',
    icon: icon(Message),
  },
  {
    title: 'Account Details',
    path: '/account-details',
    icon: icon(AccountDetails),
  },
  {
    title: 'Payments',
    path: '/payment',
    icon: icon(Payment),
  },
  {
    title: 'Notifications',
    path: '/notification',
    icon: icon(Notification),
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: icon(Settings),
  },
];

export default navConfig;
