import React, { useEffect, useState, useRef } from 'react';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  Pagination,
  CardHeader,
  Box,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { filter } from 'lodash';
import { Link } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { PropertiesListSearch } from './PropertiesListSearch';
import { PropertiesListSort } from './PropertiesListSort';
import { TableHeader } from './TableHeader';
import { bookingRequest, manageRequest } from '../../../services/apiServices';
import { BASEURL, IMG_URL } from '../../../utils/constant';
import { useNavigate, useLocation } from 'react-router-dom';
import Scrollbar from '../../../components/scrollbar';
// import { Error } from '../../error-page';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';
import { DataNotAvailable } from '../../../components/data-not-found';
import { BookingRequestPopup } from './BookingRequestPopup';
import { io } from 'socket.io-client';
import { useAuthStore } from '../../../redux/features/authSlice';

const TABLE_HEAD = [
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'customers', label: 'Customers', alignRight: false },
  // { id: 'companiesName', label: 'Companies Name', alignRight: false },
  { id: 'requestDate', label: 'Request Date', alignRight: false },
  { id: 'checkIn', label: 'Move-In Date', alignRight: false },
  { id: 'checkOut', label: 'Move-Out Date', alignRight: false },
  { id: 'action', label: ' ', alignRight: false },
];

export const BookingRequest = (props) => {
  let { title, renderFlag, setRenderFlag, setSpinFlag, loginInfo } = props;
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState();
  const [myApplyList, setMyApplyList] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [viewDetails, setViewDetails] = useState();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const pathname = useLocation().pathname;
  const [message, setMessage] = useState(false);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [statusCode, setStatusCode] = useState();

  const notificationSocket = io(`${BASEURL}`);
  const handlePopupOpen = (row) => {
    setPopupOpen(true);
    setViewDetails(row);
    // Socket.io event: connect
    notificationSocket.on('connect', () => {
      // Join the landloard's room based on their unique landloard ID
      notificationSocket.emit('join', row.renterId);
    });
  };

  const getBookingRequest = async () => {
    try {
      setSpinFlag(true);
      let payload = {
        currentPage: currentPage,
        search: search,
        sortByAlphabet: sort,
      };
      const { data } = await bookingRequest(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setMyApplyList(data.requestList);
        setNumberOfPages(data.noOfPages);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const handleFilterByName = (event) => {
    // setFilterValues(event.target.value);
    setSearch(event.target.value);
  };

  const handleSort = (sort) => {
    setSort(sort);
  };

  // const filteredUsers = applySortFilter(
  //   myApplyList,
  //   getComparator(order, orderBy),
  //   filterValues
  // );
  // const isNotFound = !filteredUsers.length && !!filterValues;

  useEffect(() => {
    if (search !== '') {
      const getData = setTimeout(() => {
        getBookingRequest();
      }, 1000);
      return () => clearTimeout(getData);
    } else {
      getBookingRequest();
    }
  }, [renderFlag, currentPage, search, sort]);

  const handleRequest = async (id, status) => {
    try {
      setSpinFlag(true);
      let payload = { id: id, status: status };
      const { data } = await manageRequest(payload);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setRenderFlag(!renderFlag);
        setPopupOpen(false);
        setStatusCode(data.statusCode);
      } else {
        setSpinFlag(false);
        setOpen(true);
        setMessage(data.message);
        setPopupOpen(false);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
    // Socket.io event: connect
    notificationSocket.on('connect', () => {
      // Join the landloard's room based on their unique landloard ID
      notificationSocket.emit('join', loginInfo._id);
    });
  };
  return (
    <>
      <DialogPopup
        open={open}
        setOpen={setOpen}
        message={message}
        size="xs"
        statusCode={statusCode}
      />
      <Card>
        <CardHeader
          title={title}
          action={
            <>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  justifyContent: { md: 'end' },
                  flexWrap: 'wrap',
                }}
              >
                <PropertiesListSearch
                  filterValues={search}
                  onFilterValues={handleFilterByName}
                />
                <PropertiesListSort handleSort={handleSort} />
              </Box>
            </>
          }
          sx={{
            pb: 2,
            flexWrap: 'wrap',
            borderBottom: {
              md: 'none',
              xs: '1px solid rgba(241, 243, 244, 1)',
            },
            '& .MuiCardHeader-content': {
              width: { md: '35%', xs: '100%' },
              pb: { md: 0, xs: 2 },
              borderBottom: {
                md: 'none',
                xs: '1px solid rgba(241, 243, 244, 1)',
              },
            },
            '& .MuiCardHeader-action': {
              width: { md: '65%', xs: '100%' },
              pt: { md: 0, xs: 2 },
              m: 0,
            },
          }}
        />

        <Box dir="ltr">
          <Scrollbar>
            <TableContainer
              sx={{
                minWidth: 1100,
                minHeight: 200,
              }}
            >
              <Table>
                <TableHeader
                  // order={order}
                  // orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={myApplyList.length}
                  // onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {myApplyList.map((row, index) => {
                    const {
                      companyName,
                      status,
                      name,
                      createdAt,
                      fromDate,
                      toDate,
                    } = row;
                    const { _id, title, address, price } = row.propertyData[0];
                    const { propertyImage } = row.propertyImages[0];
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: '35%' }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Link to={'/property-details/' + _id}>
                              <Avatar
                                sx={{
                                  // mr: 1,
                                  width: '60px',
                                  height: '60px',
                                  borderRadius: '14px',
                                }}
                                src={`${IMG_URL}${propertyImage}`}
                                alt="photoURL"
                              />
                            </Link>
                            <Box
                              sx={{
                                whiteSpace: 'normal',
                              }}
                            >
                              <Link
                                to={'/property-details/' + _id}
                                style={{
                                  color: '#323232',
                                  textDecoration: 'none',
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  noWrap
                                  sx={{ maxWidth: 270 }}
                                >
                                  {title}
                                </Typography>
                                <Typography variant="body2">
                                  {address}
                                </Typography>
                              </Link>
                            </Box>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{name}</TableCell>
                        {/* <TableCell align="left">{companyName}</TableCell> */}
                        <TableCell align="left">
                          {createdAt.slice(0, 10)}
                        </TableCell>
                        <TableCell align="left">
                          {fromDate.slice(0, 10)}
                        </TableCell>
                        <TableCell align="left">
                          {toDate.slice(0, 10)}
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            variant="contained"
                            onClick={() => handlePopupOpen(row)}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {myApplyList.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={TABLE_HEAD.length}
                        sx={{ border: 'none' }}
                      >
                        <DataNotAvailable title="You don't have any requests" />
                      </TableCell>
                    </TableRow>
                  ) : (
                    myApplyList.length === 0 && (
                      <TableRow>
                        <TableCell
                          colSpan={TABLE_HEAD.length}
                          sx={{ border: 'none' }}
                        >
                          <DataNotAvailable title="No results found" />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          {myApplyList.length !== 0 && (
            <Pagination
              shape="rounded"
              count={numberOfPages}
              siblingCount={0}
              boundaryCount={2}
              onChange={handlePageChange}
              sx={{
                py: 2,
                '& .MuiPagination-ul': { justifyContent: 'center' },
              }}
            />
          )}
        </Box>
      </Card>
      {popupOpen ? (
        <BookingRequestPopup
          popupOpen={popupOpen}
          setPopupOpen={setPopupOpen}
          defaultPage={5}
          viewDetails={viewDetails}
          handleRequest={handleRequest}
          loginInfo={loginInfo}
        />
      ) : null}
    </>
  );
};
