import { Grid, Box, Container, Typography, Button, Stack } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import Rectangle5203 from '../../assets/images/Rectangle5203.png';
import { Link } from 'react-router-dom';
import { IMG_URL } from '../../utils/constant';

const StylePopular = styled(Box)(({ theme }) => ({
  background: '#fff',
  padding: '0 0 80px',
  [theme.breakpoints.down('lg')]: {
    padding: '0 0 56px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 0 40px',
  },
}));
const BannerLeftMain = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));
const BannerLeftText = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    '& .MuiStack-root': {
      gap: '10px',
      '& .MuiButtonBase-root': {
        padding: '8px 15px',
        minWidth: 'calc(50% - 10px)',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiStack-root': {
      flexDirection: 'column',
      '& .MuiButtonBase-root': {
        minWidth: '100%',
      },
    },
  },
}));
const BannerLeft = styled(Box)(({ theme }) => ({
  maxWidth: '520px',
}));
const HostImages = styled(Box)(({ theme }) => ({
  marginLeft: '-20%',
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'start',
  overflow: 'hidden',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    marginLeft: '0',
  },
}));

export const BannerSection = (props) => {
  let { aboutBannerSection } = props;
  return (
    <>
      <StylePopular>
        <Container
          fixed={true}
          sx={{
            maxWidth: {
              xl: '1326px',
            },
          }}
        >
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <HostImages>
                <Box
                  sx={{
                    overflow: 'hidden',
                    aspectRatio: '1.3',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {aboutBannerSection && aboutBannerSection.length > 0 && (
                    <img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        display: 'block',
                      }}
                      src={`${IMG_URL}${aboutBannerSection[0]?.bannerImage}`}
                    />
                  )}
                </Box>
              </HostImages>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: { md: 'start', xs: 'center' },
              }}
            >
              <BannerLeftMain>
                <BannerLeft>
                  <BannerLeftText>
                    {aboutBannerSection && aboutBannerSection.length > 0 && (
                      <>
                        <Typography
                          variant="button"
                          component="p"
                          sx={{ mb: 2, color: 'secondary.main' }}
                        >
                          {aboutBannerSection[0]?.title}
                        </Typography>
                        <Typography variant="h2" sx={{ mb: 4 }}>
                          {aboutBannerSection[0]?.subTitle}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: 'normal', color: 'text.secondary' }}
                        >
                          {aboutBannerSection[0]?.description}
                          <br />
                          <br />
                          {/* {aboutContents.aboutUsSubDescription} */}
                        </Typography>
                      </>
                    )}
                  </BannerLeftText>
                </BannerLeft>
              </BannerLeftMain>
            </Grid>
          </Grid>
        </Container>
      </StylePopular>
    </>
  );
};
