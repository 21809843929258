import React, { useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import Scrollbar from '../../../components/scrollbar';
import {
  Card,
  TableCell,
  Button,
  TableRow,
  TableHead,
  Box,
  TableContainer,
  Paper,
  Table,
  CardHeader,
  TableBody,
  Tab,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { getTransactionHistory } from '../../../services/apiServices';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';
import { nairaSymbol } from '../../../utils/constant';
import { DataNotAvailable } from '../../../components/data-not-found';
import { useWithdrwalRequestStore } from '../../../redux/features/renderStateWithdrawalSlice';

const StyledIcon = styled(Box)(({ theme }) => ({}));
const StyledTabList = styled(TabList)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    background: '#FEAE01',
    borderRadius: '30px',
    height: '4px',
    width: '50px !important',
    marginLeft: '20px',
  },
}));
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: '500',
    color: '#808191',
  })
);

function createData(id, name, price) {
  return { id, name, price };
}

const rows = [
  createData('#16526136151', 'Hafizdzaki', '$128,00'),
  createData('#16526136152', 'Rois Maulana', '$80,00'),
  createData('#16526136153', 'Ahmad Sanusi', '$90,00'),
  createData('#16526136154', 'Hafizdzaki', '$128,00'),
  createData('#16526136155', 'Rois Maulana', '$80,00'),
  createData('#16526136156', 'Ahmad Sanusi', '$90,00'),
];

export const AppPayments = ({ title }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState('1');
  const [open, setOpen] = useState(false);
  const [spinFlag, setSpinFlag] = useState(false);
  const [message, setMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionList, setTransactionList] = useState([]);
  const { renderWithdrwalRequestFlag, updateRenderWithdrwalRequestFlag } =
    useWithdrwalRequestStore();
  const fetchTransactionHistory = async () => {
    try {
      setSpinFlag(true);
      let payload = { currentPage: currentPage };
      let { data } = await getTransactionHistory(payload);

      if (data.statusCode === 200) {
        setSpinFlag(false);
        setTransactionList(data.transactionList);
      } else {
        setSpinFlag(false);
        setMessage(data.message);
        setOpen(true);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  useEffect(() => {
    fetchTransactionHistory();
  }, [renderWithdrwalRequestFlag]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogPopup open={open} setOpen={setOpen} message={message} />
      <Card>
        <CardHeader
          title={title}
          action={
            <Button size="small" color="primary" component={Link} to="/payment">
              See all
            </Button>
          }
          sx={{ pb: 2 }}
        />
        <Box>
          {/* <TabContext value={value}> */}
          {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <StyledTabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <StyledTab label="Sent" value="1" />
                <StyledTab label="Pending" value="2" />
              </StyledTabList>
            </Box> */}
          {/* <TabPanel value="1" sx={{ p: 0 }}> */}
          <Scrollbar style={{ height: '295px' }}>
            <PaymentsSent transactionList={transactionList} />
          </Scrollbar>
          {/* </TabPanel> */}
          {/* <TabPanel value="2" sx={{ p: 0 }}>
              <PaymentsPending transactionList={transactionList} />
            </TabPanel> */}
          {/* </TabContext> */}
        </Box>
      </Card>
    </>
  );
};

function PaymentsSent(props) {
  let { transactionList } = props;
  return (
    <>
      <Scrollbar style={{ height: '262px' }}>
        {transactionList?.length > 0 && (
          <TableContainer
            component={Paper}
            sx={{
              minWidth: 350,
              textTransform: 'capitalize',
              '& .MuiTableCell-root': {
                fontSize: { md: '16px' },
              },
            }}
          >
            <Table sx={{ width: '100%' }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>ID Invoice</TableCell>
                  <TableCell>Recipient</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.referenceId}
                    </TableCell>
                    <TableCell>
                      {row.merchantInformation.firstname}{' '}
                      {row.merchantInformation.lastname}
                    </TableCell>
                    <TableCell>
                      {nairaSymbol}
                      {/* {row.totalAmount} */}
                      {row?.propertyRent}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {transactionList?.length === 0 ? (
          <Box sx={{ px: 2, pb: 2, height: '295px' }}>
            <DataNotAvailable
              sx={{ p: 2, minHeight: 'auto' }}
              title="you don't have any payments"
            />
          </Box>
        ) : null}
      </Scrollbar>
    </>
  );
}
function PaymentsPending(props) {
  let { transactionList } = props;
  return (
    <>
      <Scrollbar style={{ maxHeight: '262px' }}>
        <TableContainer
          component={Paper}
          sx={{
            minWidth: 350,
            '& .MuiTableCell-root': {
              fontSize: { md: '16px' },
            },
          }}
        >
          <Table sx={{ width: '100%' }} aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell>ID Invoice</TableCell>
                <TableCell>Recipient</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionList.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.referenceId}
                  </TableCell>
                  <TableCell>
                    {' '}
                    {row.merchantInformation.firstname}{' '}
                    {row.merchantInformation.lastname}
                  </TableCell>
                  <TableCell>
                    {nairaSymbol}
                    {row.totalAmount}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </>
  );
}
