import React, { useState } from 'react';
import {
  Button,
  InputAdornment,
  OutlinedInput,
  Box,
  Card,
  List,
  ListItemButton,
  Paper,
  Typography,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import Scrollbar from '../../components/scrollbar';
import SvgColor from '../svg-color';
import mapPin from '../../assets/icons/map-pin.svg';
import { GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { GOOGLE_MAP_KEY } from '../../utils/constant';
import { DialogPopup } from '../dialog-popup/DialogPopup';
import Filter from '../../assets/icons/Filter.svg';

export const StyledItemButton = styled((props) => (
  <ListItemButton {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  position: 'relative',
  textTransform: 'capitalize',
  borderRadius: '6px',
}));
const StyledSearchbar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}));
const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 'calc(100% - 172px)',
  flexGrow: '1',
  borderRadius: 14,
  marginRight: 12,
  height: 54,
  zIndex: '3',
  backgroundColor: '#fff',
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginRight: 0,
    marginBottom: 16,
  },
}));

const PropertySearchForm = (props) => {
  let { resultRef, handleClickOpen } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [address, setAddress] = useState('');
  let [errMsg, setErrMsg] = useState('');

  const searchLocation = async (value) => {
    // console.log('searchLocation');
    try {
      // Regular expression to match common patterns of addresses
      if (address.trim() !== '' && value.trim() !== '') {
        let results = await geocodeByAddress(value);
        results = results[0];
        const latLng = await getLatLng(results);
        navigate(`/available-spaces?lat=${latLng.lat}&&lng=${latLng.lng}`);
        // setErrMsg('');
        if (resultRef.current) {
          resultRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }
    } catch (err) {
      console.log('invalid address');
    }
  };

  const handleChange = (value) => {
    setAddress(value);
  };

  const handleAddress = (value) => {
    setAddress(value);
    searchLocation(value);
  };

  return (
    <>
      <StyledSearchbar>
        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          onSelect={(value) => handleAddress(value)}
          searchOptions={{
            componentRestrictions: { country: [] },
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <Box
              sx={{
                width: '100%',
                position: 'relative',
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <StyledSearch
                {...getInputProps({
                  placeholder: 'Search city, neighbourhood, school, building',
                })}
                startAdornment={
                  <InputAdornment position="start">
                    <SvgColor sx={{ color: 'secondary.main' }} src={mapPin} />
                  </InputAdornment>
                }
              />
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  width: { md: '160px', sm: '65px', xs: '100%' },
                  height: '54px',
                  mr: '10px',
                }}
                onClick={handleClickOpen}
              >
                <SvgColor
                  sx={{
                    mr: { xs: 1.5, sm: 0, md: 1.5 },
                    width: '18px',
                    height: '18px',
                  }}
                  src={Filter}
                />
                <Typography
                  component="span"
                  sx={{
                    display: {
                      xs: 'inline-block',
                      sm: 'none',
                      md: 'inline-block',
                    },
                  }}
                >
                  Filter
                </Typography>
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                id="homeSearchButton"
                type="submit"
                onClick={() => searchLocation(address)}
                sx={{ width: { sm: '160px', xs: '100%' } }}
              >
                Search
              </Button>
              {/* <span>{errMsg}</span> */}
              {suggestions.length !== 0 && (
                <Paper
                  sx={{
                    position: 'absolute',
                    left: '0',
                    top: '54px',
                    width: { sm: 'calc(100% - 172px)', xs: '100%' },
                    zIndex: '3',
                    p: 0.5,
                    boxShadow: (theme) => theme.customShadows.dropdown,
                    borderRadius: 2.5,
                  }}
                >
                  <Scrollbar style={{ maxHeight: '295px' }}>
                    <List sx={{ p: 0 }}>
                      {suggestions.map((suggestion, index) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <StyledItemButton
                            key={index}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <b>{suggestion.description}</b>
                          </StyledItemButton>
                        );
                      })}
                    </List>
                  </Scrollbar>
                </Paper>
              )}
            </Box>
          )}
        </PlacesAutocomplete>
      </StyledSearchbar>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_KEY,
  libraries: ['places'],
})(PropertySearchForm);
