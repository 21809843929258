import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Divider,
  Box,
  Stack,
  Grid,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  DialogActions,
  FormControlLabel,
  IconButton,
  styled,
} from '@mui/material';
import { Icon } from '@iconify/react';
import SvgColor from '../../components/svg-color';
import credit_card from '../../assets/icons/credit-card-02.svg';
import { IMG_URL } from '../../utils/constant';
import { currencySeprator } from '../../utils/common-function';
import { nairaSymbol } from '../../utils/constant';
const PriceTable = styled(Box)(({ theme }) => ({
  '& .MuiStack-root': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 0 12px',
    '&:last-child': {
      padding: '10px 0 0',
    },
  },
}));
const DialogPopupLandlordDetails = ({
  handlePopupClose,
  popupOpen,
  amount,
  handleButtonClickDetail,
  subTotal,
  setSubTotal,
  transferedFee,
  setTransferedFee,
}) => {
  const amountCalc = () => {
    const t_fee = Math.ceil(amount * 5) / 100;
    const st_fee = Math.ceil(amount - t_fee);
    setSubTotal(st_fee);
    setTransferedFee(t_fee);
  };
  useEffect(() => {
    if (amount) amountCalc();
  }, [amount]);
  return (
    <div>
      <Dialog
        onClose={handlePopupClose}
        aria-labelledby="customized-dialog-title"
        open={popupOpen}
        fullWidth
        maxWidth={'xs'}
      >
        <IconButton
          aria-label="close"
          onClick={handlePopupClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon icon="ic:round-close" />
        </IconButton>
        {amount && (
          <DialogContent dividers>
            <Grid container>
              <Grid item md={12} xs={12}>
                <Box>
                  <Box>
                    <Stack
                      sx={{
                        mb: 3,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="h6">Price details</Typography>
                    </Stack>
                    <PriceTable>
                      <Stack>
                        <Typography variant="subtitle2">Sub Total</Typography>
                        <Typography variant="body1" align="right">
                          {/* service fee 10% */}
                          {nairaSymbol}
                          {/* {(amount - (amount * 10) / 100).toFixed(2)} */}
                          {Math.trunc(subTotal)}
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle2">
                          Transfered Fee
                        </Typography>
                        <Typography variant="body1" align="right">
                          {/* service fee 4.5% */}
                          {nairaSymbol}
                          {/* {((amount * 10) / 100).toFixed(2)} */}
                          {Math.trunc(transferedFee)}
                        </Typography>
                      </Stack>
                      <Divider />
                      <Stack>
                        <Typography variant="h6">Total</Typography>
                        <Typography variant="h6" align="right">
                          {nairaSymbol}
                          {amount}
                        </Typography>
                      </Stack>
                    </PriceTable>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions sx={{ flexDirection: 'row', px: 3, pt: 1.5, pb: 4 }}>
          <Button
            size="medium"
            fullWidth
            variant="contained"
            color="success"
            onClick={handleButtonClickDetail}
          >
            <SvgColor sx={{ mr: { xl: 2, xs: 1 } }} src={credit_card} />
            Send
          </Button>

          <Button
            fullWidth
            // variant="softError"
            variant="outlined"
            color="primary"
            sx={{
              bgcolor: '#FAFAFA',
            }}
            size="medium"
            onClick={handlePopupClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogPopupLandlordDetails;
