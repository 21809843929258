import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

// let initialState = {
//   revenueGraphData: [],
//   monthGraphData: [],
// };
let initialState = {
  revenueGraphData: [
    30538750, 195002388, 72274570, 31005000, 29387750, 15795000, 15390000,
    15510000, 15310000, 10560000, 15390000, 37416500, 18388000,
  ],
  monthGraphData: [
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
  ],
};
export const revenueSlice = createSlice({
  name: 'revenueData',
  initialState,
  reducers: {
    updateRevenueGraphData: (state, action) => {
      state.revenueGraphData = action.payload;
    },
    updateMonthGraphData: (state, action) => {
      state.monthGraphData = action.payload;
    },
  },
});

export function useRevenueStore() {
  const revenueGraphData = useSelector(
    (state) => state.revenueData.revenueGraphData
  );
  const monthGraphDataInfo = useSelector(
    (state) => state.revenueData.monthGraphData
  );
  const dispatch = useDispatch();
  return {
    revenueGraphData,
    monthGraphDataInfo,
    updateRevenueGraphData: (payload) =>
      dispatch(updateRevenueGraphData(payload)),
    updateMonthGraphData: (payload) => dispatch(updateMonthGraphData(payload)),
  };
}

export const { updateRevenueGraphData, updateMonthGraphData } =
  revenueSlice.actions;

export default revenueSlice.reducer;
