import React, { useState, useEffect } from 'react';
import {
  Card,
  TextField,
  Button,
  MenuItem,
  Grid,
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  List,
  ListItemButton,
  Select,
  OutlinedInput,
  InputLabel,
  FormControl,
  IconButton,
  Paper,
  Checkbox,
  Tooltip,
  FormHelperText,
} from '@mui/material';
import axios from 'axios';
import { styled, alpha } from '@mui/material/styles';
import { Formik, Form, FieldArray, ErrorMessage, Field } from 'formik';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import SvgColor from '../../../components/svg-color';
import ArrowIconLeft from '../../../assets/icons/ArrowIconLeft.svg';
import ArrowIconRight from '../../../assets/icons/ArrowIconRight.svg';
import {
  addProperties,
  fetchRulesAndAmenities,
} from '../../../services/apiServices';
import { DialogPopup } from '../../../components/dialog-popup/DialogPopup';
import Scrollbar from '../../../components/scrollbar';
import { addPropertySchema } from '../../../utils/validation/Property';
import imgUpload from '../../../assets/icons/imgUpload.svg';
import Dropzone, { useDropzone } from 'react-dropzone';
import { useNavigate, useLocation } from 'react-router-dom';
import { GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { REGEX } from '../../../utils/regex';
import { IMG_URL } from '../../../utils/constant';
import { priceOptions, propertyOptions } from '../../../utils/optionsForSelect';
import { GOOGLE_MAP_KEY } from '../../../utils/constant';
import CurrencyFormat from 'react-currency-format';
import { getNumberWithoutComma } from '../../../utils/common-function';
import dayjs from 'dayjs';
import { HeaderBreadcrumbs } from '../../../components/breadcrumbs';

const MultipleFormControl = styled(FormControl)(({ theme }) => ({
  flexGrow: '1',
  '& .MuiInputBase-root': {
    height: '54px',
  },
  '& .MuiInputLabel-root': {
    fontWeight: '600',
    fontSize: '14px',
    color: '#9CA3AF',
    '&.MuiFormLabel-filled, &.Mui-focused': {
      transform: 'translate(14px, 2px) scale(1)',
      fontWeight: '400',
      fontSize: '12px',
    },
  },
  '& .MuiOutlinedInput-notchedOutline span': {
    display: 'none',
  },
}));

const StyledSelect = styled(OutlinedInput)(({ theme }) => ({
  flexGrow: '1',
  borderRadius: 14,
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const CarouselDiv = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  '& .slick-slider': {
    height: '100%',
    width: '100%',
    '& .slick-list': {
      height: '100%',
      width: '100%',
      '& .slick-track': {
        height: '100%',
        '& .slick-slide': {
          '&>div': {
            height: '100%',
            width: '100%',
          },
        },
      },
    },
    '& .slick-arrow': {
      fontSize: '0',
      width: '20px',
      height: '20px',
      zIndex: '1',
      transition: '0.45s ease-in-out',
      borderRadius: '50%',
      opacity: '0.2',
      '&:before': {
        fontSize: '0',
      },
      '&.slick-prev': {
        background: `rgba(255,255,255,0.8) url(${ArrowIconLeft}) no-repeat center center / cover`,
        left: '-5px',
      },
      '&.slick-next': {
        background: `rgba(255,255,255,0.8) url(${ArrowIconRight}) no-repeat center center / cover`,
        right: '-5px',
      },
      '&.slick-disabled': {
        display: 'none !important',
      },
    },
    '&:hover': {
      '& .slick-arrow': {
        opacity: '1',
      },
    },
    '& .slick-dots': {
      bottom: '0',
      '& li': {
        margin: '0',
        '& button:before': {
          fontSize: '22px',
          color: '#fff',
        },
      },
    },
  },
}));

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  dots: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const AddProperty = () => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [spinFlag, setSpinFlag] = useState(false);
  const [open, setOpen] = useState();
  const [message, setMessage] = useState();
  const [rules, setRules] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [statusCode, setStatusCode] = useState();

  const rulesOptions = rules.map((item) => ({
    label: item.rules,
    path: item.path,
  }));

  const amenitiesOptions = amenities.map((item) => ({
    label: item.amenities,
    path: item.path,
  }));

  const initialValues = {
    properties: [
      {
        title: '',
        propertyType: '',
        description: '',
        address: '',
        city: '',
        country: '',
        zip: '',
        amenities: [],
        rules: [],
        unitArray: [
          {
            unitName: '',
            amenities: [],
            rules: [],
            price: '',
            durationName: '',
            // priceArr: [
            //   {
            //     price: '',
            //     durationName: 'year',
            //   },
            // ],
            bedrooms: '',
            bathrooms: '',
            availableFrom: '',
          },
        ],
        latitude: '',
        longitude: '',
        propertyImg: [],
        checkInStartTime: '',
        checkInEndTime: '',
        checkOutTime: '',
      },
    ],
  };

  const onDataSubmit = async (values) => {
    try {
      setSpinFlag(true);
      let propertiesData = new FormData();
      values.properties.forEach(({ propertyImg, unitArray }, index) => {
        unitArray.map((item) => {
          item.price = getNumberWithoutComma(item.price);
        });
        propertyImg.forEach((img) => {
          propertiesData.append(`propertyImg_${index}`, img);
        });
      });

      propertiesData.append('properties', JSON.stringify(values.properties));
      const { data } = await addProperties(propertiesData);

      if (data.statusCode === 200) {
        setOpen(true);
        navigate('/dashboard');
        setSpinFlag(false);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      } else {
        setOpen(true);
        setSpinFlag(false);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const rulesAndAmenities = async () => {
    try {
      setSpinFlag(true);
      const { data } = await fetchRulesAndAmenities();

      if (data.statusCode === 200) {
        setSpinFlag(false);
        setRules(data.rules);
        setSpinFlag(false);
        setAmenities(data.amenities);
        setStatusCode(data.statusCode);
      } else {
        setOpen(true);
        setSpinFlag(false);
        setMessage(data.message);
        setStatusCode(data.statusCode);
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const handleavailableDate = (e) => {
    let date = new Date(e.$d),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    let nDate = [date.getFullYear(), mnth, day].join('-');
    return nDate;
  };

  const handleAddress = async (value) => {
    let result = await geocodeByAddress(value);

    result = result[0];
    const latLng = await getLatLng(result);
    const { long_name: country = '' } =
      result.address_components.find((c) => c.types.includes('country')) || {};
    const { long_name: city = '' } =
      result.address_components.find((c) => c.types.includes('locality')) || {};
    const { long_name: zip = '' } =
      result.address_components.find((c) => c.types.includes('postal_code')) ||
      {};
    return { country, city, zip, latLng };
  };

  useEffect(() => {
    rulesAndAmenities();
  }, []);

  //get latlng
  async function getLatLngFromAddress(address) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${GOOGLE_MAP_KEY}`;
    try {
      const response = await axios.get(url);
      const { results } = response.data;
      if (results && results.length > 0) {
        const { lat, lng } = results[0].geometry.location;
        return { latitude: lat, longitude: lng };
      } else {
        return { latitude: '', longitude: '' };
      }
    } catch (error) {
      console.error('Error fetching geocoding latitude longitude data:', error);
      return { latitude: '', longitude: '' };
    }
  }

  return (
    <>
      <HeaderBreadcrumbs
        heading="Add new property"
        links={[
          { name: 'Dashboard', href: '/dashboard' },
          { name: 'Add new property' },
        ]}
      />
      <Box>
        <DialogPopup
          open={open}
          setOpen={setOpen}
          message={message}
          size="xs"
          statusCode={statusCode}
        />
        <Formik
          initialValues={initialValues}
          validationSchema={addPropertySchema}
          onSubmit={(values) => {
            onDataSubmit(values);
          }}
          render={({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <FieldArray
                name="properties"
                render={(arrayHelpers) => (
                  <>
                    {values.properties.map((friend, index) => (
                      <Card
                        key={index}
                        sx={{
                          p: { md: 4, sm: 3, xs: 2 },
                          mb: 5,
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Box sx={{ textAlign: 'center' }}>
                              <Typography sx={{ mb: 3 }} variant="h5">
                                Upload photo of your property
                              </Typography>
                              <Dropzone
                                onDrop={(acceptedFiles) => {
                                  if (acceptedFiles.length === 0) {
                                    return;
                                  }
                                  const onlyImages = acceptedFiles.filter(
                                    (file) => file.type.startsWith('image/')
                                  );
                                  // let onlyImages = [];
                                  // acceptedFiles.map((item) => {
                                  //   return item.type.slice(0, 5) === 'image'
                                  //     ? onlyImages.push(item)
                                  //     : (onlyImages = []);
                                  // });
                                  setFieldValue(
                                    `properties.${index}.propertyImg`,
                                    friend.propertyImg.concat(
                                      onlyImages.map((file) =>
                                        Object.assign(file, {
                                          preview: URL.createObjectURL(file),
                                        })
                                      )
                                    )
                                  );
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <Box
                                    sx={{
                                      color: '#9CA3AF',
                                      border: '1px dashed #D1D5DB',
                                      borderRadius: '14px',
                                      padding: {
                                        md: '42px 16px',
                                        xs: '20px 16px',
                                      },
                                      maxWidth: '472px',
                                      m: '0 auto',
                                    }}
                                  >
                                    <Box {...getRootProps()}>
                                      <input
                                        name={`properties.${index}.propertyImg`}
                                        {...getInputProps()}
                                      />
                                      <SvgColor
                                        src={imgUpload}
                                        alt={imgUpload}
                                        sx={{
                                          width: '44px',
                                          height: '44px',
                                          mb: 3,
                                        }}
                                      />
                                      <Typography variant="h5">
                                        Drag and drop image or
                                      </Typography>
                                      <Button
                                        variant="contained"
                                        size="large"
                                        sx={{ px: 5, my: 2 }}
                                      >
                                        Browser file
                                      </Button>
                                      <Typography
                                        component="p"
                                        variant="caption"
                                      >
                                        Photo must be JPEG,JPG,WEBP or PNG
                                        format and least 1024x768
                                      </Typography>
                                    </Box>
                                  </Box>
                                )}
                              </Dropzone>
                              <ErrorMessage
                                error
                                component={FormHelperText}
                                name={`properties.${index}.propertyImg`}
                                sx={{ textAlign: 'center' }}
                              />
                              {friend.propertyImg.length === 0 ? (
                                ''
                              ) : (
                                <CarouselDiv>
                                  <Slider {...settings}>
                                    {friend.propertyImg.map(
                                      (file, imgIndex) => (
                                        <Box
                                          key={imgIndex}
                                          sx={{
                                            px: 1,
                                            py: 1,
                                            position: 'relative',
                                          }}
                                        >
                                          <img
                                            src={file.preview}
                                            style={{
                                              width: '100%',
                                              height: '100%',
                                              objectFit: 'cover',
                                              borderRadius: '14px',
                                              aspectRatio: '1.5',
                                            }}
                                            onLoad={() => {
                                              URL.revokeObjectURL(file.preview);
                                            }}
                                          />
                                          <Tooltip
                                            title="Delete"
                                            placement="top"
                                            arrow
                                          >
                                            <IconButton
                                              aria-label="delete"
                                              size="small"
                                              color="error"
                                              sx={{
                                                padding: '2px',
                                                position: 'absolute',
                                                top: '10px',
                                                right: '10px',
                                                bgcolor: '#fff !important',
                                              }}
                                              onClick={() => {
                                                friend.propertyImg.splice(
                                                  imgIndex,
                                                  1
                                                );
                                                setFieldValue(
                                                  `properties.${index}.propertyImg`,
                                                  friend.propertyImg.map(
                                                    (file) =>
                                                      Object.assign(file, {
                                                        preview:
                                                          URL.createObjectURL(
                                                            file
                                                          ),
                                                      })
                                                  )
                                                );
                                              }}
                                            >
                                              <Icon icon="iconamoon:close-light" />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      )
                                    )}
                                  </Slider>
                                </CarouselDiv>
                              )}
                            </Box>
                          </Grid>
                          <Grid item md={6} xs={12} container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h5">
                                Property description
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                type="Text"
                                label="Title*"
                                value={friend.title}
                                name={`properties.${index}.title`}
                                id={`properties.${index}.title`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                error
                                component={FormHelperText}
                                name={`properties.${index}.title`}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                select
                                fullWidth
                                label="Type property"
                                value={friend.propertyType}
                                name={`properties.${index}.propertyType`}
                                id={`properties.${index}.propertyType`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                {propertyOptions.map((option, index) => (
                                  <MenuItem key={index} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                              <ErrorMessage
                                error
                                component={FormHelperText}
                                name={`properties.${index}.propertyType`}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                multiline
                                rows="4"
                                fullWidth
                                value={friend.description}
                                label="Description"
                                name={`properties.${index}.description`}
                                id={`properties.${index}.description`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                error
                                component={FormHelperText}
                                name={`properties.${index}.description`}
                              />
                            </Grid>
                          </Grid>
                          <Grid item md={6} xs={12} container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h5">
                                Listing location
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              {/* map code start */}
                              <PlacesAutocomplete
                                fullWidth
                                value={friend.address}
                                onChange={async (value) => {
                                  setFieldValue(
                                    `properties.${index}.address`,
                                    value
                                  );
                                  setFieldValue(
                                    `properties.${index}.country`,
                                    ''
                                  );
                                  setFieldValue(`properties.${index}.city`, '');
                                  setFieldValue(`properties.${index}.zip`, '');
                                  let { latitude, longitude } =
                                    await getLatLngFromAddress(value);
                                  setFieldValue(
                                    `properties.${index}.latitude`,
                                    latitude
                                  );
                                  setFieldValue(
                                    `properties.${index}.longitude`,
                                    longitude
                                  );
                                }}
                                onSelect={async (value) => {
                                  let { country, city, zip, latLng } =
                                    await handleAddress(value);

                                  setFieldValue(
                                    `properties.${index}.address`,
                                    value
                                  );
                                  setFieldValue(
                                    `properties.${index}.country`,
                                    country
                                  );
                                  setFieldValue(
                                    `properties.${index}.city`,
                                    city
                                  );
                                  setFieldValue(`properties.${index}.zip`, zip);
                                  setFieldValue(
                                    `properties.${index}.latitude`,
                                    latLng.lat
                                  );
                                  setFieldValue(
                                    `properties.${index}.longitude`,
                                    latLng.lng
                                  );
                                }}
                                searchOptions={{
                                  componentRestrictions: { country: [] },
                                }}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <Box
                                    sx={{
                                      width: '100%',
                                      position: 'relative',
                                    }}
                                  >
                                    <TextField
                                      fullWidth
                                      value={friend.address}
                                      name={`properties.${index}.address`}
                                      // onBlur={handleBlur}
                                      {...getInputProps({
                                        label: 'Search address',
                                      })}
                                    />
                                    {suggestions.length !== 0 && (
                                      <Paper
                                        sx={{
                                          position: 'absolute',
                                          left: '0',
                                          top: '100%',
                                          width: '100%',
                                          zIndex: '3',
                                          boxShadow:
                                            '0px 0px 2px 0px rgba(145, 158, 171, 0.24), 0px 20px 40px -4px rgba(145, 158, 171, 0.24)',
                                          padding: '4px',
                                          borderRadius: '10px',
                                        }}
                                      >
                                        <Scrollbar
                                          style={{ maxHeight: '295px' }}
                                        >
                                          <List sx={{ p: 0 }}>
                                            {/* {loading && <div>Loading...</div>} */}
                                            {suggestions.map(
                                              (suggestion, index) => {
                                                const className =
                                                  suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                const style = suggestion.active
                                                  ? {
                                                      backgroundColor:
                                                        '#fafafa',
                                                      cursor: 'pointer',
                                                    }
                                                  : {
                                                      backgroundColor:
                                                        '#ffffff',
                                                      cursor: 'pointer',
                                                    };
                                                return (
                                                  <ListItemButton
                                                    key={index}
                                                    {...getSuggestionItemProps(
                                                      suggestion,
                                                      {
                                                        className,
                                                        style,
                                                      }
                                                    )}
                                                  >
                                                    <span>
                                                      {suggestion.description}
                                                    </span>
                                                  </ListItemButton>
                                                );
                                              }
                                            )}
                                          </List>
                                        </Scrollbar>
                                      </Paper>
                                    )}
                                  </Box>
                                )}
                              </PlacesAutocomplete>
                              {/* map code end */}

                              <ErrorMessage
                                error
                                component={FormHelperText}
                                name={`properties.${index}.address`}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Country"
                                value={friend.country}
                                name={`properties.${index}.country`}
                                id={`properties.${index}.country`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                error
                                component={FormHelperText}
                                name={`properties.${index}.country`}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                type="Text"
                                label="City"
                                value={friend.city}
                                name={`properties.${index}.city`}
                                id={`properties.${index}.city`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                error
                                component={FormHelperText}
                                name={`properties.${index}.city`}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                type="text"
                                value={friend.zip}
                                label="ZIP"
                                name={`properties.${index}.zip`}
                                id={`properties.${index}.zip`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onInput={(e) => {
                                  e.target.value = e.target.value
                                    .replace(REGEX.RESTRICT_CHAR, '')
                                    .slice(0, 6);
                                }}
                              />
                              <ErrorMessage
                                error
                                component={FormHelperText}
                                name={`properties.${index}.zip`}
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={12} container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h5">
                                Property detail
                              </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <MultipleFormControl fullWidth>
                                <InputLabel id="demo-multiple-chip-label">
                                  Property's amenities
                                </InputLabel>
                                <Select
                                  fullWidth
                                  multiple
                                  name={`properties.${index}.amenities`}
                                  id={`properties.${index}.amenities`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={friend.amenities}
                                  input={
                                    <StyledSelect
                                      id="select-multiple-chip"
                                      label="Property's amenities"
                                    />
                                  }
                                  renderValue={(selected) =>
                                    selected.join(', ')
                                  }
                                >
                                  {amenitiesOptions.map((option, index) => (
                                    <MenuItem key={index} value={option.label}>
                                      <img
                                        style={{
                                          marginRight: '16px',
                                          width: '24px',
                                          height: '24px',
                                          objectFit: 'contain',
                                        }}
                                        src={`${IMG_URL}${option.path}`}
                                        alt="err"
                                      />
                                      <span
                                        style={{
                                          width: '100%',
                                          display: 'block',
                                        }}
                                      >
                                        {option.label}
                                      </span>
                                      <Checkbox
                                        checked={
                                          friend.amenities.indexOf(
                                            option.label
                                          ) > -1
                                        }
                                        sx={{
                                          minWidth: '20px',
                                          border: 'none',
                                          transform: 'scale(.8)',
                                        }}
                                      />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </MultipleFormControl>
                              <ErrorMessage
                                error
                                component={FormHelperText}
                                name={`properties.${index}.amenities`}
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <MultipleFormControl fullWidth>
                                <InputLabel id="demo-multiple-chip-label">
                                  Property rules
                                </InputLabel>
                                <Select
                                  fullWidth
                                  multiple
                                  name={`properties.${index}.rules`}
                                  id={`properties.${index}.rules`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={friend.rules}
                                  input={
                                    <StyledSelect
                                      id="select-multiple-chip"
                                      label="Property's rules"
                                    />
                                  }
                                  renderValue={(selected) =>
                                    selected.join(', ')
                                  }
                                >
                                  {rulesOptions.map((option, index) => (
                                    <MenuItem key={index} value={option.label}>
                                      {/* <img
                                          style={{
                                            marginRight: '16px',
                                            width: '24px',
                                            height: '24px',
                                            objectFit: 'contain',
                                          }}
                                          src={`${IMG_URL}${option.path}`}
                                        /> */}
                                      <span
                                        style={{
                                          width: '100%',
                                          display: 'block',
                                        }}
                                      >
                                        {option.label}
                                      </span>
                                      <Checkbox
                                        checked={
                                          friend.rules.indexOf(option.label) >
                                          -1
                                        }
                                        sx={{
                                          minWidth: '20px',
                                          border: 'none',
                                          transform: 'scale(.8)',
                                        }}
                                      />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </MultipleFormControl>
                              <ErrorMessage
                                error
                                component={FormHelperText}
                                name={`properties.${index}.rules`}
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={12} container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h5">
                                Listing detail
                              </Typography>
                            </Grid>

                            {friend.unitArray.map((unitItem, unitIndex) => (
                              <Grid
                                item
                                container
                                xs={12}
                                key={unitIndex}
                                spacing={2}
                              >
                                <Grid item container xs={12} spacing={2}>
                                  <Grid item xs={12}>
                                    <TextField
                                      fullWidth
                                      type="Text"
                                      label="Unit name*"
                                      value={unitItem.unitName}
                                      name={`properties.${index}.unitArray[${unitIndex}].unitName`}
                                      id={`properties.${index}.unitArray[${unitIndex}].unitName`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <ErrorMessage
                                      error
                                      component={FormHelperText}
                                      name={`properties.${index}.unitArray[${unitIndex}].unitName`}
                                    />
                                  </Grid>
                                  <Grid item md={6} xs={12}>
                                    <MultipleFormControl fullWidth>
                                      <InputLabel id="demo-multiple-chip-label">
                                        Property's amenities
                                      </InputLabel>
                                      <Select
                                        fullWidth
                                        multiple
                                        name={`properties.${index}.unitArray[${unitIndex}].amenities`}
                                        id={`properties.${index}.unitArray[${unitIndex}].amenities`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={unitItem.amenities}
                                        input={
                                          <StyledSelect
                                            id="select-multiple-chip"
                                            label="Property's Amenities"
                                          />
                                        }
                                        renderValue={(selected) =>
                                          selected.join(', ')
                                        }
                                      >
                                        {amenitiesOptions.map(
                                          (option, index) => (
                                            <MenuItem
                                              key={index}
                                              value={option.label}
                                            >
                                              <img
                                                style={{
                                                  marginRight: '16px',
                                                  width: '24px',
                                                  height: '24px',
                                                  objectFit: 'contain',
                                                }}
                                                src={`${IMG_URL}${option.path}`}
                                                alt="err"
                                              />
                                              <span
                                                style={{
                                                  width: '100%',
                                                  display: 'block',
                                                }}
                                              >
                                                {option.label}
                                              </span>
                                              <Checkbox
                                                checked={
                                                  unitItem.amenities.indexOf(
                                                    option.label
                                                  ) > -1
                                                }
                                                sx={{
                                                  minWidth: '20px',
                                                  border: 'none',
                                                  transform: 'scale(.8)',
                                                }}
                                              />
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                    </MultipleFormControl>
                                    <ErrorMessage
                                      error
                                      component={FormHelperText}
                                      name={`properties.${index}.unitArray[${unitIndex}].amenities`}
                                    />
                                  </Grid>
                                  <Grid item container md={6} xs={12}>
                                    <Grid item container xs={12} spacing={1}>
                                      {/* {unitItem.priceArr.map(
                                        (priceItem, priceIndex) => ( */}
                                      <Grid
                                        item
                                        container
                                        spacing={1}
                                        sm={12}
                                        xs={12}
                                      >
                                        <Grid item sm={6} xs={6}>
                                          <CurrencyFormat
                                            customInput={TextField}
                                            fullWidth
                                            type="text"
                                            value={unitItem.price}
                                            name={`properties.${index}.unitArray[${unitIndex}].price`}
                                            label="Price"
                                            thousandSeparator={true}
                                            onChange={(e) => {
                                              let value = getNumberWithoutComma(
                                                e.target.value
                                              );

                                              setFieldValue(
                                                `properties.${index}.unitArray[${unitIndex}].price`,
                                                value
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            onInput={(e) => {
                                              e.target.value = e.target.value
                                                .replace(
                                                  REGEX.RESTRICT_CHAR,
                                                  ''
                                                )
                                                .slice(0, 9);
                                            }}
                                          />
                                          <ErrorMessage
                                            error
                                            component={FormHelperText}
                                            name={`properties.${index}.unitArray[${unitIndex}].price`}
                                          />
                                        </Grid>
                                        <Grid item sm={6} xs={6}>
                                          <TextField
                                            fullWidth
                                            select
                                            name={`properties.${index}.unitArray[${unitIndex}].durationName`}
                                            value={unitItem.durationName}
                                            label="Duration"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          >
                                            {priceOptions.map(
                                              (option, index) => (
                                                <MenuItem
                                                  key={index}
                                                  value={option.value}
                                                  // disabled={
                                                  //   priceIndex === 0 ||
                                                  //   unitItem.priceArr
                                                  //     .map((item) => {
                                                  //       return item.durationName;
                                                  //     })
                                                  //     .includes(
                                                  //       option.value
                                                  //     )
                                                  // }
                                                >
                                                  {option.label}
                                                </MenuItem>
                                              )
                                            )}
                                          </TextField>
                                          <ErrorMessage
                                            error
                                            component={FormHelperText}
                                            name={`properties.${index}.unitArray[${unitIndex}].durationName`}
                                          />
                                        </Grid>
                                        {/* <Grid item sm={1} xs={2}>
                                              {priceIndex === 0 ? null : (
                                                <IconButton
                                                  sx={{
                                                    bgcolor: '#F4F4F4',
                                                    mt: 2,
                                                  }}
                                                  color="error"
                                                  size="small"
                                                  onClick={() => {
                                                    unitItem.priceArr.splice(
                                                      priceIndex,
                                                      1
                                                    );
                                                    setFieldValue(
                                                      `properties.${index}.uniItem[${unitIndex}].priceArr`,
                                                      unitItem.priceArr
                                                    );
                                                  }}
                                                >
                                                  <Icon icon="uil:minus" />
                                                </IconButton>
                                              )}
                                            </Grid> */}
                                      </Grid>
                                      {/* )  )} */}
                                      {/* <Grid item sm={1} xs={2}>
                                        {unitItem.priceArr.length <= 1 ? (
                                          <IconButton
                                            sx={{ bgcolor: '#F4F4F4', mt: 2 }}
                                            size="small"
                                            onClick={() => {
                                              unitItem.priceArr.push({
                                                price: '',
                                                durationName: '',
                                              });
                                              setFieldValue(
                                                `properties.${index}.uniItem[${unitIndex}].priceArr`,
                                                unitItem.priceArr
                                              );
                                            }}
                                          >
                                            <Icon icon="uil:plus" />
                                          </IconButton>
                                        ) : null}
                                      </Grid> */}
                                    </Grid>
                                  </Grid>
                                  <Grid item md={6} xs={12}>
                                    <TextField
                                      fullWidth
                                      type="text"
                                      label="Number bedrooms"
                                      value={unitItem.bedrooms}
                                      name={`properties.${index}.unitArray[${unitIndex}].bedrooms`}
                                      id={`properties.${index}.unitArray[${unitIndex}].bedrooms`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      onInput={(e) => {
                                        e.target.value = e.target.value
                                          .replace(REGEX.RESTRICT_CHAR, '')
                                          .slice(0, 2);
                                      }}
                                    />
                                    <ErrorMessage
                                      error
                                      component={FormHelperText}
                                      name={`properties.${index}.unitArray[${unitIndex}].bedrooms`}
                                    />
                                  </Grid>
                                  <Grid item md={6} xs={12}>
                                    <TextField
                                      fullWidth
                                      type="text"
                                      label="Number bathrooms"
                                      value={unitItem.bathrooms}
                                      name={`properties.${index}.unitArray[${unitIndex}].bathrooms`}
                                      id={`properties.${index}.unitArray[${unitIndex}].bathrooms`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      onInput={(e) => {
                                        e.target.value = e.target.value
                                          .replace(REGEX.RESTRICT_CHAR, '')
                                          .slice(0, 2);
                                      }}
                                      // error={Boolean(
                                      //   `${touched}.properties.${index}.bathrooms` &&
                                      //     `${errors}.properties.${index}.bathrooms`
                                      // )}
                                      // helperText={
                                      //   `${touched}.properties.${index}.bathrooms` &&
                                      //   `${errors}.properties.${index}.bathrooms`
                                      // }
                                    />
                                    <ErrorMessage
                                      error
                                      component={FormHelperText}
                                      name={`properties.${index}.unitArray[${unitIndex}].bathrooms`}
                                    />
                                  </Grid>
                                  <Grid item md={6} xs={12}>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        sx={{ p: 0 }}
                                        components={['DatePicker']}
                                      >
                                        <DatePicker
                                          sx={{ width: '100%' }}
                                          label="Availbale from (YYYY-MM-DD)"
                                          format="YYYY-MM-DD"
                                          value={
                                            unitItem.availableFrom
                                              ? dayjs(unitItem.availableFrom)
                                              : null
                                          }
                                          disablePast={true}
                                          name={`properties.${index}.unitArray[${unitIndex}].availableFrom`}
                                          id={`properties.${index}.unitArray[${unitIndex}].availableFrom`}
                                          onChange={(e) => {
                                            const nDate =
                                              handleavailableDate(e);
                                            setFieldValue(
                                              `properties.${index}.unitArray[${unitIndex}].availableFrom`,
                                              nDate
                                            );
                                          }}
                                          onBlur={handleBlur}
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                    <ErrorMessage
                                      error
                                      component={FormHelperText}
                                      name={`properties.${index}.unitArray[${unitIndex}].availableFrom`}
                                    />
                                  </Grid>
                                  <Grid item md={6} xs={12}>
                                    <MultipleFormControl fullWidth>
                                      <InputLabel id="demo-multiple-chip-label">
                                        Property rules
                                      </InputLabel>
                                      <Select
                                        fullWidth
                                        multiple
                                        name={`properties.${index}.unitArray[${unitIndex}].rules`}
                                        id={`properties.${index}.unitArray[${unitIndex}].rules`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={unitItem.rules}
                                        input={
                                          <StyledSelect
                                            id="select-multiple-chip"
                                            label="Property's rules"
                                          />
                                        }
                                        renderValue={(selected) =>
                                          selected.join(', ')
                                        }
                                      >
                                        {rulesOptions.map((option, index) => (
                                          <MenuItem
                                            key={index}
                                            value={option.label}
                                          >
                                            {/* <img
                                          style={{
                                            marginRight: '16px',
                                            width: '24px',
                                            height: '24px',
                                            objectFit: 'contain',
                                          }}
                                          src={`${IMG_URL}${option.path}`}
                                        /> */}
                                            <span
                                              style={{
                                                width: '100%',
                                                display: 'block',
                                              }}
                                            >
                                              {option.label}
                                            </span>
                                            <Checkbox
                                              checked={
                                                unitItem.rules.indexOf(
                                                  option.label
                                                ) > -1
                                              }
                                              sx={{
                                                minWidth: '20px',
                                                border: 'none',
                                                transform: 'scale(.8)',
                                              }}
                                            />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </MultipleFormControl>
                                    <ErrorMessage
                                      error
                                      component={FormHelperText}
                                      name={`properties.${index}.unitArray[${unitIndex}].rules`}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  sx={{
                                    display: 'inline-flex',
                                    gap: 2,
                                    justifyContent: 'end',
                                  }}
                                >
                                  {friend.unitArray.length === 1 ? null : (
                                    <Button
                                      variant="softErrorOutlined"
                                      onClick={() => {
                                        friend.unitArray.splice(unitIndex, 1);

                                        setFieldValue(
                                          `properties.${index}.unitArray`,
                                          friend.unitArray
                                        );
                                      }}
                                      startIcon={<Icon icon="uil:minus" />}
                                    >
                                      Remove units
                                    </Button>
                                  )}
                                  {friend.unitArray.length ===
                                    unitIndex + 1 && (
                                    <Button
                                      variant="outlined"
                                      onClick={() => {
                                        friend.unitArray.push({
                                          unitName: unitItem.unitName,
                                          amenities: unitItem.amenities,
                                          rules: unitItem.rules,
                                          price: unitItem.price,
                                          durationName: unitItem.durationName,
                                          bedrooms: unitItem.bedrooms,
                                          bathrooms: unitItem.bathrooms,
                                          availableFrom: unitItem.availableFrom,
                                        });
                                        setFieldValue(
                                          `properties.${index}.unitArray`,
                                          friend.unitArray
                                        );
                                      }}
                                      startIcon={<Icon icon="uil:plus" />}
                                    >
                                      Add units
                                    </Button>
                                  )}
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                          {/* {values.properties.length === 1 ? (
                            ''
                          ) : (
                            <Grid item xs={12}>
                              <Stack
                                gap="24px"
                                flexDirection="row"
                                justifyContent="end"
                              >
                                <Button
                                  variant="outlined"
                                  color="error"
                                  size="large"
                                  sx={{
                                    px: 5,
                                    width: { md: 'auto', xs: '100%' },
                                  }}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <SvgColor
                                    src={deleteIcon}
                                    alt={deleteIcon}
                                    sx={{
                                      mr: 1.5,
                                      width: '18px',
                                      height: '18px',
                                    }}
                                  />
                                  Remove Listing
                                </Button>
                              </Stack>
                            </Grid>
                          )} */}
                        </Grid>
                      </Card>
                    ))}
                    {/* <Button
                      variant="outlined"
                      size="large"
                      sx={{
                        px: 5,
                        mr: { md: 3 },
                        mb: { md: 0, xs: 2 },
                        width: { md: 'auto', xs: '100%' },
                      }}
                      onClick={() =>
                        arrayHelpers.insert(values.properties.length + 1, {
                          title: values.properties[0].title,
                          propertyType: values.properties[0].propertyType,
                          description: values.properties[0].description,
                          address: values.properties[0].address,
                          city: values.properties[0].city,
                          country: values.properties[0].country,
                          zip: values.properties[0].zip,
                          amenities: values.properties[0].amenities,
                          bedrooms: values.properties[0].bedrooms,
                          bathrooms: values.properties[0].bathrooms,
                          rules: values.properties[0].rules,
                          availableFrom: values.properties[0].availableFrom,
                          latitude: values.properties[0].latitude,
                          longitude: values.properties[0].longitude,
                          propertyImg: values.properties[0].propertyImg,
                          checkInStartTime: '',
                          checkInEndTime: '',
                          checkOutTime: '',
                          priceArr: values.properties[0].priceArr,
                          // [
                          //   {
                          //     price: '',
                          //     durationName: 'year',
                          //   },
                          // ]
                        })
                      }
                    >
                      <SvgColor
                        src={duplicate}
                        alt={duplicate}
                        sx={{
                          mr: 1.5,
                          width: '18px',
                          height: '18px',
                        }}
                      />
                      Duplicate Listing
                    </Button> */}
                  </>
                )}
              />
              <Box sx={{ textAlign: 'right' }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ px: 5, width: { md: 'auto', xs: '100%' } }}
                >
                  Save new listing
                </Button>
              </Box>
            </Form>
          )}
        />
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={spinFlag}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_KEY,
  libraries: ['places'],
})(AddProperty);
