import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { RevenueCharts } from '../../Components/AllCharts';
import CountUp from 'react-countup';
import { useNavigate, useLocation } from 'react-router-dom';
import ShowSpinner from '../../Components/Common/spinner';
import ResponseModal from '../../Components/Common/SuccessModal';
import { getAllTransactionList } from '../../../services/apiServices';
import { useRevenueStore } from '../../../redux/features/revenueGraphSlice';
const RevenueChart = ({
  totalPayout,
  totalAvailableBalance,
  // revenueData,
  // monthName,
  allUsersCount,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [transactionList, setTrasactionList] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [monthName, setMonthName] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [spinFlag, setSpinFlag] = useState(false);
  const [selection, setSelection] = useState('all');
  const [response, setResponse] = useState({
    message: '',
    open: false,
    statusCode: null,
  });
  const { monthGraphDataInfo, revenueGraphData } = useRevenueStore();
  // const [chartData, setchartData] = useState([]);

  // const fetchAllTransactions = async () => {
  //   try {
  //     // setSpinFlag(true);
  //     let payload = {};
  //     let { data } = await getAllTransactionList(payload);
  //     if (data.statusCode === 200) {
  //       setSpinFlag(false);
  //       setRevenueData(data.totalAmountsArray);
  //       setMonthName(data.monthNames);
  //       setTotalEarnings(data.total_volume);
  //     } else {
  //       setSpinFlag(false);
  //       setResponse({
  //         message: data.message,
  //         statusCode: data.statusCode,
  //         open: true,
  //       });
  //     }
  //   } catch (err) {
  //     navigate(`/admin/error?msg=${err}&&path=${pathname}`);
  //   }
  // };

  useEffect(() => {
    const arr = revenueGraphData?.map((item) => item / 100) || [];
    setRevenueData(arr);
    setMonthName(monthGraphDataInfo);
  }, [revenueGraphData]);
  const onChangeChartPeriod = (timeline) => {
    setSelection(timeline);
    switch (timeline) {
      case 'month':
        break;
      case 'halfyear':
        break;
      case 'year':
        break;
      case 'all':
        break;
      default:
    }
  };
  const chartData = [
    {
      name: 'Revenue',
      type: 'column',
      data: revenueData,
    },
  ];
  return (
    <React.Fragment>
      <ResponseModal response={response} setResponse={setResponse} />
      <Card>
        <CardHeader className="border-0 align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Revenue</h4>
          {/* <div className="d-flex gap-1">
            <button
              type="button"
              className={`btn btn-sm ${
                selection === 'all' ? 'btn-secondary' : 'btn-soft-secondary'
              }`}
              onClick={() => {
                onChangeChartPeriod('all');
              }}
            >
              ALL
            </button>
            <button
              type="button"
              className={`btn btn-sm ${
                selection === 'month' ? 'btn-secondary' : 'btn-soft-secondary'
              }`}
              onClick={() => {
                onChangeChartPeriod('month');
              }}
            >
              1M
            </button>
            <button
              type="button"
              className={`btn btn-sm ${
                selection === 'halfyear'
                  ? 'btn-secondary'
                  : 'btn-soft-secondary'
              }`}
              onClick={() => {
                onChangeChartPeriod('halfyear');
              }}
            >
              6M
            </button>
            <button
              type="button"
              className={`btn btn-sm ${
                selection === 'year' ? 'btn-secondary' : 'btn-soft-secondary'
              }`}
              onClick={() => {
                onChangeChartPeriod('year');
              }}
            >
              1Y
            </button>
          </div> */}
        </CardHeader>

        <CardHeader className="p-0 border-0 bg-soft-light">
          <Row className="g-0 text-center">
            {/* <Col>
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <CountUp
                    prefix="₦"
                    start={0}
                    end={totalEarnings}
                    duration={3}
                  />
                </h5>
                <p className="text-muted mb-0">Earnings</p>
              </div>
            </Col> */}
            <Col>
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <CountUp
                    start={0}
                    prefix="₦"
                    end={totalAvailableBalance}
                    duration={3}
                  />
                </h5>
                <p className="text-muted mb-0">Available</p>
              </div>
            </Col>
            <Col>
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <CountUp
                    prefix="₦"
                    start={0}
                    end={totalPayout}
                    duration={3}
                  />
                </h5>
                <p className="text-muted mb-0">Payout</p>
              </div>
            </Col>
          </Row>
        </CardHeader>

        <CardBody className="p-0 pb-2">
          <div className="w-100">
            <div dir="ltr" id="chart-timeline">
              <RevenueCharts
                monthName={monthName}
                allUsersCount={allUsersCount}
                series={chartData}
                dataColors='["--vz-success", "--vz-primary", "--vz-danger"]'
              />
            </div>
          </div>
        </CardBody>
      </Card>
      <ShowSpinner spinFlag={spinFlag} />
    </React.Fragment>
  );
};

export default RevenueChart;
