import React, { useState, useEffect, useCallback } from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  CardHeader,
  Form,
  Label,
  Input,
  Button,
} from 'reactstrap';
import Dropzone, { useDropzone } from 'react-dropzone';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FieldArray, Formik, FormikProvider, useFormik } from 'formik';
import { IMG_URL } from '../../../../utils/constant';
import {
  addOrEditHomeContent,
  getCmsHomeContent,
} from '../../../../services/apiServices';
import ShowSpinner from '../../../Components/Common/spinner';
import ResponseModal from '../../../Components/Common/SuccessModal';
import { getAllSectionDynamic } from './getAllSection';

const HomePage = () => {
  const [selectedFiles, setselectedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [homeContentArr, setHomeContentArr] = useState([]);
  const [homeContents, setHomeContents] = useState([]);
  const [renderCount, setRender] = useState(true);
  const [spinFlag, setSpinFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [errorMessageOne, setErrorMessageOne] = useState();
  const [response, setResponse] = useState({
    message: '',
    open: false,
    statusCode: null,
  });
  const [uploadedImage, setUploadedImage] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pageName = searchParams.get('pageName');
  const pageSize = searchParams.get('pageSize');
  useEffect(() => {
    homePageContent();
  }, [renderCount, pageName]);

  const homePageContent = async () => {
    try {
      setSpinFlag(true);
      const payload = { pageName: pageName };
      const { data } = await getCmsHomeContent(payload);
      if (data.statusCode === 200) {
        const homeContentData = data?.homeData;
        setHomeContents(homeContentData);
        const dataSections = await getAllSectionDynamic(
          homeContentData,
          pageName,
          pageSize
        );
        setHomeContentArr(dataSections);
        setSpinFlag(false);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
      }
    } catch (err) {
      navigate(`/error?msg=${err}&&path=${pathname}`);
    }
  };

  const submit = async (value) => {
    try {
      setSpinFlag(true);
      let homecontentData = new FormData();
      value.value.map((item, index) => {
        homecontentData.append(`bannerImage${index}`, item?.bannerImage);
        homecontentData.append(`bannerImage2${index}`, item?.bannerImage2);
      });
      homecontentData.append('data', JSON.stringify(value?.value));
      homecontentData.append('pageName', JSON.stringify(pageName));
      let { data } = await addOrEditHomeContent(homecontentData);
      if (data.statusCode === 200) {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
        setRender(!renderCount);
      } else {
        setSpinFlag(false);
        setResponse({
          message: data.message,
          open: true,
          statusCode: data.statusCode,
        });
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`);
    }
  };

  return (
    <React.Fragment>
      <ResponseModal response={response} setResponse={setResponse} />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card className="bg-transparent">
                <CardHeader>
                  <Row className="g-2">
                    <Col>
                      <div>
                        <h5 className="card-title mb-0">
                          {pageName?.charAt(0).toUpperCase() +
                            pageName?.slice(1)}{' '}
                          page
                        </h5>
                      </div>
                    </Col>
                    <Col className="col-sm-auto ms-auto"></Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="all-sections">
                    <Formik
                      initialValues={homeContentArr}
                      onSubmit={(data) => submit(data)}
                      enableReinitialize
                    >
                      {(formik) => {
                        const {
                          values,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                        } = formik;

                        return (
                          <Form onSubmit={handleSubmit}>
                            <div>
                              <FieldArray
                                name="value"
                                render={(arrayHelpers) => (
                                  <div>
                                    <Row>
                                      {values?.value?.map((item, index) => {
                                        return (
                                          <Col md={12}>
                                            <Card className="">
                                              <CardHeader className="card-header d-flex justify-content-between">
                                                <h4 className="card-title mb-0">
                                                  {item?.section
                                                    ?.charAt(0)
                                                    .toUpperCase() +
                                                    item?.section?.slice(1)}
                                                </h4>
                                                {values?.value[index].section ==
                                                'aboutUs' ? (
                                                  <Link
                                                    className="btn btn-sm btn-success"
                                                    to={'/admin/about-us-list'}
                                                  >
                                                    List
                                                  </Link>
                                                ) : null}
                                                {values?.value[index].section ==
                                                'howItWorks' ? (
                                                  <Link
                                                    className="btn btn-sm btn-success"
                                                    to={'/admin/how-it-works'}
                                                  >
                                                    List
                                                  </Link>
                                                ) : null}
                                              </CardHeader>
                                              <CardBody>
                                                <Row>
                                                  <Col md={6}>
                                                    <div className="mb-3">
                                                      <Label
                                                        htmlFor=""
                                                        className="form-label"
                                                      >
                                                        Title
                                                      </Label>
                                                      <Input
                                                        name={`value[${index}].title`}
                                                        value={
                                                          values.value[index]
                                                            .title
                                                        }
                                                        onChange={handleChange}
                                                        placeholder="Title"
                                                      />
                                                    </div>
                                                    <div className="mb-3">
                                                      <Label
                                                        htmlFor=""
                                                        className="form-label"
                                                      >
                                                        Subtitle
                                                      </Label>
                                                      <Input
                                                        name={`value[${index}].subTitle`}
                                                        value={
                                                          values.value[index]
                                                            .subTitle
                                                        }
                                                        onChange={handleChange}
                                                        placeholder="Sub Title"
                                                      />
                                                    </div>
                                                    <div className="mb-3">
                                                      <Label
                                                        htmlFor=""
                                                        className="form-label"
                                                      >
                                                        {values?.value[index]
                                                          .section == 'hiring'
                                                          ? 'See All Jobs Link'
                                                          : 'Description'}
                                                      </Label>
                                                      <textarea
                                                        name={`value[${index}].description`}
                                                        value={
                                                          values.value[index]
                                                            .description
                                                        }
                                                        className="form-control pe-5"
                                                        onChange={handleChange}
                                                        placeholder="Description"
                                                        rows="3"
                                                        style={{
                                                          resize: 'none',
                                                        }}
                                                      ></textarea>
                                                    </div>
                                                  </Col>
                                                  {values?.value[index]
                                                    .section ==
                                                    'bannerSection' ||
                                                  values?.value[index]
                                                    .section == 'convenience' ||
                                                  values?.value[index]
                                                    .section ==
                                                    'listConfidently' ? (
                                                    <>
                                                      <Col md={6}>
                                                        <div className="mb-3">
                                                          <Label
                                                            htmlFor=""
                                                            className="form-label"
                                                          >
                                                            Banner image{' '}
                                                            <small className="text-muted">
                                                              (Please ensure
                                                              there is 1 banner
                                                              image uploaded)
                                                            </small>
                                                          </Label>
                                                          <Dropzone
                                                            onDrop={(
                                                              acceptedFiles
                                                            ) => {
                                                              const onlyImages =
                                                                acceptedFiles.filter(
                                                                  (file) =>
                                                                    file.type.startsWith(
                                                                      'image/'
                                                                    )
                                                                );

                                                              if (
                                                                !onlyImages?.length >
                                                                  0 &&
                                                                onlyImages
                                                              ) {
                                                                return;
                                                              }
                                                              if (
                                                                acceptedFiles.length >
                                                                1
                                                              ) {
                                                                setErrorMessageOne(
                                                                  'Max 1 image '
                                                                );
                                                                return;
                                                              }
                                                              if (
                                                                acceptedFiles.length !==
                                                                0
                                                              ) {
                                                                setErrorMessageOne(
                                                                  ''
                                                                );
                                                                values.value[
                                                                  index
                                                                ].bannerImage =
                                                                  acceptedFiles[0];
                                                                const imageUrl =
                                                                  URL.createObjectURL(
                                                                    acceptedFiles[0]
                                                                  );
                                                                setFieldValue(
                                                                  Object.assign(
                                                                    acceptedFiles[0],
                                                                    {
                                                                      preview:
                                                                        imageUrl,
                                                                    }
                                                                  )
                                                                );

                                                                setUploadedImage(
                                                                  imageUrl
                                                                );
                                                              }
                                                            }}
                                                          >
                                                            {({
                                                              getRootProps,
                                                              getInputProps,
                                                            }) => (
                                                              <div
                                                                className="dropzone dz-clickable"
                                                                style={{
                                                                  minHeight:
                                                                    'auto',
                                                                }}
                                                              >
                                                                <div
                                                                  className="dz-message needsclick"
                                                                  {...getRootProps()}
                                                                >
                                                                  <div className="mb-3">
                                                                    <i className="display-5 text-muted ri-upload-cloud-2-fill" />
                                                                  </div>
                                                                  <h4>
                                                                    Drop files
                                                                    here or
                                                                    click to
                                                                    upload.
                                                                  </h4>
                                                                </div>
                                                              </div>
                                                            )}
                                                          </Dropzone>
                                                          <ul
                                                            className="list-unstyled mb-0"
                                                            id="file-previews"
                                                          >
                                                            <li className="mt-2 dz-processing dz-image-preview dz-success dz-complete">
                                                              <div className="border rounded">
                                                                <div className="d-flex p-2">
                                                                  <div className="flex-shrink-0 me-3">
                                                                    <div className="avatar-sm bg-light rounded">
                                                                      <img
                                                                        data-dz-thumbnail=""
                                                                        className="img-fluid rounded d-block h-100 object-fit-cover"
                                                                        alt={
                                                                          values.bannerImage
                                                                        }
                                                                        src={
                                                                          typeof values
                                                                            ?.value[
                                                                            index
                                                                          ]
                                                                            ?.bannerImage ===
                                                                          'object'
                                                                            ? values
                                                                                ?.value[
                                                                                index
                                                                              ]
                                                                                ?.bannerImage
                                                                                ?.preview
                                                                            : `${IMG_URL}${values?.value[index]?.bannerImage}`
                                                                        }
                                                                        onLoad={() => {
                                                                          !values
                                                                            ?.value[
                                                                            index
                                                                          ]
                                                                            ?.bannerImage
                                                                            ? URL.revokeObjectURL(
                                                                                values.preview
                                                                              )
                                                                            : '';
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                  <div className="flex-grow-1">
                                                                    <div className="pt-1">
                                                                      <h5
                                                                        className="fs-14 mb-1"
                                                                        data-dz-name=""
                                                                      ></h5>
                                                                      <p
                                                                        className="fs-13 text-muted mb-0"
                                                                        data-dz-size=""
                                                                      >
                                                                        <strong></strong>
                                                                      </p>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                        <h6
                                                          style={{
                                                            color: 'red',
                                                          }}
                                                        >
                                                          {errorMessageOne}
                                                        </h6>
                                                      </Col>
                                                    </>
                                                  ) : null}
                                                  <>
                                                    {values?.value[index]
                                                      .section ==
                                                      'becomeLandlord' ||
                                                    values?.value[index]
                                                      .section ==
                                                      'rentFavouritePlace' ||
                                                    values?.value[index]
                                                      .section == 'hiring' ? (
                                                      <>
                                                        <Col md={6}>
                                                          <div className="mb-3">
                                                            <Label
                                                              htmlFor=""
                                                              className="form-label"
                                                            >
                                                              Banner image
                                                              <small className="text-muted">
                                                                (Please ensure
                                                                there is 2
                                                                banner image
                                                                uploaded)
                                                              </small>
                                                            </Label>
                                                            <Dropzone
                                                              multiple
                                                              onDrop={(
                                                                acceptedFiles
                                                              ) => {
                                                                const onlyImages =
                                                                  acceptedFiles.filter(
                                                                    (file) =>
                                                                      file.type.startsWith(
                                                                        'image/'
                                                                      )
                                                                  );

                                                                if (
                                                                  !onlyImages?.length >
                                                                    0 &&
                                                                  onlyImages
                                                                ) {
                                                                  return;
                                                                }
                                                                if (
                                                                  acceptedFiles.length >
                                                                  2
                                                                ) {
                                                                  setErrorMessage(
                                                                    'Max 2 image '
                                                                  );
                                                                  return;
                                                                }
                                                                if (
                                                                  acceptedFiles.length <
                                                                  2
                                                                ) {
                                                                  setErrorMessage(
                                                                    'Max 2 image '
                                                                  );
                                                                  return;
                                                                }
                                                                if (
                                                                  acceptedFiles.length !==
                                                                  0
                                                                ) {
                                                                  values.value[
                                                                    index
                                                                  ].bannerImage =
                                                                    acceptedFiles[0];
                                                                  const imageUrl =
                                                                    URL.createObjectURL(
                                                                      acceptedFiles[0]
                                                                    );
                                                                  setFieldValue(
                                                                    Object.assign(
                                                                      acceptedFiles[0],
                                                                      {
                                                                        preview:
                                                                          imageUrl,
                                                                      }
                                                                    )
                                                                  );
                                                                  setUploadedImage(
                                                                    imageUrl
                                                                  );
                                                                  setErrorMessage(
                                                                    ''
                                                                  );
                                                                }
                                                                if (
                                                                  acceptedFiles.length >
                                                                  1
                                                                ) {
                                                                  values.value[
                                                                    index
                                                                  ].bannerImage2 =
                                                                    acceptedFiles[1];
                                                                  const imageUrl =
                                                                    URL.createObjectURL(
                                                                      acceptedFiles[1]
                                                                    );

                                                                  setFieldValue(
                                                                    Object.assign(
                                                                      acceptedFiles[1],
                                                                      {
                                                                        preview1:
                                                                          imageUrl,
                                                                      }
                                                                    )
                                                                  );
                                                                }
                                                              }}
                                                            >
                                                              {({
                                                                getRootProps,
                                                                getInputProps,
                                                              }) => (
                                                                <div
                                                                  className="dropzone dz-clickable"
                                                                  style={{
                                                                    minHeight:
                                                                      'auto',
                                                                  }}
                                                                >
                                                                  <div
                                                                    className="dz-message needsclick"
                                                                    {...getRootProps()}
                                                                  >
                                                                    <div className="mb-3">
                                                                      <i className="display-5 text-muted ri-upload-cloud-2-fill" />
                                                                    </div>
                                                                    <h4>
                                                                      Drop files
                                                                      here or
                                                                      click to
                                                                      upload.
                                                                    </h4>
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </Dropzone>
                                                            <ul
                                                              className="list-unstyled mb-0"
                                                              id="file-previews"
                                                            >
                                                              <li className="mt-2 dz-processing dz-image-preview dz-success dz-complete">
                                                                <div className="border rounded">
                                                                  <div className="d-flex p-2">
                                                                    <div className="flex-shrink-0 me-3">
                                                                      <div className="avatar-sm bg-light rounded">
                                                                        <img
                                                                          data-dz-thumbnail=""
                                                                          className="img-fluid rounded d-block h-100 object-fit-cover"
                                                                          alt={
                                                                            values.bannerImage
                                                                          }
                                                                          src={
                                                                            typeof values
                                                                              ?.value[
                                                                              index
                                                                            ]
                                                                              ?.bannerImage ===
                                                                            'object'
                                                                              ? values
                                                                                  ?.value[
                                                                                  index
                                                                                ]
                                                                                  ?.bannerImage
                                                                                  ?.preview
                                                                              : `${IMG_URL}${values?.value[index]?.bannerImage}`
                                                                          }
                                                                          onLoad={() => {
                                                                            !values
                                                                              ?.value[
                                                                              index
                                                                            ]
                                                                              ?.bannerImage
                                                                              ? URL.revokeObjectURL(
                                                                                  values.preview
                                                                                )
                                                                              : '';
                                                                          }}
                                                                        />
                                                                      </div>
                                                                    </div>
                                                                    <div className="flex-shrink-0 me-3">
                                                                      <div className="avatar-sm bg-light rounded">
                                                                        <img
                                                                          data-dz-thumbnail=""
                                                                          className="img-fluid rounded d-block h-100 object-fit-cover"
                                                                          alt={
                                                                            values.bannerImage2
                                                                          }
                                                                          src={
                                                                            typeof values
                                                                              ?.value[
                                                                              index
                                                                            ]
                                                                              ?.bannerImage2 ===
                                                                            'object'
                                                                              ? values
                                                                                  ?.value[
                                                                                  index
                                                                                ]
                                                                                  ?.bannerImage2
                                                                                  ?.preview1
                                                                              : `${IMG_URL}${values?.value[index]?.bannerImage2}`
                                                                          }
                                                                          onLoad={() => {
                                                                            !values
                                                                              ?.value[
                                                                              index
                                                                            ]
                                                                              ?.bannerImage2
                                                                              ? URL.revokeObjectURL(
                                                                                  values.preview
                                                                                )
                                                                              : '';
                                                                          }}
                                                                        />
                                                                      </div>
                                                                    </div>
                                                                    <div className="flex-grow-1">
                                                                      <div className="pt-1">
                                                                        <h5
                                                                          className="fs-14 mb-1"
                                                                          data-dz-name=""
                                                                        ></h5>
                                                                        <p
                                                                          className="fs-13 text-muted mb-0"
                                                                          data-dz-size=""
                                                                        >
                                                                          <strong></strong>
                                                                        </p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                          <h6
                                                            style={{
                                                              color: 'red',
                                                            }}
                                                          >
                                                            {errorMessage}
                                                          </h6>
                                                        </Col>
                                                      </>
                                                    ) : null}
                                                  </>
                                                </Row>
                                              </CardBody>
                                            </Card>
                                          </Col>
                                        );
                                      })}
                                    </Row>
                                  </div>
                                )}
                              />
                              <Col md={12}>
                                <div className="hstack gap-2 justify-content-start">
                                  <Button type="submit" className="btn-success">
                                    Save
                                  </Button>
                                </div>
                              </Col>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ShowSpinner spinFlag={spinFlag} />
    </React.Fragment>
  );
};

export default HomePage;
