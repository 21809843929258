import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import Widgets from '../../Components/Common/Widgets';
import Loader from '../../Components/Common/Loader';
import 'react-toastify/dist/ReactToastify.css';
import { nairaSymbol } from '../../../utils/constant';
import {
  getAllTransactionList,
  getTotalPayoutAndBalance,
} from '../../../services/apiServices';
import TransactionList from './TransactionList';
import PayRequest from './PayRequest';
import ResponseModal from '../../Components/Common/SuccessModal';
import PayOutList from './PayOutList';
import ShowSpinner from '../../Components/Common/spinner';

const invoiceTable = [
  {
    id: 1,
    invoiceId: '#VL25000351',
    img: 'avatar2',
    name: 'Diana Kohler',
    email: 'dianakohler@velzon.com',
    country: 'Brazil',
    date: '06 Apr, 2021',
    time: '09:58PM',
    amount: '$875',
    status: 'Paid',
    statusClass: 'success',
  },
  {
    id: 2,
    invoiceId: '#VL25000352',
    img: 'avatar2',
    name: 'James Morris',
    email: 'jamesmorris@velzon.com',
    country: 'Germany',
    date: '17 Dec, 2021',
    time: '1:24AM',
    amount: '$451.00',
    status: 'Unpaid',
    statusClass: 'warning',
  },
  {
    id: 3,
    invoiceId: '#VL25000353',
    img: 'avatar2',
    name: 'Dawn Koh',
    email: 'dawnkoh@velzon.com',
    country: 'United Kingdom',
    date: '29 Nov, 2021',
    time: '7:20PM',
    amount: '$984.98',
    status: 'Paid',
    statusClass: 'success',
  },
  {
    id: 4,
    invoiceId: '#VL25000354',
    img: 'avatar2',
    name: 'Tonya Noble',
    email: 'tonynoble@velzon.com',
    country: 'Spain',
    date: '22 Nov, 2021',
    time: '10:20PM',
    amount: '$742.12',
    status: 'Cancel',
    statusClass: 'danger',
  },
  {
    id: 5,
    invoiceId: '#VL25000355',
    img: 'avatar2',
    name: 'David Nichols',
    email: 'davidnochols@velzon.com',
    country: 'United States of America',
    date: '11 Nov, 2021',
    time: '12:37AM',
    amount: '$2415.00',
    status: 'Unpaid',
    statusClass: 'warning',
  },
  {
    id: 6,
    invoiceId: '#VL25000356',
    img: 'avatar2',
    name: 'Joseph Payten',
    email: 'josephpayten@velzon.com',
    country: 'France',
    date: '03 Nov, 2021',
    time: '12:29AM',
    amount: '$7451.02',
    status: 'Paid',
    statusClass: 'success',
  },
  {
    id: 7,
    invoiceId: '#VL25000357',
    name: 'Mary Rucker',
    email: 'maryrucker@velzon.com',
    country: 'United Kingdom',
    date: '27 Oct, 2021',
    time: '01:46PM',
    amount: '$327.36',
    status: 'Cancel',
    statusClass: 'danger',
  },
  {
    id: 8,
    invoiceId: '#VL25000358',
    name: 'Alexis Clarke',
    email: 'alexisclarke@velzon.com',
    country: 'Spain',
    date: '18 Oct, 2021',
    time: '04:55PM',
    amount: '$879.78',
    status: 'Unpaid',
    statusClass: 'warning',
  },
  {
    id: 9,
    invoiceId: '#VL25000359',
    img: 'avatar2',
    name: 'Ryan Cowie',
    email: 'rayancowie@velzon.com',
    country: 'France',
    date: '07 Oct, 2021',
    time: '06:33AM',
    amount: '$879.00',
    status: 'Refund',
    statusClass: 'primary',
  },
  {
    id: 10,
    invoiceId: '#VL25000360',
    img: 'avatar2',
    name: 'Christina Maier',
    email: 'christinamaier@velzon.com',
    country: 'United States of America',
    date: '13 Sep, 2021',
    time: '11:59AM',
    amount: '$1624.18',
    status: 'Unpaid',
    statusClass: 'warning',
  },
  {
    id: 11,
    invoiceId: '#VL25000361',
    img: 'avatar2',
    name: 'Jennifer Winkel',
    email: 'jenniferwinkal@velzon.com',
    country: 'Brazil',
    date: '15 Aug, 2021',
    time: '01:05PM',
    amount: '$214.67',
    status: 'Cancel',
    statusClass: 'danger',
  },
  {
    id: 12,
    invoiceId: '#VL25000362',
    name: 'Erik Peters',
    email: 'erikpeters@velzon.com',
    country: 'Mexico',
    date: '07 Aug, 2021',
    time: '07:46PM',
    amount: '$1798.71',
    status: 'Active',
    statusClass: 'success',
  },
];

const PaymentPage = () => {
  const [invoice, setInvoice] = useState(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalPayout, setTotalPayout] = useState(0);
  const [totalAvailableBalance, setTotalAvailableBalance] = useState(0);
  const [spinFlag, setSpinFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchRender, setFetchRender] = useState(true);
  const [withdrwalId, setWithdrwalId] = useState('');
  const [response, setResponse] = useState({
    message: '',
    open: false,
    statusCode: null,
  });

  // const fetchAllTransactions=async()=>{
  //   try {
  //     setSpinFlag(true);
  //     let payload = {};
  //     let { data } = await getAllTransactionList(payload);
  //     if (data.statusCode === 200) {
  //       setSpinFlag(false);
  //       setRevenueData(data?.totalAmountsArray);
  //       setMonthName(data?.monthNames);
  //       setTotalEarnings(data?.total_volume);
  //     } else {
  //       setSpinFlag(false);
  //       setResponse({
  //         message: data?.message,
  //         statusCode: data?.statusCode,
  //         open: false,
  //       });
  //     }
  //   } catch (err) {
  //     navigate(`/admin/error?msg=${err}&&path=${pathname}`);
  //   }
  // }

  const fetchTotalPayoutAndBalanceData = async () => {
    try {
      setLoading(true); // Replacing setSpinFlag with a clearer name
      let requestPayload = {}; // payload renamed to requestPayload
      let { data } = await getTotalPayoutAndBalance(requestPayload);
      if (data?.statusCode === 200) {
        setLoading(false);
        setTotalAvailableBalance(data?.total_volume);
        setTotalPayout(data?.total_payout);
      } else {
        setLoading(false);
      }
    } catch (err) {
      navigate(`/admin/error?msg=${err}&&path=${pathname}`); // No change needed
    }
  };
  useEffect(() => {
    // fetchAllTransactions();
    fetchTotalPayoutAndBalanceData();
  }, []);

  return (
    <React.Fragment>
      <ShowSpinner spinFlag={spinFlag} />
      <ResponseModal response={response} setResponse={setResponse} />
      <div className="page-content">
        <Container fluid>
          <Row>
            {/* <Col xxl={3} lg={6}>
              <Widgets
                label={'TOTAL EARNINGS'}
                prefix={nairaSymbol}
                suffix={''}
                separator={''}
                end={totalEarnings}
                decimals={''}
                bgcolor={'success'}
                icon={'bx bxs-wallet'}
                role={''}
                tab={''}
              />
            </Col> */}
            <Col xxl={3} lg={6}>
              <Widgets
                label={'Available Balance'}
                prefix={nairaSymbol}
                suffix={''}
                separator={''}
                end={totalAvailableBalance}
                decimals={''}
                bgcolor={'warning'}
                icon={'bx bxs-wallet-alt'}
                role={''}
                tab={''}
              />
            </Col>
            <Col xxl={3} lg={6}>
              <Widgets
                label={'TOTAL PayOut'}
                prefix={nairaSymbol}
                suffix={''}
                separator={''}
                end={totalPayout}
                decimals={''}
                bgcolor={'danger'}
                icon={'bx bx-wallet-alt'}
                role={''}
                tab={''}
              />
            </Col>
          </Row>
          <Row>
            {invoiceTable?.length ? (
              <PayRequest
                setSpinFlag={setSpinFlag}
                setResponse={setResponse}
                fetchRender={fetchRender}
                setFetchRender={setFetchRender}
              />
            ) : (
              <Loader error="{error}" />
            )}
          </Row>
          {/* Payout list */}
          {/* <Row>
            {invoiceTable?.length ? (
              <PayOutList setSpinFlag={setSpinFlag} setResponse={setResponse} />
            ) : (
              <Loader error="{error}" />
            )}
          </Row> */}
          <Row>
            {invoiceTable?.length ? (
              <TransactionList
                setSpinFlag={setSpinFlag}
                setResponse={setResponse}
                fetchRender={fetchRender}
                setFetchRender={setFetchRender}
              />
            ) : (
              <Loader error="{error}" />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PaymentPage;
